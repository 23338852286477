import Grid from '@mui/material/Grid2';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  DateSelector,
  ReceivedValue,
} from 'components/date-picker/components/date-selector';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslateAll } from 'locales/translation-hooks';
import { NewClientDataType } from 'pages/clients/api/get-clients';
import {
  getCity,
  getCountry,
  getMunicipality,
  getState,
} from 'pages/clients/api/get-location';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useClientDataContext } from '../../../react-query-toolkit/state/client-context';

export function ClientCreator() {
  const [formData, setFormData] = useState<NewClientDataType>({
    clientId: '',
    clientName: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    country: '',
    city: '',
    municipality: '',
    state: '',
    activeDate: '',
    closeDate: '',
    address: '',
    postalCode: '',
    organizationType: '',
    consultants: [],
    logo: '',
    typename: 'client',
    action: '',
    clientPool: '',
  });
  const {
    handleCreateClient,
    isCreateClientLoading,
    isCreateClientSuccess,
    resetCreateClientQueryValues,
  } = useClientDataContext();
  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleCreateClient(formData);

    // Reset formData
    setFormData({
      clientId: '',
      clientName: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      country: '',
      city: '',
      municipality: '',
      state: '',
      activeDate: '',
      closeDate: '',
      address: '',
      postalCode: '',
      organizationType: '',
      consultants: [],
      logo: '',
      typename: 'client',
      action: '',
      clientPool: '',
    });

    // Reset date pickers
    setActiveDateValue(DateTime.now().toISO()!);
    setCloseDateValue(DateTime.now().toISO()!);

    resetCreateClientQueryValues();
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [dueDateValue, setDueDateValue] = React.useState<ReceivedValue>(
    DateTime.now().toISO(),
  );
  const [closeDateValue, setCloseDateValue] = React.useState<string>(
    DateTime.fromISO(formData.closeDate).toISO()!,
  );
  const [activeDateValue, setActiveDateValue] = React.useState<string>(
    DateTime.fromISO(formData.activeDate).toISO()!,
  );

  const countryOptions = getCountry([formData]);
  const stateOptions = getState([formData]);
  const cityOptions = getCity([formData]);
  const municipalitiesOptions = getMunicipality([formData]);

  const { translateClients } = useTranslateAll(['clients']);

  const handleChange = <K extends keyof NewClientDataType>(
    field: K,
    value: NewClientDataType[K],
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const requiredFields: (keyof NewClientDataType)[] = [
    'clientName',
    'firstName',
    'lastName',
    'phone',
    'email',
  ];

  const areRequiredFieldsFilled = () => {
    return requiredFields.every((field) => {
      // Only check if the field is NOT disabled (assumes you control disabled state elsewhere)
      const isFieldDisabled = false; // Replace with your logic per field if needed
      if (isFieldDisabled) return true;

      const value = formData[field];
      return (
        value !== null && value !== undefined && String(value).trim() !== ''
      );
    });
  };

  const formDisabled = false;
  isCreateClientLoading || isCreateClientSuccess;

  // useEffect(() => {
  //   if (dueDateValue) {
  //     handleChange(
  //       'duedate',
  //       DateTime.fromISO(dueDateValue as string).toISODate() as string,
  //     );
  //   }
  // }, [dueDateValue]);

  const firstNameRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstNameRef.current) {
      const input = firstNameRef.current;
      const style = window.getComputedStyle(input);
      console.log('Border color:', style.borderColor);
    }
  }, []);
  return (
    <Box
      sx={{
        mt: 2,
        height: '100%',
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Grid container spacing={isMobile ? 4 : 4}>
        <Box
          sx={{
            '& .MuiDialog-paper': {
              maxWidth: '648px',
              width: '100%',
              height: '740px',
            },
          }} // Custom maxWidth
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pb: 5,
            }}
          >
            <Typography
              variant="body1"
              color="text.primary"
              sx={{
                textAlign: 'left',
              }}
            >
              {translateClients('totalClients.clientCreator.newClientSubTitle')}
            </Typography>
          </Box>
          <DialogContent
            sx={{
              px: 0,
              paddingBottom: theme.spacing(0),
            }}
          >
            <Box
              component="form"
              onSubmit={onSubmitForm}
              sx={{
                overflowX: 'hidden',
                overflowY: { xs: 'hidden', lg: 'scroll', xxl: 'hidden' },
                maxHeight: { xs: 'auto', lg: theme.breakpoints.values.md },
              }}
            >
              <Grid container spacing={6} sx={{ flexGrow: 1, pt: 2 }}>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <TextField
                    fullWidth
                    required
                    label={translateClients('editClient.labels.client')}
                    value={formData.clientName}
                    onChange={(e) => handleChange('clientName', e.target.value)}
                    sx={{ typography: 'body2' }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <TextField
                    fullWidth
                    required
                    inputRef={firstNameRef}
                    label={translateClients('editClient.labels.firstName')}
                    value={formData.firstName}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <TextField
                    fullWidth
                    required
                    label={translateClients('editClient.labels.lastName')}
                    value={formData.lastName}
                    onChange={(e) => handleChange('lastName', e.target.value)}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <TextField
                    fullWidth
                    required
                    label={translateClients('editClient.labels.phone')}
                    value={formData.phone}
                    onChange={(e) => handleChange('phone', e.target.value)}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <TextField
                    fullWidth
                    required
                    label={translateClients('editClient.labels.email')}
                    value={formData.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <DateSelector
                    name={translateClients('editClient.labels.closeDate')}
                    dateData={formData.closeDate}
                    dateUpdater={(newValue: string | undefined) => {
                      setCloseDateValue(newValue!); // Update the local closeDateValue state
                      handleChange('closeDate', newValue!); // Update formData.closeDate
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <DateSelector
                    name={translateClients('editClient.labels.activeDate')}
                    dateData={activeDateValue}
                    dateUpdater={(newValue: string | undefined) => {
                      setActiveDateValue(newValue!); // Update the local closeDateValue state
                      handleChange('activeDate', newValue!);
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="country-field-label">
                      {translateClients('editClient.labels.country')}
                    </InputLabel>
                    <Select
                      labelId="country-field-label"
                      id="country-field"
                      input={
                        <OutlinedInput
                          label={translateClients('editClient.labels.country')}
                        />
                      }
                      value={formData.country}
                      onChange={(e) => handleChange('country', e.target.value)}
                    >
                      {countryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="State-field-label">
                      {translateClients('editClient.labels.stateProvince')}
                    </InputLabel>
                    <Select
                      labelId="State-field-label"
                      id="State-field"
                      input={
                        <OutlinedInput
                          label={translateClients(
                            'editClient.labels.stateProvince',
                          )}
                        />
                      }
                      value={formData.state}
                      onChange={(e) => handleChange('state', e.target.value)}
                    >
                      {stateOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="Municipality-field-label">
                      {translateClients('editClient.labels.municipality')}
                    </InputLabel>
                    <Select
                      labelId="Municipality-field-label"
                      id="Municipality-field"
                      input={
                        <OutlinedInput
                          label={translateClients(
                            'editClient.labels.municipality',
                          )}
                        />
                      }
                      value={formData.municipality}
                      onChange={(e) =>
                        handleChange('municipality', e.target.value)
                      }
                    >
                      {municipalitiesOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="city-field-label">
                      {translateClients('editClient.labels.city')}
                    </InputLabel>
                    <Select
                      labelId="city-field-label"
                      id="city-field"
                      input={
                        <OutlinedInput
                          label={translateClients('editClient.labels.city')}
                        />
                      }
                      value={formData.city}
                      onChange={(e) => handleChange('city', e.target.value)}
                    >
                      {cityOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <TextField
                    fullWidth
                    label={translateClients('editClient.labels.streetAddress')}
                    value={formData.address}
                    onChange={(e) => handleChange('address', e.target.value)}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <TextField
                    fullWidth
                    label={translateClients('editClient.labels.postalCode')}
                    value={formData.postalCode}
                    onChange={(e) => handleChange('postalCode', e.target.value)}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="organization-field-label">
                      {translateClients('editClient.labels.organizationType')}
                    </InputLabel>
                    <Select
                      labelId="organization-field-label"
                      id="organization-field"
                      input={
                        <OutlinedInput
                          label={translateClients(
                            'editClient.labels.organizationType',
                          )}
                        />
                      }
                      value={formData.organizationType}
                      onChange={(e) =>
                        handleChange('organizationType', e.target.value)
                      }
                    >
                      <MenuItem value="goverment">
                        {translateClients('editClient.options.government')}{' '}
                      </MenuItem>
                      <MenuItem value="company">
                        {translateClients('editClient.options.company')}
                      </MenuItem>
                      <MenuItem value="industry">
                        {translateClients('editClient.options.industry')}
                      </MenuItem>
                      <MenuItem value="charity">
                        {translateClients('editClient.options.nonProfit')}
                      </MenuItem>
                      <MenuItem value="educational">
                        {translateClients(
                          'editClient.options.educationalInstitution',
                        )}
                      </MenuItem>
                      <MenuItem value="community">
                        {translateClients('editClient.options.communityGroup')}
                      </MenuItem>
                      <MenuItem value="others">
                        {translateClients('editClient.options.Others')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, xxl: 6 }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={
                      <AssignmentIcon sx={{ color: 'secondary.dark' }} />
                    }
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                      py: 3,
                    }}
                  >
                    <Typography variant={'button'} color={'primary.main'}>
                      {translateClients('editClient.labels.addProjects')}
                    </Typography>
                  </Button>
                </Grid>
                <Grid size={{ xs: 12, xxl: 6 }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={
                      <PersonAddIcon sx={{ color: 'secondary.dark' }} />
                    }
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                      py: 3,
                    }}
                  >
                    <Typography variant={'button'} color={'primary.main'}>
                      {translateClients('editClient.labels.addConsultants')}
                    </Typography>
                  </Button>
                </Grid>
                <Grid size={{ xs: 12, md: 6, lg: 12, xxl: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="client-pool-label">
                      {translateClients('editClient.labels.clientPool')}
                    </InputLabel>
                    <Select
                      labelId="client-pool-label"
                      id="client-pool"
                      input={
                        <OutlinedInput
                          label={translateClients(
                            'editClient.labels.clientPool',
                          )}
                        />
                      }
                      value={formData.clientPool}
                      onChange={(e) =>
                        handleChange('clientPool', e.target.value)
                      }
                    >
                      <MenuItem value="goverment">{'City Network'} </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <DialogActions
                sx={{
                  paddingTop: theme.spacing(5),
                  paddingBottom: theme.spacing(9),
                  px: theme.spacing(0),
                  justifyContent: 'flex-start',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={formDisabled || !areRequiredFieldsFilled()}
                >
                  {translateClients('editClient.buttons.create')}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Box>
      </Grid>
    </Box>
  );
}
