{
  "pageTitle": "",
  "titles": {
    "systemNotices": "",
    "consultants": "",
    "totalClients": "",
    "openProjects": ""
  },
  "labels": {
    "systemNotice": "",
    "consultant": "",
    "client": "",
    "project": ""
  },
  "tooltTips": {
    "addNew": "",
    "editNew": "",
    "addNew_m": "",
    "addNew_f": "",
    "addNew_n": "",
    "addNewCustomProject": "",
    "addNewSchedule": "",
    "edit": "",
    "edit_m": "",
    "edit_f": "",
    "edit_n": "",
    "editCustomProject": "",
    "editThisSchedule": "",
    "archive": "",
    "archive_m": "",
    "archive_f": "",
    "archive_n": "",
    "archiveCustomProject": "",
    "review": "",
    "seeAllClientsRequests": "",
    "seeAllCustomProjects": "",
    "seeAllSchedules": ""
  },
  "displayCustomProjectsTable": {
    "displayTableFetchingErrorMessage": "",
    "displayTableNoItemsMessage": ""
  }
}
