import { useNavigate } from 'react-router-dom';
import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

type Props = {
  icon?: JSX.Element;
  projectName: string;
  progress: number;
  projectId: string;
};

export const MyProjectsItem = ({ projectName, progress, projectId }: Props) => {
  const navigate = useNavigate();
  const projectURL = `/my-projects/project-id/${projectId}`;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
        padding: 0,
        margin: 0,
      }}
      onClick={() => navigate(projectURL)}
    >
      <Typography
        variant="body3"
        mr={3}
        color={'text.primary'}
        sx={{ flex: 1 }}
      >
        {projectName}
      </Typography>
      <Box sx={{ flex: 2 }}>
        <Box display="flex" alignItems="center" width="100%">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ color: 'primary.dark' }}
            />
          </Box>
          <Typography
            variant="body2"
            pl={3}
            color="text.primary"
          >{`${Math.round(progress)}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
