import { useState } from 'react';
import env from '../../../../env.json';

export const useRetrieveImage = (
  bucketName: string,
  folderPath: string,
  imageID: string,
) => {
  const [imageFile, setImageFile] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const fullImageUrl =
    imageID.length > 0 ? `assets/profile-pictures/${imageID}` : '';

  const handleRetrieveImage = async () => {
    if (fullImageUrl) {
      try {
        console.log('here', `assets/profile-pictures/${imageID}`);
        setImageFile(`assets/profile-pictures/${imageID}`);
      } catch (err) {
        setError('Error retrieving image. Please try again.');
        console.error('Error retrieving image', err);
      }
    } else {
      setImageFile('');
      console.warn('Image Id is empty.');
    }
  };

  return { imageFile, handleRetrieveImage, error };
};
