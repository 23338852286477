import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNotificationCardInfo } from 'pages/system-management/components/notification-card/notification-card-info';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import NotificationCard from 'pages/system-management/components/notification-card/notification-card';
import { ClientRequestWidget } from 'pages/system-management/components/client-requests';
import { CustomProjects } from 'pages/system-management/components/custom-projects';
import { Scheduler } from 'pages/system-management/components/scheduler';
import { SofConect } from 'pages/system-management/components/sofconect';
import { CustomModal } from 'components/molecules/custom-modal/custom-modal';
import {
  OpenProjects,
  SystemNotices,
  TotalClients,
} from 'pages/system-management/components/modal-components';
import { Consultants } from 'pages/system-management/components/modal-components/consultants';

export const SMDashboard = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState<string | null>(
    null,
  );
  const { isLoading, noticesCards } = useNotificationCardInfo();
  const [modalTitle, setModalTitle] = useState<string>('');
  const theme = useTheme();

  // Mapping function for selected components
  const getComponent = (selectedComponent: string | null) => {
    switch (selectedComponent) {
      case '/system-notices':
        return <SystemNotices />;
      case '/total-clients':
        return <TotalClients />;
      case '/open-projects':
        return <OpenProjects />;
      case '/consultants':
        return <Consultants />;
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          display={'flex'}
          width={'100%'}
          height={'30%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <>
          <Box justifyItems={'center'} sx={{ width: '100%' }}>
            <Box width={'100%'} maxWidth={theme.breakpoints.values.xxl}>
              <Grid
                container
                alignItems={'center'}
                columnSpacing={{ xs: 6 }}
                rowSpacing={{ xs: 6 }}
              >
                <Grid
                  container
                  size={12}
                  columnSpacing={{ xs: 3, md: 6 }}
                  rowSpacing={{ xs: 3, md: 6 }}
                  alignItems="stretch"
                >
                  {noticesCards.map((card, index) => (
                    <Grid size={{ xs: 6, xl: 3 }} key={index}>
                      <Box>
                        <NotificationCard
                          key={index}
                          setSelectedComponent={(component) => {
                            setSelectedComponent(component);
                            setModalTitle(card.cardTitle); // Set modal title dynamically
                            setModalIsOpen(true);
                          }}
                          setModalIsOpen={setModalIsOpen}
                          modalIsOpen={modalIsOpen}
                          cardIconLink={card.IconPathLink}
                          cardIcon={card.cardIcon}
                          cardTitle={card.cardTitle}
                          pathLink={card.pathLink}
                          activeNumber={card.activeNumber}
                          name={card.name}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Grid container size={{ xs: 12 }}>
                  <Grid container size={{ xs: 12, xl: 6 }}>
                    <Grid size={{ xs: 12 }}>
                      <Paper
                        elevation={1}
                        sx={{
                          flex: 1, // Ensures equal height in the Grid
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <ClientRequestWidget />
                      </Paper>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <Paper
                        elevation={1}
                        sx={{
                          flex: 1, // Ensures equal height in the Grid
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <CustomProjects />
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container size={{ xs: 12, xl: 6 }}>
                    <Grid container size={{ xs: 12 }}>
                      <Paper
                        elevation={1}
                        sx={{
                          flex: 1, // Ensures equal height in the Grid
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <SofConect />
                      </Paper>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <Paper
                        elevation={1}
                        sx={{
                          flex: 1, // Ensures equal height in the Grid
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Scheduler />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <CustomModal
            isOpen={modalIsOpen}
            toggleModal={setModalIsOpen}
            title={modalTitle}
          >
            {getComponent(selectedComponent)}
          </CustomModal>
        </>
      )}
    </>
  );
};
