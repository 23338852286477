{
  "NavBar":{
    "navBarNavigationItems":{
      "myClients": "My clients",
      "consultants": "Consultants",
      "dashboard": "Dashboard",
      "projects": "Projects",
      "tasks": "Tasks",
      "userProfile": "My profile",
      "calendar": "Calendar",
      "notifications": "Notifications",
      "reports": "Reports",
      "signout": "Sign Out",
      "systemManager": "System management"
    },
    "taskManager":"Task manager"
  },
  "systemManagerSideBar": {
    "sideBarNavigationItems": {
      "systemMonitoring": "System monitoring",
      "mandates": "Mandates",
      "projectTypes": "Project types",
      "clientPools": "Client pools",
      "programs": "Programs",
      "emissionFactors": "Emission factors",
      "transformations": "Transformations",
      "jurisdictions": "Jurisdictions",
      "sofConect": "SofConect",
      "reports": "Reports"
    }
  }
}
