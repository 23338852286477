import React, { useEffect, useState } from 'react';
import { createClient } from 'contentful';
import { AccordionItem } from 'pages/faq/components/accordion-item';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const client = createClient({
  space: 'y3n4tj2ong0l',
  accessToken: 'whLrYVGlrSGjcMaHVmBpZKP7dIpLCRwt0jMiVHyCuqs',
});

export const Faq: React.FC = () => {
  const [data, setData] = useState<
    {
      pageName: string;
      faqs: { id: string; question: string; answer: any }[];
    }[]
  >([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getEntries = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'pages', //  Use the correct content type for pages
          include: 2, //  Expand references
        });

        //  Transform data and handle null cases
        const formattedData = response.items.map((page: any) => ({
          pageName: page.fields.name || 'Unknown Page',
          faqs: Array.isArray(page.fields.allTextContent)
            ? page.fields.allTextContent
                .filter(
                  (faq: any) => faq?.fields?.question && faq?.fields?.answer,
                )
                .map((faq: any) => ({
                  id: faq.sys.id,
                  question: faq.fields.question,
                  answer: faq.fields.answer,
                }))
            : [], //  Handle cases where allTextContent is null
        }));

        setData(formattedData);
      } catch (error) {
        setError('There was an error getting data');
        console.error('Error fetching data from Contentful', error);
      }
    };

    getEntries();
  }, []);

  return (
    <div>
      {data.length > 0 ? (
        data.map((page) => (
          <div key={page.pageName}>
            <Typography variant={'h2'}>{page.pageName}</Typography>
            {page.faqs.map((faq) => (
              <AccordionItem
                key={faq.id}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        ))
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Box
          display="flex"
          width="100%"
          height="30%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={50} />
        </Box>
      )}
    </div>
  );
};
