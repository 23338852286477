{
  "description": {
    "default": "",
    "receptionDate": "",
    "changedProjectStepDueDate": "",
    "changedProjectDueDate": "",
    "createdNewTask": "",
    "changedProjectStepStatus": "",
    "noDetailsAvailable": ""
  },
  "feedBack": {
    "alertMessages": {
      "errors": {
        "deleteNotification": "",
        "deleteAllNotifications": ""
      }
    }
  },
  "titles": {
    "systemNotices": "",
    "openProject": "",
    "reportReview": "",
    "tasks": {
      "create": "",
      "overdue": "",
      "dueToday": "",
      "dueTomorrow": ""
    },
    "default": "",
    "noInfoAvailable": ""
  }
}
