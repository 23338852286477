import SystemNoticeCard from 'pages/system-notices/components/system-notice-card';
import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useSystemNoticesDataContext } from '../../../../../react-query-toolkit/state/system-notices-context';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';
import useHasSystemManagementAccess from 'utils/hooks/useHasSystemManagementAccess';
import { SystemNoticesType } from '../../../../../react-query-toolkit/state/types';
import { useTranslateAll } from 'locales/translation-hooks';

export function SystemNotices() {
  const {
    handleGetSystemNotices,
    systemNoticesListIsLoading,
    handleDeleteNotice,
    deletingNoticeId,
  } = useSystemNoticesDataContext();

  const notices = handleGetSystemNotices() || [];
  const hasSystemManagementAccess = useHasSystemManagementAccess();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] =
    useState<SystemNoticesType | null>(null);
  const { translateCommon, translateSystemNotices } = useTranslateAll([
    'common',
    'systemNotices',
  ]);

  const handleRequestDelete = (notice: SystemNoticesType) => {
    // Blur focused button BEFORE dialog opens
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    setSelectedNotice(notice);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur(); // remove focus
    }

    // Close the dialog first
    setConfirmDialogOpen(false);

    // Delay deletion until after the dialog has unmounted (MUI default = ~225ms)
    setTimeout(() => {
      if (selectedNotice) {
        handleDeleteNotice(selectedNotice.id);
        setSelectedNotice(null);
      }
      // Optional: move focus back
      document.getElementById('main-content')?.focus();
    }, 300); // Wait longer than MUI Dialog animation (default is 225ms)
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    setSelectedNotice(null);
  };

  return (
    <>
      {systemNoticesListIsLoading && hasSystemManagementAccess ? (
        <Box
          display="flex"
          width="100%"
          height="30%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid container spacing={5}>
          {notices.length > 0 ? (
            notices.map((notice) => (
              <Grid width="100%" key={notice.id + 1}>
                <SystemNoticeCard
                  notice={notice}
                  handleRequestDelete={handleRequestDelete}
                  isDeleting={deletingNoticeId === notice.id}
                />
              </Grid>
            ))
          ) : (
            <Box textAlign="center" mt={2}>
              {translateSystemNotices('messages.noNoticesAvailable')}
            </Box>
          )}
        </Grid>
      )}
      <Dialog
        disableEnforceFocus
        disableAutoFocus
        open={confirmDialogOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle color={'error.main'}>
          {translateSystemNotices('systemNoticesList.deleteHeader')}
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={handleCancelDelete}
            variant="outlined"
            color="secondary"
          >
            {translateCommon('buttons.cancelButton')}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="primary"
          >
            {translateCommon('buttons.okButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
