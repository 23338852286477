{
  "NavBar": {
    "navBarNavigationItems": {
      "myClients": "Mes clients",
      "consultants": "Consultants",
      "dashboard": "Tableau de bord",
      "projects": "Projets",
      "tasks": "Tâches",
      "userProfile": "Mon Profile",
      "calendar": "Calendrier",
      "notifications": "Notifications",
      "reports": "Rapports",
      "signout": "Déconnexion",
      "systemManager": "Gestion du système"
    },
    "taskManager": "Gestionnaire de tâches"
  },
  "systemManagerSideBar": {
    "sideBarNavigationItems": {
      "systemMonitoring": "Surveillance du système",
      "mandates": "Mandats",
      "projectTypes": "Types de projets",
      "clientPools": "Groupes de clients",
      "programs": "Programmes",
      "emissionFactors": "Facteurs d'émission",
      "transformations": "Transformations",
      "jurisdictions": "Juridictions",
      "sofConect": "SofConect",
      "reports": "Rapports"
    }
  }
}
