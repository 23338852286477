// Redux Toolkit only allows one argument to be passed to its builder methods. To get around this, we define custom types with multiple properties.
export enum PlEntities {
  Client = 'client',
  Project = 'project',
  User = 'user',
  System = 'systemnotice',
}

export enum SlEntities {
  Comment = 'comment',
  Task = 'task',
  Notification = 'notification',
  Project = 'project',
  ProjectStep = 'projectstep',
  Settings = 'settings',
  ProjectText = 'projecttext',
  System = 'systemnotice',
  User = 'user',
}

export enum SortOption {
  asc = 'asc',
  desc = 'desc',
}

export type PlEntity = {
  typename: PlEntities;
  id?: string;
  requestBody?: object;
  customId?: string;
  ClientId?: string;
  body?: {
    items: any;
    id?: string;
  };
  Items?: any;
};

export type usePlInRequest = {
  typename: PlEntities;
  plEntities: PlEntities;
  requestBody: object;
  id: string;
  customId?: string;
  ClientId?: string;
};

export type SlEntity = {
  typename: SlEntities | PlEntities;
  id: string;
  requestBody: object;
  customId?: string;
};

export type usePlSlInRequest = {
  plEntity: PlEntities;
  typename: PlEntities;
  requestBody: object;
  id: string;
  ClientId?: string;
};

export type usePlSlInDeleteRequest = {
  typename: PlEntities;
  id: string;
  taskId: string;
  slEntity: SlEntities;
};

export type AlAssociateType = {
  typename: string;
  plEntity: PlEntity;
  requestBody: object;
  id: string;
};

export type ClientContextRawData = {
  clientname: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  location: string; // This field seems to be reused for country, city, municipality, and state
  id: string;
  activedate: string;
  closedate: string;
  address: string;
  postalcode: string;
  organizationtype: string;
  accountconsultants?: string[]; // Consultants now come from the mandate
  logo: string;
  typename?: string;
  editors: string[];
};

export type TaskContextRawData = {
  id: string;
  name: string;
  completed: boolean;
  priority: string;
  duedate: string;
  typename: string;
  description: string;
  sk_id: string;
  clientId: string;
};
