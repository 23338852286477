import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Badge,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Notification } from '../../../react-query-toolkit/state/types';
import { InformationIcon } from 'icons/information-icon';
import { AlertIcon } from 'icons/alert-icon';
import { EmergencyIcon } from 'icons/emergency-icon';
import { useNotificationDataContext } from '../../../react-query-toolkit/state/notifications-context';
import { useTranslateAll } from '../../../locales/translation-hooks';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

interface NotificationCardProps {
  notification: Notification;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
}) => {
  const { translateCommon, translateNotifications } = useTranslateAll([
    'projects',
    'tasks',
    'common',
    'notifications',
  ]);
  const {
    deleteNotificationError,
    deletingNotificationId,
    handleDeleteNotification,
  } = useNotificationDataContext();

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [deletingNotificationClicked, setDeletingNotificationClicked] =
    useState(false);
  const handleCloseDialog = () => setOpenErrorDialog(false);

  // Open error dialog when deleteNotificationError occurs for this notification
  useEffect(() => {
    if (deleteNotificationError && deletingNotificationId === notification.id) {
      setOpenErrorDialog(true);
    }
  }, [deleteNotificationError, deletingNotificationId, notification.id]);

  const handleRemoveNotification = () => {
    setDeletingNotificationClicked(true);
    handleDeleteNotification(notification.id);
  };

  // Determine the due date status and corresponding icon and label
  function getDueDateStatus(
    notificationDueDate: string,
  ): 'emergency' | 'alert' | 'info' {
    const currentDate = dayjs().startOf('day');
    const dueDate = dayjs(notificationDueDate).startOf('day');

    if (dueDate.isBefore(currentDate)) {
      return 'emergency';
    } else if (dueDate.diff(currentDate, 'day') <= 1) {
      return 'alert';
    } else {
      return 'info';
    }
  }

  const dueDateStatus = getDueDateStatus(notification.duedate);

  const iconColors = {
    info: '#08668E',
    alert: '#FEA04A',
    emergency: '#FF0000',
  };

  const icon = {
    info: <InformationIcon color={iconColors.info} />,
    alert: <AlertIcon color={iconColors.alert} />,
    emergency: <EmergencyIcon color={iconColors.emergency} />,
  }[dueDateStatus];

  const { i18n } = useTranslation();

  const title = (() => {
    switch (notification.InterpolationValues.typename) {
      case 'task':
        return translateNotifications(`titles.tasks.${notification.type}`, {
          name: notification.InterpolationValues.name,
        });
      case 'project':
        return translateNotifications(`titles.projects.${notification.type}`, {
          name: notification.InterpolationValues.name,
        });
      case 'projectstep':
        return translateNotifications(
          `titles.projectsteps.${notification.type}`,
          { name: notification.InterpolationValues.name },
        );
      default:
        return translateNotifications('titles.noInfoAvailable');
    }
  })();

  const description = notification.InterpolationValues.description
    ? notification.InterpolationValues.description
    : translateNotifications('description.noDetailsAvailable');

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 1,
          borderColor: iconColors[dueDateStatus],
          py: 3,
          px: 5,
        }}
        component={Grid2}
        container
      >
        <Grid2
          container
          order={{ md: 2 }}
          size={{ xs: 12, md: 'auto' }}
          columnSpacing={5}
          offset={{ md: 'auto' }}
        >
          <Grid2
            size={{ xs: 'grow' }}
            sx={{
              display: { xs: 'flex' },
              alignItems: { xs: 'flex-end', md: 'flex-start' },
              justifyContent: { md: 'flex-end' },
            }}
          >
            <Typography variant="body3" color="textColor.main" px={2} py={2}>
              {DateTime.fromISO(notification.created_at)
                .setLocale(i18n.language)
                .toLocaleString({
                  ...DateTime.DATETIME_MED,
                  year: undefined,
                })}
            </Typography>
          </Grid2>

          <Grid2
            size={{ xs: 'auto' }}
            offset={{ xs: 'auto' }}
            sx={{ display: 'flex', alignItems: { md: 'flex-start' } }}
          >
            <IconButton
              onClick={handleRemoveNotification}
              sx={{
                boxSizing: 'content-box',
                p: 2,
                color: 'neutral.main',
              }}
            >
              {deletingNotificationClicked &&
              deletingNotificationId === notification.id ? (
                <Box
                  display={'flex'}
                  width={'100%'}
                  height={'30%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <Close
                  sx={{
                    width: { xs: '22px' },
                    height: { xs: '22px' },
                    color: 'secondary.dark',
                  }}
                />
              )}
            </IconButton>
          </Grid2>
        </Grid2>

        <Grid2
          container
          order={{ md: 1 }}
          size={{ xs: 12, md: 'grow' }}
          columnSpacing={4}
        >
          <Grid2 size={{ xs: 'auto' }} sx={{ display: 'flex' }}>
            <Tooltip
              sx={{
                overflow: 'hidden',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              title={
                dueDateStatus.charAt(0).toUpperCase() + dueDateStatus.slice(1)
              }
            >
              <Badge
                sx={{
                  height: { xs: '22px' },
                  py: { xs: 3 },
                }}
              >
                {icon}
              </Badge>
            </Tooltip>
          </Grid2>

          <Grid2
            offset={{ xs: 'auto' }}
            size={{ xs: 'grow' }}
            sx={{ display: 'flex', alignItems: 'center', pr: { xs: 4 } }}
          >
            <Stack
              spacing={{ md: 2 }}
              sx={{
                justifyContent: 'center',
                py: { md: 2 },
              }}
            >
              <Typography
                color={'textColor.main'}
                variant="subtitle1"
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                {title}
              </Typography>
              <CustomLink
                notification={notification}
                description={description}
                display={{ xs: 'none', md: 'inline-flex' }}
              />
            </Stack>
          </Grid2>

          <Grid2 size={12} sx={{ display: { xs: 'flex', md: 'none' }, py: 2 }}>
            <CustomLink notification={notification} description={description} />
          </Grid2>
        </Grid2>
      </Card>

      {/* Error Dialog */}
      <Dialog
        open={openErrorDialog}
        onClose={handleCloseDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">
          {translateCommon('errors.errorTitles.errorTitle')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography id="error-dialog-description">
            {translateCommon('errors.errorTitles.errorTitle')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {translateCommon('errors.errorButtons.errorCloseButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface CustomLinkProps {
  notification: Notification;
  description: string;
  display?: { [key: string]: string };
}

const CustomLink = ({
  notification,
  description,
  display,
}: CustomLinkProps) => {
  const navigate = useNavigate();

  const parseTaskId = (link: string) => {
    if (!link.includes('?')) {
      return null;
    }

    const normalizedLink = link[0] === '/' ? link : `/${link}`;
    const fullUrl = window.location.origin + normalizedLink;
    const newUrl = new URL(fullUrl);
    const queryParams = new URLSearchParams(newUrl.search);
    const taskId = queryParams.get('task');

    return taskId;
  };

  const taskId = parseTaskId(notification.link);

  return (
    <Link
      onClick={() => {
        navigate(notification.link, {
          state: { taskId },
        });
      }}
      underline="hover"
      color="inherit"
      target="_blank"
      sx={{ display: display ?? 'inline-flex' }}
    >
      <Typography
        variant="body3"
        color="textColor.main"
        lineHeight={'100%'}
        fontStyle={'normal'}
      >
        {description}
      </Typography>
    </Link>
  );
};

export default NotificationCard;
