import React, { useEffect, useState } from 'react';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslateAll } from 'locales/translation-hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { useWebSocketContext } from '../../react-query-toolkit/state/websocket-context';
import { useTheme } from '@mui/material/styles';
import { useSystemNoticesDataContext } from '../../react-query-toolkit/state/system-notices-context';
import SystemNoticeCard from 'pages/system-notices/components/system-notice-card';
import Grid from '@mui/material/Grid2';
import { NoticeCreator } from 'pages/system-notices/components/notice-creator';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { useNavigate } from 'react-router-dom';
import useHasSystemManagementAccess from 'utils/hooks/useHasSystemManagementAccess';
import UnauthorizedMessage from 'components/unauthorized-message/unauthorized-message';
import { CustomBreadcrumb } from 'components';
import { SystemNoticesType } from '../../react-query-toolkit/state/types';

export const SystemNotices: React.FC = () => {
  const { translateCommon, translateSystemNotices } = useTranslateAll([
    'common',
    'systemNotices',
  ]);
  const {
    systemNoticesFetchingError,
    systemNoticesListIsLoading,
    notices: fetchedNotices,
    handleDeleteNotice,
    deletingNoticeId,
  } = useSystemNoticesDataContext();

  const notices = fetchedNotices;
  const { lastMessageData } = useWebSocketContext();

  useEffect(() => {
    console.log(lastMessageData);
  }, [lastMessageData]);

  const theme = useTheme();
  const navigate = useNavigate();
  const hasSystemManagementAccess = useHasSystemManagementAccess();

  const isSoft404 = (error: unknown): boolean => {
    if (
      error &&
      typeof error === 'object' &&
      'status' in error &&
      (error as any).status === 404
    ) {
      const data = (error as any).data;
      return data?.Items?.length === 0;
    }
    return false;
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] =
    useState<SystemNoticesType | null>(null);

  const handleRequestDelete = (notice: SystemNoticesType) => {
    // Blur focused button BEFORE dialog opens
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    setSelectedNotice(notice);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur(); // remove focus
    }

    // Close the dialog first
    setConfirmDialogOpen(false);

    // Delay deletion until after the dialog has unmounted (MUI default = ~225ms)
    setTimeout(() => {
      if (selectedNotice) {
        handleDeleteNotice(selectedNotice.id);
        setSelectedNotice(null);
      }
      // Optional: move focus back
      document.getElementById('main-content')?.focus();
    }, 300); // Wait longer than MUI Dialog animation (default is 225ms)
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    setSelectedNotice(null);
  };
  return (
    <>
      {hasSystemManagementAccess ? (
        <Box position="relative" id="main-content" tabIndex={-1}>
          <Box
            sx={{
              position: 'absolute',
              left: 4,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              pointerEvents: 'auto', // ensure clickable
            }}
          >
            <CustomBreadcrumb
              links={[
                {
                  title: `${translateCommon('breadcrumbs.systemManagement')}`,
                  to: '/system-management/',
                },
                {
                  title: `${translateCommon('breadcrumbs.systemNotices')}`,
                  to: '/system-management/system-notices/',
                  current: true,
                },
              ]}
            />
          </Box>
          <PageWrapper heightValue={'100%'}>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                padding: 2,
                justifyContent: 'center',
                minHeight: 0,
              }}
            >
              <Box
                width="100%"
                maxWidth={theme.breakpoints.values.xxl}
                flexGrow={1}
                justifyContent={'center'}
              >
                <Grid
                  container
                  spacing={7}
                  justifyContent={'center'}
                  width={'100%'}
                  sx={{ flexGrow: 1, minHeight: 0 }}
                >
                  <Grid size={{ xs: 12 }}>
                    <PageTitle
                      title={translateSystemNotices('pageTitle')}
                      py={0}
                    />
                  </Grid>
                  {/* Left Side: Title & Notice Creator */}
                  <Grid
                    container
                    direction={{ xs: 'row', lg: 'column' }}
                    size={{ xs: 12, lg: 4 }}
                    sx={{
                      flexDirection: 'column',
                      flexGrow: 1,
                      minHeight: 0,
                    }}
                  >
                    <Grid
                      size={{ xs: 12 }}
                      sx={{
                        flexShrink: 0,
                        flexGrow: 1,
                        borderRadius: 2,
                        overflow: 'hidden',
                        backgroundColor: theme.palette.background.paper,
                      }}
                    >
                      <NoticeCreator />
                    </Grid>
                  </Grid>

                  {/* Right Side: System Notices */}
                  <Grid
                    container
                    rowSpacing={6}
                    alignContent={'start'}
                    size={{ xs: 12, lg: 'grow' }}
                    px={{ xs: 7 }}
                    py={{ xs: 9 }}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: theme.palette.background.paper,
                      display: 'flex',
                      flexGrow: 1,
                      maxHeight: 'calc(100vh - 120px)',
                      minHeight: 0,
                      overflow: 'hidden',
                    }}
                  >
                    <Grid size={{ xs: 12 }} height={'min-content'}>
                      <Typography variant={'h5'} color={'primary.dark'}>
                        {translateSystemNotices('systemNoticesList.header')}
                      </Typography>
                    </Grid>

                    <Grid
                      size={{ xs: 12 }}
                      sx={{
                        flexGrow: 1,
                        minHeight: 0,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          minHeight: 0,
                          maxHeight: 'calc(100vh - 300px)',
                          overflowY: 'auto',
                          paddingRight: 1,
                        }}
                      >
                        {systemNoticesListIsLoading ? (
                          <Box
                            display="flex"
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CircularProgress size={50} />
                          </Box>
                        ) : systemNoticesFetchingError &&
                          !isSoft404(systemNoticesFetchingError) ? (
                          <Typography color="error.main">
                            {translateSystemNotices('messages.loadingError')}
                          </Typography>
                        ) : notices.length === 0 ? (
                          <Typography variant="body1" color="text.secondary">
                            {translateSystemNotices(
                              'messages.noNoticesAvailable',
                            )}
                          </Typography>
                        ) : (
                          notices.map((notice) => (
                            <Grid
                              key={notice.id}
                              size={{ xs: 12 }}
                              sx={{ pb: 6 }}
                            >
                              <SystemNoticeCard
                                key={notice.id}
                                notice={notice}
                                handleRequestDelete={handleRequestDelete}
                                isDeleting={deletingNoticeId === notice.id}
                              />
                            </Grid>
                          ))
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Dialog
                  disableEnforceFocus
                  disableAutoFocus
                  open={confirmDialogOpen}
                  onClose={handleCancelDelete}
                >
                  <DialogTitle color={'error.main'}>
                    {translateSystemNotices('systemNoticesList.deleteHeader')}
                  </DialogTitle>
                  <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                      onClick={handleCancelDelete}
                      variant="outlined"
                      color="secondary"
                    >
                      {translateCommon('buttons.cancelButton')}
                    </Button>
                    <Button
                      onClick={handleConfirmDelete}
                      variant="contained"
                      color="primary"
                    >
                      {translateCommon('buttons.okButton')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </PageWrapper>
        </Box>
      ) : (
        <UnauthorizedMessage />
      )}
    </>
  );
};
