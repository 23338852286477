import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useLanguageContext } from 'src/locales/context/LanguageContext';
import { Menu, MenuItem, Typography, IconButton } from '@mui/material';
import { colors } from 'utils/styles';

export const LanguageSelector = () => {
  const { selected, language, changeLanguage } = useLanguageContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Button that opens the menu */}
      <IconButton onClick={handleOpen} sx={{ color: colors.white }}>
        <Typography variant="h6">{language}</Typography>
        <ExpandMoreIcon
          sx={{
            color: 'common.white',
            transition: 'transform 150ms ease-in-out',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
          }}
        />
      </IconButton>

      {/* Language Selection Menu */}
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: colors.bluePrimary,
            border: `2px solid ${colors.secondaryBlue}`,
            borderRadius: 2,
            minWidth: 11,
          },
        }}
      >
        {Object.keys(selected).map((selection) => (
          <MenuItem
            key={selection}
            onClick={() => {
              changeLanguage?.(selection);
              handleClose();
            }}
            sx={{
              color: colors.white,
              fontWeight: 'bold',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            {selection.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
