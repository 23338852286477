import { Container } from '../../styled';
import { colors } from 'utils/styles';
import styled from 'styled-components';
import {
  Box,
  ButtonBase,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  Typography,
} from '@mui/material';
import { useTranslateAll } from 'locales/translation-hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StyledList = styled(MuiList)`
  &.dashboard-list {
    padding: 0;
  }
`;

const ListItem = styled(MuiListItem)<{ isFirst: boolean }>`
  display: flex;
  ${({ isFirst }) =>
    isFirst ? `border-top: 1px solid ${colors.lightGrey}25;` : ''}
  border-bottom: 1px solid ${colors.lightGrey}25;
`;

const ListItemButton = styled(MuiListItemButton)``;

type Props = {
  title: string;
  listData: JSX.Element[];
};

export const List = ({ title, listData }: Props) => {
  const { translateCommon } = useTranslateAll('common');

  const navigate = useNavigate();
  return (
    <Container width="100%" margin="0">
      <Box py={3} px={5} onClick={() => navigate('/clients')}>
        <Typography color={'primary.dark'} variant={'subtitle2'}>
          {title}
        </Typography>
      </Box>
      <StyledList className="dashboard-list">
        {listData.map((Data, index) => {
          return (
            <ListItem
              key={index}
              isFirst={index === 0}
              sx={{ padding: '0 !important' }}
            >
              <ListItemButton sx={{ paddingY: 3, paddingX: 5 }}>
                {Data}
              </ListItemButton>
            </ListItem>
          );
        })}
      </StyledList>
      <ButtonBase
        onClick={() => navigate('/my-clients')}
        sx={{
          padding: '0',
          alignItems: 'left',
          cursor: 'pointer',
          alignSelf: 'stretch',
          justifyContent: 'start',
          '&:hover': { bgcolor: 'action.hover' }, // Optional hover effect
        }}
      >
        <Typography my={4} mx={7} variant={'link'} color={'primary.dark'}>
          {translateCommon('seeAll')}
        </Typography>
      </ButtonBase>
    </Container>
  );
};
