import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enResources } from './en/ressources';
import { frResources } from './fr/ressources';
import { deResources } from './de/ressources';

export type LanguageGendering = 'm' | 'f' | 'n';

export interface NamespaceInfo {
  namespace: string;
  nameSpaceDeclaration: { ns: string };
}

export type Namespaces =
  | 'common'
  | 'misc'
  | 'account'
  | 'accountRequest'
  | 'calendar'
  | 'clients'
  | 'forgottenPassword'
  | 'login'
  | 'notifications'
  | 'password'
  | 'projects'
  | 'tasks'
  | 'userProfile'
  | 'systemManagement'
  | 'systemNotices';

const namespaces: Namespaces[] = [
  'common',
  'misc',
  'account',
  'accountRequest',
  'calendar',
  'clients',
  'forgottenPassword',
  'login',
  'notifications',
  'password',
  'projects',
  'tasks',
  'userProfile',
  'systemManagement',
  'systemNotices',
];

export type Resource = {
  [key in Namespaces]: object;
};

export const resources = {
  en: enResources,
  de: deResources,
  fr: frResources,
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Set the default language here
  ns: namespaces,
  fallbackLng: 'en', // Fallback language in case translation is missing for the current language
  interpolation: {
    escapeValue: false, // Allows usage of HTML tags in translations
  },
});

export const languages = i18n;
