import {
  Box,
  Breadcrumbs,
  Link,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useState } from 'react';

type BreadcrumbItem = {
  title: string;
  to: string;
  current?: boolean;
};

interface BreadCrumbProps {
  links: BreadcrumbItem[];
}

export function CustomBreadcrumb({ links }: BreadCrumbProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (to: string) => {
    navigate(to);
    handleMenuClose();
  };

  const home = links[0];
  const last = links[links.length - 1];
  const middle = links.slice(1, links.length - 1);

  // Collapse if more than 3 items (home + middle + current)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const maxInlineItems = isMobile ? 3 : 6;
  const shouldCollapse = links.length > maxInlineItems;

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 4,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'auto',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: theme.breakpoints.values.xxl,
          px: { xs: 4, md: 7 },
          pt: 4,
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <ArrowForwardIosIcon
              fontSize="small"
              sx={{ color: 'secondary.dark' }}
            />
          }
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '0.75rem',
            '& .MuiBreadcrumbs-separator': {
              px: '0 !important',
              mx: '0 !important',
            },
          }}
        >
          {/* Home link */}
          <Link
            underline="hover"
            color="secondary.dark"
            onClick={() => navigate(home.to)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              paddingRight: 1,
            }}
          >
            <HomeIcon fontSize="small" />
          </Link>

          {/* Collapsed dropdown or normal middle items */}
          {shouldCollapse ? (
            <>
              <IconButton
                size="small"
                onClick={handleMenuOpen}
                sx={{ alignItems: 'end' }}
              >
                <MoreHorizIcon
                  fontSize="small"
                  sx={{ color: 'primary.main' }}
                />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                {middle.map((item, idx) => (
                  <MenuItem key={idx} onClick={() => handleNavigate(item.to)}>
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            middle.map((item, idx) => (
              <Link
                key={idx}
                underline="hover"
                color="secondary.dark"
                onClick={() => navigate(item.to)}
                sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              >
                <Typography variant="overline" color="inherit" noWrap>
                  {item.title}
                </Typography>
              </Link>
            ))
          )}

          {/* Last (active) item */}
          <Typography
            variant="overline"
            color="primary.light"
            noWrap
            sx={{ cursor: 'default' }}
          >
            {last.title}
          </Typography>
        </Breadcrumbs>
      </Box>
    </Box>
  );
}
