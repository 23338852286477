{
  "pageTitle": "System Management",
  "titles": {
    "systemNotices": "System Notices",
    "consultants": "Consultants",
    "totalClients": "Total Clients",
    "openProjects": "Open Projects"
  },
  "labels":{
    "systemNotice": "System Notice",
    "consultant": "Consultant",
    "client": "Client",
    "project": "Project"
  },
  "tooltTips":{
    "addNew":"Add new {{what}}",
    "editNew":"Edit this {{what}}",
    "addNew_m": "",
    "addNew_f": "",
    "addNew_n": "",
    "addNewCustomProject":"Add new custom project",
    "addNewSchedule":"Add new schedule",
    "edit":"Edit this {{what}}",
    "edit_m": "",
    "edit_f": "",
    "edit_n": "",
    "editCustomProject":"Edit this custom project",
    "editThisSchedule":"Edit this schedule",
    "archive":"Archive this {{what}}",
    "archive_m": "",
    "archive_f": "",
    "archive_n": "",
    "archiveCustomProject":"Archive this custom project",
    "review":"Review then accept/reject client request",
    "seeAllClientsRequests":"See all clients requests",
    "seeAllCustomProjects":"See all custom projects",
    "seeAllSchedules":"See all schedules"


  },
  "displayCustomProjectsTable":{
    "displayTableFetchingErrorMessage": "There was an error",
    "displayTableNoItemsMessage": "No Items to display"
  }
}
