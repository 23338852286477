import common from './common.json';
import misc from './misc.json';
import account from './pages/account.json';
import accountRequest from './pages/accountRequest.json';
import calendar from './pages/calendar.json';
import clients from './pages/clients.json';
import forgottenPassword from './pages/forgottenPassword.json';
import login from './pages/login.json';
import notifications from './pages/notifications.json';
import projects from './pages/projects.json';
import tasks from './pages/tasks.json';
import userProfile from './pages/userProfile.json';
import password from './pages/password.json';
import systemManagement from './pages/systemManagement.json';
import systemNotices from './pages/systemNotices.json';
import { Resource } from '../languages';

export const enResources: Resource = {
  common,
  misc,
  account,
  accountRequest,
  calendar,
  clients,
  forgottenPassword,
  login,
  notifications,
  password,
  projects,
  tasks,
  userProfile,
  systemManagement,
  systemNotices,
};
