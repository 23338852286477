import { Box, Typography } from '@mui/material';
import { UnauthorizedIcon } from 'icons/unauthorized-icon';

function UnauthorizedMessage() {
  return (
    <Box
      width={'100%'}
      height={'100vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box width={'40%'}>
        <Typography variant={'h5'} color={'textSecondary'} align={'center'}>
          Oops! You are not authorized. Please Contact our customer Service
        </Typography>
        <UnauthorizedIcon />
      </Box>
    </Box>
  );
}

export default UnauthorizedMessage;
