import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'configs/AuthContext';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { LoginForm } from 'pages/login/components/login-form';
import { PageWrapper } from 'components';

export interface LocationState {
  from: {
    pathname: string;
  };
}

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const from = (location.state as LocationState)?.from || '';

  useEffect(() => {
    if (user) {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate('/change-password');
      } else {
        navigate(from || '/');
      }
    }
  }, [user, navigate, from]);

  return (
    <PageWrapper justifyContent={'center'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          overflow: 'auto',
          overflowX: 'none',
        }}
      >
        <LoginForm />
      </Box>
    </PageWrapper>
  );
};
