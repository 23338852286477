import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { ProjectComment } from '../../../../react-query-toolkit/state/types';
import { Comment } from './project-comments';
import { DateTime } from 'luxon';

export type CommentManagement =
  | MenuStateManagement
  | CommentEditingManagement
  | ReplyManagement
  | BackendManagement
  | DialogManagement
  | CommentDataManagement
  | TextManagement;

export interface MenuStateManagement {
  menuState: { [key: string]: HTMLElement | null };
  setMenuState: React.Dispatch<
    React.SetStateAction<{
      [key: string]: HTMLElement | null;
    }>
  >;
  handleMenuToggle: (commentId: string, anchor: HTMLElement | null) => void;
  handleMenuClose: (commentId: string) => void;
}

export interface CommentEditingManagement {
  editingComment: {
    id: string | null;
    text: string;
  } | null;
  setEditingComment: React.Dispatch<
    React.SetStateAction<{
      id: string | null;
      text: string;
    } | null>
  >;
  handleEditComment: (commentId: string, currentText: string) => void;
  handleEditChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCancelEdit: () => void;
  handleSaveEdit: (
    commentsData: ProjectComment[],
    handleUpdateProjectComment: (
      projectCommentId: string,
      parent: string,
      projectstep: string,
      read: string[],
      tags: string,
      text: string,
      checked: boolean,
    ) => Promise<void>,
    commentsSetter: Dispatch<SetStateAction<Comment[]>>,
  ) => Promise<void>;
}

export interface ReplyManagement {
  inputRef: React.RefObject<HTMLInputElement>;
  replyTo: {
    id: string | null;
    name: string | null;
  };
  setReplyTo: React.Dispatch<
    React.SetStateAction<{
      id: string | null;
      name: string | null;
    }>
  >;
  replyVisibility: {
    [key: string]: boolean;
  };
  setReplyVisibility: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  toggleRepliesVisibility: (commentId: string) => void;
  toggleAllRepliesVisibility: () => void;
  addReply: (
    commentsList: Comment[],
    parentId: string,
    newReply: Comment,
  ) => Comment[];
  handleReply: (commentId: string, commenterName: string) => void;
  replyInputValue: string;
  setReplyInputValue: React.Dispatch<React.SetStateAction<string>>;
  areAllRepliesVisible: boolean;
  setAreAllRepliesVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface BackendManagement {
  handleSend: (
    ghgciId: string,
    stepComment: string,
    replyTo: {
      id: string | null;
      name: string | null;
    },
    replyInputValue: string,
    inputValue: string,
    setInputValue: Dispatch<SetStateAction<string>>,
    setComments: Dispatch<SetStateAction<Comment[]>>,
    addReply: (
      commentsList: Comment[],
      parentId: string,
      newReply: Comment,
    ) => Comment[],
    setReplyTo: Dispatch<
      SetStateAction<{
        id: string | null;
        name: string | null;
      }>
    >,
    setReplyInputValue: Dispatch<SetStateAction<string>>,
    handleCreateProjectComment: (
      id: string,
      parent: string,
      projectstep: string,
      read: string[],
      tags: string,
      text: string,
      checked: boolean,
      author: string,
    ) => Promise<void>,
  ) => void;
  handleDelete: (
    commentId: string,
    commentsData: ProjectComment[],
    setComments: (value: SetStateAction<Comment[]>) => void,
    handleDeleteComment: (commentId: string) => void,
  ) => void;
  deleteCommentOrReply: (comments: Comment[], commentId: string) => Comment[];
}

export interface DialogManagement {
  dialogOpen: boolean;
  handleOpenDialog: (
    commentId: string,
    isReply: boolean,
    currentChecked: boolean,
  ) => void;
  handleCloseDialog: (
    setComments: (value: SetStateAction<Comment[]>) => void,
  ) => void;
  handleConfirmDialog: (
    comments: Comment[],
    setComments: (value: SetStateAction<Comment[]>) => void,
  ) => Promise<void>;
  selectedComment: {
    id: string;
    isReply: boolean;
    currentChecked: boolean;
  } | null;
  setSelectedComment: React.Dispatch<
    React.SetStateAction<{
      id: string;
      isReply: boolean;
      currentChecked: boolean;
    } | null>
  >;
}

export interface CommentDataManagement {
  comments: Comment[];
  setComments: Dispatch<SetStateAction<Comment[]>>;
  loadingComments: {
    [key: string]: boolean;
  };
  setLoadingComments: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  filteredCommentsData: (
    commentsData: ProjectComment[],
    stepComment: string,
  ) => ProjectComment[];
  getFilteredComments: (filter: string, comments: Comment[]) => Comment[];
  nestComments: (comments: ProjectComment[], ghgciId: string) => Comment[];
}

export interface TextManagement {
  filterText: string;
  setFilterText: React.Dispatch<React.SetStateAction<string>>;
  highlightText: (text: string) => string | JSX.Element;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

export const useMenuStateManagement = (): MenuStateManagement => {
  const [menuState, setMenuState] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const handleMenuToggle = (commentId: string, anchor: HTMLElement | null) => {
    setMenuState((prev) => ({
      ...prev,
      [commentId]: prev[commentId] ? null : anchor,
    }));
  };

  const handleMenuClose = (commentId: string) => {
    setMenuState((prev) => ({
      ...prev,
      [commentId]: null,
    }));
  };
  return { menuState, setMenuState, handleMenuToggle, handleMenuClose };
};

export const useCommentEditingManagement = (): CommentEditingManagement => {
  const [editingComment, setEditingComment] = useState<{
    id: string | null;
    text: string;
  } | null>(null);

  const handleEditComment = (commentId: string, currentText: string) => {
    setEditingComment({ id: commentId, text: currentText });
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editingComment) {
      setEditingComment({ ...editingComment, text: e.target.value });
    }
  };

  const handleCancelEdit = () => {
    setEditingComment(null);
  };

  const handleSaveEdit = async (
    commentsData: ProjectComment[],
    handleUpdateProjectComment: (
      projectCommentId: string,
      parent: string,
      projectstep: string,
      read: string[],
      tags: string,
      text: string,
      checked: boolean,
    ) => Promise<void>,
    commentsSetter: Dispatch<SetStateAction<Comment[]>>,
  ) => {
    if (!editingComment) return;

    const { id, text } = editingComment;

    // Find the comment being edited in the current comments list
    const commentToEdit = commentsData.find((comment) => comment.id === id);
    if (!commentToEdit) return;

    try {
      await handleUpdateProjectComment(
        id!,
        commentToEdit.parent,
        commentToEdit.projectstep,
        commentToEdit.read,
        commentToEdit.tags,
        text,
        commentToEdit.checked,
      );

      // Update the local state with the edited comment
      commentsSetter((prevComments) =>
        prevComments.map((comment) =>
          comment.id === id
            ? { ...comment, text } // Update the comment's text
            : {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply.id === id ? { ...reply, text } : reply,
                ), // Update text if it's a reply
              },
        ),
      );

      // Clear the editing state
      setEditingComment(null);
    } catch (error) {
      console.error('Failed to update comment:', error);
      alert('Failed to update the comment. Please try again.');
    }
  };

  return {
    editingComment,
    setEditingComment,
    handleEditComment,
    handleEditChange,
    handleCancelEdit,
    handleSaveEdit,
  };
};

export const useReplyManagement = (): ReplyManagement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [replyInputValue, setReplyInputValue] = useState<string>('');

  const [areAllRepliesVisible, setAreAllRepliesVisible] =
    useState<boolean>(false);

  const [replyTo, setReplyTo] = useState<{
    id: string | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });

  const [replyVisibility, setReplyVisibility] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleRepliesVisibility = (commentId: string) => {
    setReplyVisibility((prevVisibility) => ({
      ...prevVisibility,
      [commentId]: !prevVisibility[commentId],
    }));
  };

  const toggleAllRepliesVisibility = () => {
    setAreAllRepliesVisible((prev) => !prev);
    if (areAllRepliesVisible) setReplyVisibility({});
  };

  const addReply = (
    commentsList: Comment[],
    parentId: string,
    newReply: Comment,
  ): Comment[] => {
    return commentsList.map((comment) => {
      if (comment.id === parentId) {
        // Add reply to the correct parent
        return {
          ...comment,
          replies: [...comment.replies, newReply],
        };
      }
      // Recursively check replies
      return {
        ...comment,
        replies: addReply(comment.replies, parentId, newReply),
      };
    });
  };

  const handleReply = (commentId: string, commenterName: string) => {
    setReplyTo((prevReplyTo) =>
      prevReplyTo.id === commentId
        ? { id: null, name: null }
        : { id: commentId, name: commenterName },
    );
    if (replyTo.id !== commentId) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  };

  return {
    inputRef,
    replyTo,
    setReplyTo,
    replyVisibility,
    setReplyVisibility,
    toggleRepliesVisibility,
    toggleAllRepliesVisibility,
    addReply,
    handleReply,
    replyInputValue,
    setReplyInputValue,
    areAllRepliesVisible,
    setAreAllRepliesVisible,
  };
};

export const useBackendManagement = (): BackendManagement => {
  const handleSend = (
    ghgciId: string,
    stepComment: string,
    replyTo: {
      id: string | null;
      name: string | null;
    },
    replyInputValue: string,
    inputValue: string,
    setInputValue: Dispatch<SetStateAction<string>>,
    setComments: Dispatch<SetStateAction<Comment[]>>,
    addReply: (
      commentsList: Comment[],
      parentId: string,
      newReply: Comment,
    ) => Comment[],
    setReplyTo: Dispatch<
      SetStateAction<{
        id: string | null;
        name: string | null;
      }>
    >,
    setReplyInputValue: Dispatch<SetStateAction<string>>,
    handleCreateProjectComment: (
      id: string,
      parent: string,
      projectstep: string,
      read: string[],
      tags: string,
      text: string,
      checked: boolean,
      author: string,
    ) => Promise<void>,
  ) => {
    if (!replyTo?.id && !inputValue?.trim()) return; // Skip if no comment
    if (replyTo.id && !replyInputValue.trim()) return; // Skip if no reply

    const text = replyTo.id ? replyInputValue : inputValue;

    const newComment: Comment = {
      id: Date.now().toString(),
      typename: 'comment',
      created_at: DateTime.now().toISO(), //todo-cg:not working as it should be created in backend.
      creationDate: DateTime.now().toISO(),
      author: ghgciId,
      parent: replyTo.id || '',
      projectstep: stepComment,
      read: [],
      checked: false,
      tags: '',
      text,
      replies: [],
      isMine: true,
    };

    if (replyTo.id) {
      // Add reply to the correct parent
      setComments((prevComments) =>
        addReply(prevComments, replyTo.id!, newComment),
      );
      handleCreateProjectComment(
        newComment.id,
        newComment.parent,
        newComment.projectstep,
        newComment.read,
        newComment.tags,
        newComment.text,
        newComment.checked,
        newComment.author,
      );
      setReplyInputValue(''); // Clear reply input
    } else {
      // Add a new top-level comment
      setComments((prevComments) => [...prevComments, newComment]);
      handleCreateProjectComment(
        newComment.id,
        newComment.parent,
        newComment.projectstep,
        newComment.read,
        newComment.tags,
        newComment.text,
        newComment.checked,
        newComment.author,
      );
      setInputValue(''); // Clear main comment input
    }

    setReplyTo({ id: null, name: null }); // Reset reply state
  };

  const deleteCommentOrReply = (
    comments: Comment[],
    commentId: string,
  ): Comment[] => {
    return comments.reduce<Comment[]>((acc, comment) => {
      if (comment.id === commentId) return acc;
      const updatedReplies = deleteCommentOrReply(comment.replies, commentId);
      return [...acc, { ...comment, replies: updatedReplies }];
    }, []);
  };

  const handleDelete = (
    commentId: string,
    commentsData: ProjectComment[],
    setComments: Dispatch<SetStateAction<Comment[]>>,
    handleDeleteComment: (commentId: string) => void,
  ) => {
    const commentToDelete = commentsData.find(
      (comment) => comment.id === commentId,
    );
    console.log({ commentId, commentsData, setComments, handleDeleteComment });
    console.log({ commentToDelete });

    if (commentToDelete) {
      // Check if the comment is a top-level comment (no parent)
      if (!commentToDelete.parent) {
        // Confirmation message for deleting a top-level comment and its replies
        const confirmDelete = window.confirm(
          'You are about to delete this comment and all of its replies. Are you sure?',
        );

        if (confirmDelete) {
          // Remove the top-level comment and all nested replies

          const deleteNestedReplies = (
            commentsList: Comment[],
            targetId: string,
          ): Comment[] => {
            return commentsList.filter((comment) => {
              if (comment.id === targetId) {
                // Recursively delete replies from the backend
                console.log(comment.id, targetId);

                comment.replies.forEach((reply) =>
                  handleDeleteComment(reply.id),
                );
                handleDeleteComment(comment.id); // Delete the main comment
                return false; // Remove this comment from the list
              }
              // Recursively check and update replies
              comment.replies = deleteNestedReplies(comment.replies, targetId);
              return true;
            });
          };

          setComments((prevComments) => {
            console.log('Setting comments');

            console.log({ prevComments });

            return deleteNestedReplies(prevComments, commentId);
          });
        }
      } else {
        // Confirmation message for deleting a reply only
        const confirmDelete = window.confirm(
          'Are you sure you want to delete this reply?',
        );

        if (confirmDelete) {
          // Remove only the specific reply from the nested structure
          setComments((prevComments) =>
            deleteCommentOrReply(prevComments, commentId),
          );

          // Here is the delete query
          handleDeleteComment(commentId);
        }
      }
    }
  };

  return { handleSend, deleteCommentOrReply, handleDelete };
};

export const useDialogManagement = (): DialogManagement => {
  const [selectedComment, setSelectedComment] = useState<{
    id: string;
    isReply: boolean;
    currentChecked: boolean;
  } | null>(null);

  const [previousCheckedState, setPreviousCheckedState] = useState<
    boolean | null
  >(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCheckComment = async (
    commentId: string,
    currentChecked: boolean,
    comments: Comment[],
    setComments: Dispatch<SetStateAction<Comment[]>>,
  ) => {
    // Save the previous state for rollback in case of failure
    const previousComments = comments;

    // Optimistically update the UI
    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.id === commentId
          ? { ...comment, checked: !currentChecked }
          : {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply.id === commentId
                  ? { ...reply, checked: !currentChecked }
                  : reply,
              ),
            },
      ),
    );

    try {
    } catch (error) {
      // Rollback the state in case of failure
      setComments(previousComments);
      console.error('Failed to update comment:', error);
      alert('Failed to update comment. Please try again.');
    }
  };

  const handleOpenDialog = (
    commentId: string,
    isReply: boolean,
    currentChecked: boolean,
  ) => {
    // Save the current checked state for potential rollback
    setPreviousCheckedState(currentChecked);
    setSelectedComment({ id: commentId, isReply, currentChecked });
    setDialogOpen(true);
  };

  const handleCloseDialog = (
    setComments: (value: SetStateAction<Comment[]>) => void,
  ) => {
    // Revert to the previous state if dialog is cancelled
    if (selectedComment) {
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === selectedComment.id
            ? { ...comment, checked: previousCheckedState ?? false } // Ensure checked is a boolean
            : {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply.id === selectedComment.id
                    ? { ...reply, checked: previousCheckedState ?? false } // Ensure checked is a boolean
                    : reply,
                ),
              },
        ),
      );
    }
    setDialogOpen(false);
    setSelectedComment(null);
  };

  const handleConfirmDialog = async (
    comments: Comment[],
    setComments: (value: SetStateAction<Comment[]>) => void,
  ) => {
    if (selectedComment) {
      const { id, currentChecked } = selectedComment;
      try {
        // Toggle the state and perform the mutation
        await handleCheckComment(id, currentChecked, comments, setComments);
      } catch (error) {
        console.error('Failed to update comment:', error);
      }
    }
    setDialogOpen(false);
    setSelectedComment(null);
  };

  return {
    dialogOpen,
    handleOpenDialog,
    handleCloseDialog,
    handleConfirmDialog,
    selectedComment,
    setSelectedComment,
  };
};

export const useCommentDataManagement = (): CommentDataManagement => {
  const [comments, setComments] = useState<Comment[]>([]);

  const [loadingComments, setLoadingComments] = useState<{
    [key: string]: boolean;
  }>({});

  const filteredCommentsData = (
    commentsData: ProjectComment[],
    stepComment: string,
  ) =>
    useMemo(() => {
      return commentsData.filter(
        (comment) => comment.projectstep === stepComment,
      );
    }, [commentsData, stepComment]);

  const getFilteredComments = (filter: string, comments: Comment[]) => {
    if (!filter) return comments;
    return comments
      .map((comment) => ({
        ...comment,
        replies: comment.replies.filter((reply) =>
          reply.text.toLowerCase().includes(filter.toLowerCase()),
        ),
      }))
      .filter(
        (comment) =>
          comment.text.toLowerCase().includes(filter.toLowerCase()) ||
          comment.replies.length > 0,
      );
  };

  const nestComments = (
    comments: ProjectComment[],
    ghgciId: string,
  ): Comment[] => {
    const commentMap = new Map<string, Comment>();

    // Initialize all comments in the map
    comments.forEach((comment) => {
      commentMap.set(comment.id, {
        ...comment,
        replies: [],
        isMine: comment.author === ghgciId,
      });
    });

    const nestedComments: Comment[] = [];

    comments.forEach((comment) => {
      if (comment.parent) {
        // Add the comment as a reply to its parent
        const parentComment = commentMap.get(comment.parent);
        if (parentComment) {
          parentComment.replies.push(commentMap.get(comment.id)!);
        }
      } else {
        // Add top-level comments to the nested array
        nestedComments.push(commentMap.get(comment.id)!);
      }
    });

    return nestedComments;
  };

  return {
    comments,
    setComments,
    loadingComments,
    setLoadingComments,
    filteredCommentsData,
    getFilteredComments,
    nestComments,
  };
};

export const useTextManagement = (): TextManagement => {
  const [inputValue, setInputValue] = useState<string>('');
  const [filterText, setFilterText] = useState<string>('');

  const highlightText = (text: string) => {
    if (!filterText) return text;
    const parts = text.split(new RegExp(`(${filterText})`, 'gi'));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === filterText.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </>
    );
  };
  return {
    inputValue,
    setInputValue,
    filterText,
    setFilterText,
    highlightText,
  };
};
