import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  useTheme,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import { useProjectsDataContext } from '../../../../react-query-toolkit/state/projects-context';
import { ProjectComment } from '../../../../react-query-toolkit/state/types';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';
import { useUserDataContext } from '../../../../react-query-toolkit/state/user-context';
import { useTranslateAll } from '../../../../locales/translation-hooks';
import {
  getTranslationKeyMapping,
  ProjectStepsTranslationKeys,
  projectStepsTranslationMappings,
} from '../../../../locales/mappings';
import {
  useTextManagement,
  useBackendManagement,
  useCommentDataManagement,
  useCommentEditingManagement,
  useDialogManagement,
  useMenuStateManagement,
  useReplyManagement,
} from './hooks';
import { CommentCard } from './comment-card';
import {
  BackendManagementProps,
  CommentDataManagementProps,
  CommentEditingManagementProps,
  DialogManagementProps,
  getDefaultObject,
  MenuStateManagementProps,
  ReplyManagementProps,
  TextManagementProps,
} from './types/comment-card-props-types';

export interface Comment extends ProjectComment {
  replies: Comment[];
  isMine: boolean;
}

interface CommentsDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  stepComment: string;
}

export const ProjectComments: React.FC<CommentsDrawerProps> = ({
  open,
  toggleDrawer,
  stepComment,
}) => {
  const theme = useTheme();
  const {
    handleGetProject,
    isGetProjectsLoading,
    ghgciId,
    handleUpdateProjectComment,
    handleDeleteComment,
    handleCreateProjectComment,
  } = useProjectsDataContext();

  const { handleGetAllUsers, isGetUserLoading } = useUserDataContext();
  const rawUserData = handleGetAllUsers() || [];
  const rawProjectData = handleGetProject();
  const commentsData: ProjectComment[] = rawProjectData?.projectComments || [];

  function assignToObject<T extends object>(
    values: Partial<T>,
    formTemplate: T,
  ): T {
    const result = {} as T;

    for (const key in formTemplate) {
      if (
        key in values &&
        typeof values[key as keyof T] === typeof formTemplate[key]
      ) {
        result[key] = values[key as keyof T] as T[typeof key];
      }
    }

    return result;
  }

  const rawMenuStateManagement = useMenuStateManagement();

  const menuStateManagement = assignToObject(
    rawMenuStateManagement,
    getDefaultObject('menuManagement') as MenuStateManagementProps,
  );

  const rawReplyManagement = useReplyManagement();
  const {
    inputRef,
    replyTo,
    replyInputValue,
    setReplyInputValue,
    toggleAllRepliesVisibility,
    setReplyVisibility,
    areAllRepliesVisible,
    setAreAllRepliesVisible,
    addReply,
    setReplyTo,
  } = rawReplyManagement;

  const replyManagement = assignToObject(
    rawReplyManagement,
    getDefaultObject('replyManagement') as ReplyManagementProps,
  );

  const rawCommentEditingManagement = useCommentEditingManagement();

  const commentEditingManagement = assignToObject(
    rawCommentEditingManagement,
    getDefaultObject(
      'commentEditingManagement',
    ) as CommentEditingManagementProps,
  );

  const rawDialogManagement = useDialogManagement();
  const {
    handleCloseDialog,
    handleConfirmDialog,
    dialogOpen,
    selectedComment,
  } = rawDialogManagement;

  const dialogManagement = assignToObject(
    rawDialogManagement,
    getDefaultObject('dialogManagement') as DialogManagementProps,
  );

  const rawBackendManagement = useBackendManagement();
  const { handleSend, deleteCommentOrReply } = rawBackendManagement;

  const backendManagement = assignToObject(
    rawBackendManagement,
    getDefaultObject('backendManagement') as BackendManagementProps,
  );

  const rawTextManagement = useTextManagement();

  const { inputValue, setInputValue, filterText, setFilterText } =
    rawTextManagement;

  const textManagement = assignToObject(
    rawTextManagement,
    getDefaultObject('textManagement') as TextManagementProps,
  );

  const rawCommentDataManagement = useCommentDataManagement();

  const {
    comments,
    setComments,
    filteredCommentsData,
    getFilteredComments,
    nestComments,
  } = rawCommentDataManagement;

  const commentDataManagement = assignToObject(
    rawCommentDataManagement,
    getDefaultObject('commentDataManagement') as CommentDataManagementProps,
  );

  useEffect(() => {
    if (!open) {
      setInputValue(''); // Clear the input when the drawer is closed
    } else {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  }, [open]);

  const _filteredCommentsData = filteredCommentsData(commentsData, stepComment);

  useEffect(() => {
    const nested = nestComments(_filteredCommentsData, ghgciId);
    // Only update state if the nested data is different
    if (JSON.stringify(comments) !== JSON.stringify(nested)) {
      setComments(nested);
    }
  }, [filteredCommentsData]);

  useState<boolean>(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterText(value);
    if (value.trim()) {
      setReplyVisibility({});
      setAreAllRepliesVisible(true);
    } else {
      setAreAllRepliesVisible(false);
    }
  };
  useEffect(() => {
    if (open) {
      inputRef.current?.focus(); // Focus the input when the drawer opens
    }
  }, [open]);

  const { translateCommon, translateProjects } = useTranslateAll([
    'common',
    'projects',
  ]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: { sm: '100%', md: 300 },
            boxSizing: 'border-box',
            top: 60,
          },
          '& .MuiBackdrop-root': { backgroundColor: 'transparent !important' },
        }}
      >
        <Box
          p={6}
          display="flex"
          flexDirection="column"
          height="calc(100vh - 100px)"
        >
          <Box display="block" alignItems="center" mb={2}>
            <Box
              display={'flex'}
              width={'100%'}
              paddingBottom={0}
              justifyContent="flex-end"
            >
              <IconButton onClick={toggleDrawer}>
                <CloseIcon color="primary" />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder={translateProjects('searchCommentsPlaceholder')}
              value={filterText}
              onChange={handleSearchChange}
              autoFocus
              sx={{
                '.MuiInputBase-input': { fontVariant: 'body3' },
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  paddingY: `${theme.spacing(3)} !important`,
                  paddingX: `${theme.spacing(4)} !important`,
                },
                '& .MuiInputBase-input': {
                  ...theme.typography.body3, // Apply body3 variant directly
                  padding: '0 8px', // Align text properly
                  typography: 'secondary.main',
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      if (filterText) {
                        setFilterText('');
                        setAreAllRepliesVisible(false);
                        setReplyVisibility({});
                      }
                    }}
                    sx={{ padding: 0 }}
                  >
                    {filterText ? (
                      <CloseIcon sx={{ color: 'primary.dark' }} /> // Shows CloseIcon if filterText exists
                    ) : (
                      <SearchIcon sx={{ color: 'primary.dark' }} /> // Shows SearchIcon otherwise
                    )}
                  </IconButton>
                ),
              }}
            />
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent="space-between"
            >
              <Typography variant="h6" color="info.main">
                {translateCommon('headers.headerProjectComments')}
              </Typography>
              <Typography
                onClick={toggleAllRepliesVisibility}
                variant="link"
                color="primary.light"
              >
                {areAllRepliesVisible
                  ? translateCommon('collapseAll')
                  : translateCommon('expandAll')}
              </Typography>
            </Box>
            <Typography variant="subtitle2" color="text.primary">
              {capitalizeFirstLetter(
                translateProjects(
                  getTranslationKeyMapping(
                    stepComment as keyof ProjectStepsTranslationKeys,
                    projectStepsTranslationMappings,
                  ),
                ),
              )}
            </Typography>
          </Box>

          {isGetUserLoading || isGetProjectsLoading ? (
            // Display the loading message while data is being fetched
            <Box
              flexGrow={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Loading...</Typography>
            </Box>
          ) : (
            // Render comments once data is loaded
            <Box flexGrow={1} overflow="auto">
              {getFilteredComments(filterText, comments).map((comment) => (
                <CommentCard
                  key={comment.id}
                  ghgciId={ghgciId}
                  stepComment={stepComment}
                  commentsData={commentsData}
                  comment={comment}
                  indentLevel={0}
                  rawUserData={rawUserData}
                  handleUpdateProjectComment={handleUpdateProjectComment}
                  handleCreateProjectComment={handleCreateProjectComment}
                  handleDeleteComment={handleDeleteComment}
                  deleteCommentOrReply={deleteCommentOrReply}
                  menuStateManagement={menuStateManagement}
                  commentEditingManagement={commentEditingManagement}
                  replyManagement={replyManagement}
                  dialogManagement={dialogManagement}
                  backendManagement={backendManagement}
                  textManagement={textManagement}
                  commentDataManagement={commentDataManagement}
                  ref={inputRef}
                />
              ))}
            </Box>
          )}
          <Box mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={translateProjects('addCommentPlaceholder')}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              disabled={!!replyTo.id} // Disable the comment input when replying
              inputRef={inputRef}
              sx={{
                '.MuiInputBase-input': { fontVariant: 'body3' },
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  paddingY: `${theme.spacing(3)} !important`,
                  paddingX: `${theme.spacing(4)} !important`,
                },
                '& .MuiInputBase-input': {
                  ...theme.typography.body3, // Apply body3 variant directly
                  padding: '0 8px', // Align text properly
                  typography: 'secondary.main',
                },
              }}
              multiline
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() =>
                      handleSend(
                        ghgciId,
                        stepComment,
                        replyTo,
                        replyInputValue,
                        inputValue,
                        setInputValue,
                        setComments,
                        addReply,
                        setReplyTo,
                        setReplyInputValue,
                        handleCreateProjectComment,
                      )
                    }
                    sx={{ padding: 0 }}
                  >
                    <SendIcon sx={{ color: 'primary.main' }} />
                  </IconButton>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend(
                    ghgciId,
                    stepComment,
                    replyTo,
                    replyInputValue,
                    inputValue,
                    setInputValue,
                    setComments,
                    addReply,
                    setReplyTo,
                    setReplyInputValue,
                    handleCreateProjectComment,
                  );
                } else if (e.key === 'Enter' && e.shiftKey) {
                  setInputValue((prevValue) => prevValue + '\n');
                }
              }}
            />
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          {translateProjects('confirmAction')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            You are about to{' '}
            {selectedComment?.currentChecked ? 'unmark' : 'mark'} this{' '}
            {selectedComment?.isReply ? 'reply' : 'comment'} as addressed. Do
            you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            {translateCommon('buttons.cancelButton')}
          </Button>
          <Button onClick={handleConfirmDialog} color="primary" autoFocus>
            {translateCommon('buttons.okButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// const renderComment = (comment: Comment, indentLevel: number = 0) => {
//   const user =
//     rawUserData.length > 0 ? findUserById(comment.author, rawUserData) : null;
//   const userName = user ? user.firstname : 'Unknown User';
//   const repliesCount = comment.replies.length;
//   const areRepliesVisible = replyVisibility[comment.id] || false;
//   const formattedDate = DateTime.fromISO(comment.created_at)
//     .setLocale(i18n.language)
//     .toLocaleString(DateTime.DATE_MED);

//   const isMenuOpen = Boolean(menuState[comment.id]);
//   const isEditing = editingComment?.id === comment.id;

//   return (
//     <Box key={comment.id} ml={indentLevel * 2}>
//       <Card
//         elevation={comment.parent ? 0 : 3} // Set elevation to 0 for replies and 3 for comments
//         sx={{ my: 4, mx: 2, paddingX: 5, paddingY: 4 }}
//       >
//         <Stack direction="row" alignItems="center" spacing={1} mb={1}>
//           <Avatar sx={{ width: theme.spacing(6), height: theme.spacing(6) }}>
//             <Icon>{userName}</Icon>
//           </Avatar>
//           <Typography color="primary.dark" variant="subtitle2">
//             {comment.isMine ? translateProjects('me') : userName}
//           </Typography>
//           <Typography color="secondary.dark" variant="body3">
//             {formattedDate}
//           </Typography>
//           <Box flexGrow={1} />
//           <IconButton
//             onClick={() =>
//               handleOpenDialog(comment.id, !!comment.parent, comment.checked)
//             }
//             sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
//           >
//             {loadingComments[comment.id] ? (
//               <Box>
//                 <CircularProgress size={24} />
//               </Box>
//             ) : comment.checked ? (
//               <CircleIcon
//                 color="primary"
//                 sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
//               />
//             ) : (
//               <CheckCircleOutlineIcon
//                 color="primary"
//                 sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
//               />
//             )}
//           </IconButton>
//           {comment.isMine && (
//             <IconButton
//               id={`menu-button-${comment.id}`}
//               aria-controls={isMenuOpen ? `menu-${comment.id}` : undefined}
//               aria-haspopup="true"
//               aria-expanded={isMenuOpen ? 'true' : undefined}
//               onClick={(e) => handleMenuToggle(comment.id, e.currentTarget)}
//               sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
//             >
//               <MoreVertIcon
//                 color="primary"
//                 sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
//               />
//             </IconButton>
//           )}
//           <Popper
//             open={isMenuOpen}
//             anchorEl={menuState[comment.id]}
//             role={undefined}
//             placement="bottom-start"
//             transition
//             disablePortal
//           >
//             {({ TransitionProps }) => (
//               <Grow
//                 {...TransitionProps}
//                 style={{
//                   transformOrigin: 'left top',
//                 }}
//               >
//                 <Paper>
//                   <ClickAwayListener
//                     onClickAway={() => handleMenuClose(comment.id)}
//                   >
//                     <MenuList
//                       id={`menu-${comment.id}`}
//                       aria-labelledby={`menu-button-${comment.id}`}
//                       sx={{
//                         backgroundColor: 'secondary.dark',
//                         borderRadius: theme.spacing(2),
//                       }}
//                     >
//                       <MenuItem
//                         onClick={() => {
//                           handleEditComment(comment.id, comment.text);
//                           handleMenuClose(comment.id);
//                         }}
//                         sx={{ typography: 'body2', color: 'bg.box' }}
//                       >
//                         {translateProjects('actions.edit')}
//                       </MenuItem>
//                       <MenuItem
//                         onClick={() => {
//                           handleDelete(comment.id, commentsData);
//                           handleMenuClose(comment.id);
//                         }}
//                         sx={{ typography: 'body2', color: 'bg.box' }}
//                       >
//                         {translateProjects('actions.delete')}
//                       </MenuItem>
//                     </MenuList>
//                   </ClickAwayListener>
//                 </Paper>
//               </Grow>
//             )}
//           </Popper>
//         </Stack>

//         {isEditing ? (
//           <Box>
//             <TextField
//               fullWidth
//               variant="outlined"
//               value={editingComment.text}
//               onChange={handleEditChange}
//               multiline
//               rows={2}
//             />
//             <Box display="flex" justifyContent="flex-end" mt={1}>
//               <Button onClick={handleCancelEdit} color="secondary">
//                 {translateCommon('buttons.cancelButton')}
//               </Button>
//               <Button
//                 onClick={() => handleSaveEdit(commentsData)}
//                 color="primary"
//                 variant="contained"
//                 sx={{ ml: 1 }}
//               >
//                 {translateCommon('buttons.saveButton')}
//               </Button>
//             </Box>
//           </Box>
//         ) : (
//           <Typography variant="body3" color="text.primary" mb={1}>
//             {highlightText(comment.text)}
//           </Typography>
//         )}

//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//         >
//           {!comment.parent && (
//             <Box
//               display="flex"
//               alignItems="center"
//               onClick={() => handleReply(comment.id, userName)}
//             >
//               <IconButton>
//                 {replyTo.id === comment.id ? (
//                   <CloseIcon
//                     color="primary"
//                     sx={{ width: theme.spacing(4), height: 'auto' }}
//                   />
//                 ) : (
//                   <ReplyIcon
//                     color="primary"
//                     sx={{ width: theme.spacing(4), height: 'auto' }}
//                   />
//                 )}
//               </IconButton>
//               <Typography color="primary.light" variant="link">
//                 {replyTo.id === comment.id
//                   ? translateProjects('actions.close')
//                   : translateProjects('actions.reply')}
//               </Typography>
//             </Box>
//           )}
//           {repliesCount > 0 && (
//             <Typography
//               variant="link"
//               color="primary.light"
//               sx={{ cursor: 'pointer', mt: 1 }}
//               onClick={() => toggleRepliesVisibility(comment.id)}
//             >
//               {areRepliesVisible
//                 ? translateProjects('actions.hideReplies')
//                 : translateProjects('actions.showReplies')}
//               {/*: `${repliesCount} replies - Last reply today at ${new Date().toLocaleTimeString()}`}*/}
//             </Typography>
//           )}
//         </Box>

//         {/* Render reply TextField if this comment is being replied to */}
//         {replyTo.id === comment.id && (
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Type a Reply..."
//             value={replyInputValue}
//             onChange={(e) => setReplyInputValue(e.target.value)}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter' && !e.shiftKey) {
//                 e.preventDefault();
//                 handleSend(stepComment);
//               } else if (e.key === 'Enter' && e.shiftKey) {
//                 setReplyInputValue((prevValue) => prevValue + '\n');
//               }
//             }}
//             inputRef={inputRef}
//             sx={{
//               mt: 2,
//               '& .MuiOutlinedInput-root': {
//                 borderRadius: '4px',
//                 backgroundColor: 'bg.main',
//                 height: 'auto',
//                 padding: '0px 10px 0px 30px',
//               },
//               '& .MuiInputBase-input': {
//                 ...theme.typography.body3,
//                 padding: '8px',
//                 whiteSpace: 'pre-wrap',
//               },
//             }}
//             multiline
//             InputProps={{
//               endAdornment: (
//                 <IconButton
//                   onClick={() => {
//                     handleSend(stepComment);
//                     setReplyInputValue(''); // Clear reply input after sending
//                   }}
//                 >
//                   <SendIcon sx={{ color: '#4682b4' }} />
//                 </IconButton>
//               ),
//             }}
//           />
//         )}

//         {(areRepliesVisible || areAllRepliesVisible) && (
//           <>
//             {comment.replies.map((reply) => (
//               <Box
//                 sx={{
//                   borderTop: `1px solid ${theme.palette.notificationBorderShadow.main}`,
//                 }}
//               >
//                 {renderComment(reply, indentLevel + 1)}
//               </Box>
//             ))}
//           </>
//         )}
//         <Divider sx={{ my: 2 }} />
//       </Card>
//     </Box>
//   );
// };
