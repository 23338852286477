{
  "NavBar": {
    "navBarNavigationItems": {
      "myClients": "Meine Kunden",
      "consultants": "Berater",
      "dashboard": "Dashboard",
      "projects": "Projekte",
      "tasks": "Aufgaben",
      "userProfile": "Mein Profil",
      "calendar": "Kalender",
      "notifications": "Benachrichtigungen",
      "reports": "Berichte",
      "signout": "Abmelden",
      "systemManager": ""
    },
    "taskManager": "Aufgaben-Manager"
  },
  "systemManagerSideBar": {
    "sideBarNavigationItems": {
      "systemMonitoring": "",
      "mandates": "",
      "projectTypes": "",
      "clientPools": "",
      "programs": "",
      "emissionFactors": "",
      "transformations": "",
      "jurisdictions": "",
      "sofConect": "",
      "reports": ""
    }
  }
}
