import React, { forwardRef } from 'react';
import { Avatar, Box, Button, Input, Stack, Typography } from '@mui/material';
import { ImageUploader, Preview, StatusInfo } from './image-uploader';
import { profilePictureEditIcon } from '../icons/svg-icons';
import theme from '../../../../theme/theme';
import { User } from '../../../../react-query-toolkit/state/types';

export interface EditableProfilePictureProps {
  inputChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  preview: Preview;
  defaultProfilePicture: string | JSX.Element;
  profilePictureUrl: string;
  userFirstName: string;
}

export const ProfilePictureControl = ({
  userData,
  openStateSetter,
}: {
  userData: User;
  openStateSetter: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const title = 'Photo';

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="subtitle2">{title}</Typography>
      <ImageUploader
        isAlternate={true}
        userData={userData}
        openStateSetter={openStateSetter}
      />
    </Stack>
  );
};

export const PhotoActionButton: React.FC<StatusInfo> = ({
  text,
  buttonClickHandler,
  isButtonDisabled,
  isButtonHidden,
}) => {
  return (
    <Box display={isButtonHidden ? 'none' : 'flex'} alignItems="center">
      <Button
        variant="outlined"
        onClick={buttonClickHandler}
        disabled={isButtonDisabled}
        sx={{ px: 5, py: 3 }}
      >
        <Typography variant="caption">{text}</Typography>
      </Button>
    </Box>
  );
};

export const EditableProfilePicture = forwardRef<
  HTMLLabelElement,
  EditableProfilePictureProps
>(
  (
    {
      inputChangeHandler,
      preview,
      defaultProfilePicture,
      profilePictureUrl,
      userFirstName,
    },
    ref,
  ) => {
    const hasProfilePicture = profilePictureUrl ?? false;

    return (
      <Box
        p={2}
        position={'relative'}
        height={'auto'}
        display={'flex'}
        alignItems={'center'}
        maxWidth={'36px'}
        maxHeight={'36px'}
      >
        {hasProfilePicture || preview || userFirstName ? (
          <Avatar
            alt={`${userFirstName}'s profile picture `}
            src={preview ?? profilePictureUrl ?? 'nullPlaceholder'}
            sx={{ maxWidth: 'inherit', maxHeight: 'inherit' }}
          />
        ) : (
          defaultProfilePicture
        )}
        <Box
          position={'absolute'}
          bottom={theme.spacing(1)}
          right={theme.spacing(1)}
          width={'fit-content'}
        >
          <label
            ref={ref}
            htmlFor="profile-picture-input"
            style={{
              cursor: 'pointer',
              width: theme.spacing(5),
              height: theme.spacing(5),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {profilePictureEditIcon}
          </label>
          <Input
            id="profile-picture-input"
            type="file"
            onChange={inputChangeHandler}
            inputProps={{ accept: 'image/jpeg, image/png' }}
            sx={{ display: 'none' }}
          />
        </Box>
      </Box>
    );
  },
);

EditableProfilePicture.displayName = 'EditableProfilePicture';
