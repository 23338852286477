import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';
import { colors } from 'utils/colors';
import theme from '../../../../theme/theme';

export const TableContainer = styled.div`
  border: 1.5px;
  background-color: ${colors.white};
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
  position: page;
  max-width: ${theme.breakpoints.values.xxl}px;
`;

export const TableTopContainer = styled.div<{ showSearchInput?: boolean }>`
  display: flex;
  justify-content: right;
  align-items: center;
  align-self: stretch;
  padding: 2px 0 2px 0;
`;

export const StyledTable = styled.table`
  width: 100%;
`;

export const StyledInput = styled.input`
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0;
  color: ${colors.black};
`;

export const SearchButton = styled.button`
  color: white;
  float: right;
  width: 40px;
  height: 40px;
  background: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
`;

export const SearchContainer = styled.div`
  position: inherit;
  margin-right: 40px;
  background: ${colors.lightestGrey};
  border-radius: 40px;

  &:hover ${StyledInput} {
    width: 240px;
    padding: 0 4px 0 15px;
  }
  &:hover ${SearchButton} {
    background: ${colors.lightestGrey};
    border-radius: 50%;
    color: ${colors.lightestGrey};
  }
`;

export const StyledThead = styled.thead<{ hide?: boolean }>`
  border-bottom: 1px solid ${colors.lineGray};
  color: ${colors.secondaryBlue};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  overflow: hidden;
  height: 0;
  line-height: normal;
  ${({ hide }) =>
    hide
      ? `
    display: none;
  `
      : ''}
`;
export const StyledTr = styled.tr<{ hide?: boolean }>`
  border-top: 1px solid ${colors.lineGray};
  ${({ hide }) =>
    hide
      ? `
    display: none;
  `
      : ''}
`;

//This component prop usage must be complemented with the hiding of the subcomponent main container Otherwise you will see a
// little gap
export const SubComponentTr = styled.tr<{
  hideSubComponentFromSizeLg?: boolean;
  hideSubComponentFromSizeMd?: boolean;
  hideSubComponentFromSizeSm?: boolean;
  showSubComponent?: boolean;
}>`
  background: ${colors.subRowGray};

  /* Always hide if showSubComponent is false */
  ${({ showSubComponent }) => (!showSubComponent ? 'display: none;' : '')};

  /* Hide from md and up if hideSubComponentFromSizeSm is true */
  @media screen and (min-width: ${breakpoints.md}) {
    ${({ hideSubComponentFromSizeSm }) =>
      hideSubComponentFromSizeSm ? 'display: none !important;' : ''};
  }

  /* Hide from lg and up if hideSubComponentFromSizeMd is true */
  @media screen and (min-width: ${breakpoints.lg}) {
    ${({ hideSubComponentFromSizeMd }) =>
      hideSubComponentFromSizeMd ? 'display: none !important;' : ''};
  }

  /* Always hide if hideSubComponentFromSizeLg is true */
  ${({ hideSubComponentFromSizeLg }) =>
    hideSubComponentFromSizeLg ? 'display: none !important;' : ''};
`;
export const StyledTh = styled.th`
  padding: 8px 0 8px 0;
  border-bottom: 1px solid ${colors.lineGray};
  cursor: pointer;
`;
export const StyledTbody = styled.tbody`
  border-bottom: 1px solid ${colors.lineGray};
  padding: 0 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
`;
export const ArrowContainer = styled.div`
  padding-left: 4px;
`;

export const StyledTd = styled.td<{
  rowIsSelected: boolean;
}>`
  border-bottom: 1px solid ${colors.lineGray};
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: ${colors.darkGrey};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.044px;
`;

export const FooterContainer = styled.div<{
  showFooter: boolean;
}>`
  display: flex;
  ${(props) => (props.showFooter ? '' : 'display: none;')};
  height: 45px;
  align-items: center;
  align-self: stretch;
  color: ${colors.darkGrey};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  justify-content: space-between;
`;

export const ItemsPerPageContainer = styled.div`
  display: flex;
  padding-left: 0;
  align-items: center;
  gap: 8px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  height: 45px;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1 0 0;
`;
export const StyledDropDownMenu1 = styled.select`
  border: none;
  margin-left: 20px;
  margin-right: 20px;

  display: flex;
  width: 50px;
  height: 41px;
  padding-right: 0;
  align-items: center;

  @media screen and (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

export const StyledOptionMenu = styled.option``;

export const PageNavigationContainer = styled.div`
  display: flex;
  height: 45px;
  justify-content: flex-end;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 42px;
  height: 45px;
  padding: 13px;
  justify-content: center;
  align-items: center;
`;
export const ExpandingButtonContainer = styled.div`
  align-items: center;
  padding-left: 18px;
  width: 100%;
  display: inline-flex;
  position: relative;
  align-content: center;
`;
export const ExpandingButtonContainerDashboardTask = styled.div`
  align-items: center;
  padding-left: 12px;
  width: 100%;
  display: inline-flex;
  position: relative;
  align-content: center;
`;
export const DisplayTableTaskCell = styled.div`
  align-items: center;
  display: flex;
`;

export const ConditionalTextContainerHeader = styled.div`
  color: ${colors.secondaryBlue};
  width: 100px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.048px;
  padding: 10px 0;
`;

export const ConditionalTextContainerInfo = styled.div`
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  max-width: 150px;
  -webkit-letter-spacing: 0.048px;
  -moz-letter-spacing: 0.048px;
  -ms-letter-spacing: 0.048px;
  letter-spacing: 0.048px;
  padding: 10px 0;

  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide the overflow text */
  text-overflow: ellipsis;
  @media screen and (min-width: ${breakpoints.md}) {
    max-width: 300px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    max-width: 150px;
  }
`;

export const StyledSubRow = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  align-content: center;
`;
export const TruncatedTextCell = styled.div`
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide the overflow text */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflow text */
`;

export const SubRowContainer = styled.div`
  display: flex;
  padding-left: 62px;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.lg}) {
    border-bottom: 1px solid ${colors.lineGray};
  }

  @media screen and (max-width: ${breakpoints.md}) {
    padding-left: 62px;
    border-bottom: 1px solid ${colors.lineGray};
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding-left: 42px;
    border-bottom: 1px solid ${colors.lineGray};
  }
`;
export const StyledFirstSubRow = styled.div`
  display: flex;
  width: 100%;
  align-content: center;
`;
export const RowTableContainer = styled.div<{ last?: boolean }>`
  @media screen and (min-width: ${breakpoints.md}) {
    display: none;
  }
`;
export const RowMobileContainer = styled.div<{ last?: boolean }>`
  @media screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

export const ConsultantContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const SubConsultantContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ConsultantTitleContainer = styled.div`
  white-space: nowrap;
`;

export const ImageContainer = styled.div`
  padding-top: 9px;
`;
export const ProfileImageIcon = styled.div<{
  move: string;
  color: string;
  zIndex: string;
}>`
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.color};
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  right: ${(props) => props.move};
  border-radius: 50%;
  z-index: ${(props) => props.zIndex};
  &:hover {
    z-index: 101;
    background-color: ${colors.secondaryBlue};
  }
`;

export const InnerText = styled.p`
  color: white;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  width: 20px;
  font-size: 10px;
`;

export const TaskCell = styled.div<{
  rowIsSelected: boolean;
}>`
  width: 100%;
  height: 45px;
  align-content: center;
  align-self: stretch;
  color: ${colors.darkGrey};
  display: flex;
  align-items: center;
`;

export const FlagPriorityHandlerContainer = styled.div`
  white-space: nowrap;
  display: flex;
`;
