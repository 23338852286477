import {
  Button,
  Snackbar,
  SnackbarContent,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface ActionFeedbackProps {
  isOpen: boolean;
  action?: React.ReactNode;
  message: string;
  onClose?: () => void;
  alignment?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  content?: JSX.Element;
  autoHideDuration?: number;
}

interface DefaultActionProps {
  onClick: () => void;
  title: string;
}

export const DefaultAction = ({ onClick, title }: DefaultActionProps) => (
  <Button color="error" size="small" onClick={onClick}>
    {title}
  </Button>
);

export const ActionFeedback = ({
  isOpen,
  action,
  message,
  onClose,
  alignment,
  content,
  autoHideDuration,
}: ActionFeedbackProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(650));

  return (
    <Snackbar
      open={isOpen}
      key={message}
      onClose={onClose ?? (() => {})}
      anchorOrigin={
        alignment ?? {
          vertical: 'bottom',
          horizontal: isSmallScreen ? 'center' : 'right',
        }
      }
      sx={{ width: '100%' }}
      autoHideDuration={autoHideDuration}
    >
      {content ?? (
        <SnackbarContent
          message={message}
          action={action}
          sx={{ borderRadius: 4 }}
        />
      )}
    </Snackbar>
  );
};
