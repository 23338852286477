{
  "pageTitle": "Gestion du système",
  "titles": {
    "systemNotices": "Notifications du système",
    "consultants": "Consultants",
    "totalClients": "Nombre total de clients",
    "openProjects": "Projets ouverts"
  },
  "labels": {
    "systemNotice": "Notification du système",
    "consultant": "Consultant",
    "client": "Client",
    "project": "Projet"
  },
  "tooltTips": {
    "addNew": "Ajouter un nouveau {{what}}",
    "editNew": "",
    "addNew_m": "Ajouter un nouveau {{what}}",
    "addNew_f": "Ajouter une nouvelle {{what}}",
    "addNew_n": "Ajouter un nouvel {{what}}",
    "addNewCustomProject": "Ajouter un nouveau projet personnalisé",
    "addNewSchedule": "Ajouter un nouvel horaire",
    "edit": "Modifier ce {{what}}",
    "edit_m": "Modifier ce {{what}}",
    "edit_f": "Modifier cette {{what}}",
    "edit_n": "Modifier cet {{what}}",
    "editCustomProject": "Modifier ce projet personnalisé",
    "editThisSchedule": "Modifier cet horaire",
    "archive": "Archiver ce {{what}}",
    "archive_m": "Archiver ce {{what}}",
    "archive_f": "Archiver cette {{what}}",
    "archive_n": "Archiver cet {{what}}",
    "archiveCustomProject": "Archiver ce projet personnalisé",
    "review": "Examiner puis accepter/rejeter la demande du client",
    "seeAllClientsRequests": "Voir toutes les demandes des clients",
    "seeAllCustomProjects": "Voir tous les projets personnalisés",
    "seeAllSchedules": "Voir tous les horaires"
  },
  "displayCustomProjectsTable": {
    "displayTableFetchingErrorMessage": "Une erreur est survenue",
    "displayTableNoItemsMessage": "Aucun élément à afficher"
  }
}
