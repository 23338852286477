import { noop } from 'lodash';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Preview } from '../../../pages/user-profile/components/profile/image-uploader';

type NavAndSideBarContext = {
  showSideBar: boolean;
  setShowSideBar: typeof noop;
  showMenuBar: boolean;
  setShowMenuBar: typeof noop;
  imageVersion: Preview;
  setImageVersion: React.Dispatch<React.SetStateAction<string | null>>;
  imageId: string;
  setImageId: React.Dispatch<React.SetStateAction<string>>;
};

const defaultNavAndSideBarContext: NavAndSideBarContext = {
  showSideBar: false,
  setShowSideBar: noop,
  showMenuBar: false,
  setShowMenuBar: noop,
  imageVersion: '',
  setImageVersion: () => '',
  imageId: '',
  setImageId: () => '',
};

export const NavAndSideBarContext = createContext(defaultNavAndSideBarContext);

export const NavAndSideBarProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(false);
  const [imageVersion, setImageVersion] = useState<string | null>(null);
  const [imageId, setImageId] = useState<string>('');

  const value = {
    showSideBar,
    setShowSideBar,
    showMenuBar,
    setShowMenuBar,
    imageVersion,
    setImageVersion,
    imageId,
    setImageId,
  };

  return (
    <NavAndSideBarContext.Provider value={value}>
      {children}
    </NavAndSideBarContext.Provider>
  );
};

export const useNavAndSideBarContext = () => {
  return useContext(NavAndSideBarContext);
};
