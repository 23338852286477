import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDeleteSlMutation, useGetPlQuery } from '../services/ghgci-sfe';
import { PlEntities, SlEntities } from '../services/types';
import useAuth from 'configs/AuthContext';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { Notification } from './types';

type notificationContextType = {
  handleGetNotifications: () => Notification[] | undefined;
  notificationsListIsLoading: boolean;
  notificationsFetchingError: FetchBaseQueryError | SerializedError | undefined;

  handleDeleteNotification: (customId: string) => void;
  deleteNotificationLoading: boolean;
  deleteNotificationError: FetchBaseQueryError | SerializedError | undefined;
  deleteNotificationIsError: boolean;
  deletingNotificationId: string | null;
  resetDeleteNotificationQueryValues: () => void;
};
const defaultNotificationContext: notificationContextType = {
  handleGetNotifications: () => [], // Provide a default implementation for handleGetNotifications
  notificationsListIsLoading: false,
  notificationsFetchingError: {},

  handleDeleteNotification: () => {}, // Provide a default implementation for handleDeleteNotification
  deleteNotificationLoading: false,
  deleteNotificationError: undefined,
  deleteNotificationIsError: false,
  deletingNotificationId: null,
  resetDeleteNotificationQueryValues: () => {},
};
const DataContext = createContext(defaultNotificationContext);

export const NotificationDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const auth = useAuth();
  const ghgciId = useMemo(() => {
    return (
      auth.user?.getSignInUserSession()?.getIdToken()?.payload[
        'custom:ghgci_id'
      ] || null
    );
  }, [auth.user]);
  const [deletingNotificationId, setDeletingNotificationId] = useState<
    string | null
  >(null);

  const [
    deleteNotification,
    {
      isLoading: deleteNotificationLoading,
      isError: deleteNotificationIsError,
      error: deleteNotificationError,
      reset: resetDeleteNotificationQueryValues,
    },
  ] = useDeleteSlMutation();

  const handleDeleteNotification = async (notificationId: string) => {
    setDeletingNotificationId(notificationId);
    try {
      await deleteNotification({
        typename: PlEntities.User,
        id: ghgciId,
        requestBody: {
          id: notificationId,
          typename: SlEntities.Notification,
        },
      }).unwrap();
      setDeletingNotificationId(null); // Clear the deleting notification ID on success
    } catch (e) {
      setDeletingNotificationId(null); // Clear the deleting notification ID on error
      throw new Error(`Error deleting notification:${e.data}`);
    }
  };

  const {
    isLoading: notificationsListIsLoading,
    error: notificationsFetchingError,
  } = useGetPlQuery({
    typename: PlEntities.User,
    id: ghgciId,
  });

  const handleGetNotifications = () => {
    const { data } = useGetPlQuery({
      typename: PlEntities.User,
      id: ghgciId,
    });
    // Check if data is not undefined before accessing its properties
    if (data) {
      return sortNotificationsByDate(
        findItemsByTypename(data.Items, 'notification'),
      );
    } else {
      return [];
    }
  };

  function sortNotificationsByDate(
    notifications: Notification[],
  ): Notification[] {
    return notifications.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
  }

  return (
    <DataContext.Provider
      value={{
        handleGetNotifications,
        notificationsFetchingError,
        notificationsListIsLoading,

        handleDeleteNotification,
        deleteNotificationLoading,
        deleteNotificationError: deleteNotificationIsError
          ? deleteNotificationError
          : undefined,
        deleteNotificationIsError,
        deletingNotificationId,
        resetDeleteNotificationQueryValues,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useNotificationDataContext = () => useContext(DataContext);
