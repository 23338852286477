import { useMemo } from 'react';
import useAuth from 'configs/AuthContext';

const useHasSystemManagementAccess = (): boolean => {
  const auth = useAuth();

  const userGroups: string[] =
    auth.user?.getSignInUserSession()?.getIdToken().payload['cognito:groups'] ||
    [];

  // Dynamically determine environment based on the host
  const environment = useMemo(() => {
    const host = window.location.host;

    if (host.includes('stage')) return 'stage';
    if (host.includes('test') || host.includes('localhost:8080')) return 'test';
    return 'prod'; // fallback to prod if no match
  }, []);

  // Construct allowed groups based on environment
  const allowedGroups = useMemo(() => {
    return [`admin-${environment}`, `systemmanager-${environment}`];
  }, [environment]);

  // Check if user is in one of the allowed groups
  return useMemo(
    () => userGroups.some((group) => allowedGroups.includes(group)),
    [userGroups, allowedGroups],
  );
};

export default useHasSystemManagementAccess;
