import React, { createContext, ReactNode, useContext } from 'react';
import {
  useCreateAllPlMutation,
  useDeletePlAllMutation,
  useGetPlAllQuery,
  useUpdateSlMutation,
} from '../services/ghgci-sfe';
import { PlEntities } from '../services/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { formatClientData } from 'pages/clients/components/format-client-data';
import { NewClientDataType } from 'pages/clients/api/get-clients';
import { ExtendedPlEntity } from './types';

type ClientContextType = {
  handleGetClients: () => NewClientDataType[];
  clientListIsLoading: boolean;
  clientsFetchingError: FetchBaseQueryError | SerializedError | undefined;

  isUpdateClientSuccess: boolean;
  isUpdateClientLoading: boolean;
  resetUpdateClientQueryValues: () => void;

  handleUpdateClient: (
    clientId: string,
    clientName: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    country: string,
    city: string,
    municipality: string,
    state: string,
    activeDate: string,
    closeDate: string,
    address: string,
    postalCode: string,
    organizationType: string,
  ) => void;

  handleCreateClient: (clientData: NewClientDataType) => void;
  isCreateClientLoading: boolean;
  isCreateClientSuccess: boolean;
  resetCreateClientQueryValues: () => void;

  handleDeleteClient: (noticeId: string) => void;
  deleteClientLoading: boolean;
  deleteClientError: FetchBaseQueryError | SerializedError | undefined;
};

const defaultClientContext: ClientContextType = {
  handleGetClients: () => [], // Provide a default implementation for handleGetClients
  clientListIsLoading: false,
  clientsFetchingError: {},

  isUpdateClientSuccess: false,
  isUpdateClientLoading: false,
  resetUpdateClientQueryValues: () => {},

  handleUpdateClient: () => {},

  handleCreateClient: () => {},
  isCreateClientLoading: false,
  isCreateClientSuccess: false,
  resetCreateClientQueryValues: () => {},

  handleDeleteClient: () => {}, // Provide a default implementation for handleDeleteNotification
  deleteClientLoading: false,
  deleteClientError: undefined,
};
const DataContext = createContext(defaultClientContext);

export const ClientDataProvider = ({ children }: { children: ReactNode }) => {
  const [
    updateClient,
    {
      isLoading: isUpdateClientLoading,
      isSuccess: isUpdateClientSuccess,
      reset: resetUpdateClientQueryValues,
    },
  ] = useUpdateSlMutation();

  //Update Client Info
  const handleUpdateClient = (
    clientId: string,
    clientName: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    country: string,
    city: string,
    municipality: string,
    state: string,
    activeDate: string,
    closeDate: string,
    address: string,
    postalCode: string,
    organizationType: string,
  ) => {
    updateClient({
      typename: PlEntities.Client,
      id: clientId,
      requestBody: {
        typename: PlEntities.Client,
        clientname: clientName,
        firstname: firstName,
        lastname: lastName,
        phone: phone,
        email: email,
        location: `${country}-${state}-${city}-${municipality}`,
        activedate: activeDate,
        closedate: closeDate,
        address: address,
        postalcode: postalCode,
        organizationtype: organizationType,
        id: clientId,
      },
    }).unwrap();
  };

  //This deletes clients
  const [
    deleteClient,
    { isLoading: deleteClientLoading, error: deleteClientError },
  ] = useDeletePlAllMutation();

  const handleDeleteClient = async (clientId: string) => {
    try {
      await deleteClient({
        typename: PlEntities.Client,
        id: clientId,
      }).unwrap();
    } catch (e) {
      console.error('Failed to delete client:', e);
    }
  };

  // This gets all clients
  const { isLoading: clientListIsLoading, error: clientsFetchingError } =
    useGetPlAllQuery({
      typename: PlEntities.Client,
    } as ExtendedPlEntity);

  const [
    createClient,
    {
      isLoading: isCreateClientLoading,
      isSuccess: isCreateClientSuccess,
      reset: resetCreateClientQueryValues,
    },
  ] = useCreateAllPlMutation();

  // This Creates PL clients
  const handleCreateClient = async (clientData: NewClientDataType) => {
    createClient({
      typename: PlEntities.Client,
      requestBody: [
        {
          typename: PlEntities.Client,
          activedate: clientData.activeDate,
          address: clientData.address,
          clientname: clientData.clientName,
          closedate: clientData.closeDate,
          email: clientData.email,
          firstname: clientData.firstName,
          lastname: clientData.lastName,
          location: `${clientData.country}-${clientData.state}-${clientData.city}-${clientData.municipality}`,
          logo: clientData.logo,
          organizationtype: clientData.organizationType,
          phone: clientData.phone,
          postalcode: clientData.postalCode,
        },
      ],
    }).unwrap();
  };

  const handleGetClients = () => {
    const { data } = useGetPlAllQuery({
      typename: PlEntities.Client,
    });

    if (data) {
      // Assuming formatClientData returns the expected array but missing 'typename'
      const formattedData = formatClientData(data.Items || []) as Omit<
        NewClientDataType,
        'typename'
      >[];

      // Add typename as 'client' for each item to match NewClientDataType
      return formattedData.map((item) => ({
        ...item,
        typename: 'client',
      })) as NewClientDataType[];
    }

    return [];
  };

  return (
    <DataContext.Provider
      value={{
        handleGetClients,
        clientListIsLoading,
        clientsFetchingError,
        handleUpdateClient,

        isUpdateClientLoading,
        isUpdateClientSuccess,
        resetUpdateClientQueryValues,

        handleCreateClient,
        isCreateClientLoading,
        isCreateClientSuccess,
        resetCreateClientQueryValues,

        handleDeleteClient,
        deleteClientLoading,
        deleteClientError,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useClientDataContext = () => useContext(DataContext);
