import styled from 'styled-components';
import { breakpoints } from 'utils/breakpoints';
import { colors } from 'utils/colors';
import { defaultTransitionTimeAndStyle, spacingWithPx } from 'utils/styles';

export const MobileMenuContainer = styled.div`
  &#mobile-menu-container {
    display: flex;
    position: fixed;
    transition: transform ${defaultTransitionTimeAndStyle};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px 0 0 4px;
    background: ${colors.white};
    transform: translate(220px, 60px);
    z-index: 1500;
    width: 208px;
    height: fit-content;
    top: 0;
    right: 0;
    &.show {
      transform: translate(0, 60px);
    }
    @media screen and (min-width: ${breakpoints.md}) {
      &.show {
        transform: translate(0, 60px);
      }
    }
  }
`;

export const Flex = styled.div<{ flexDirection?: string; padding?: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'row'};
  padding: ${({ padding }) => padding ?? ''};
`;

export const NavBarList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 4px;
`;

export const NavBarItem = styled.li`
  list-style: none;
  &:hover {
    cursor: pointer;
    background-color: ${colors.sidebarHoverGrey};

    path {
      fill: ${colors.bluePrimary};
    }
    span {
      color: ${colors.bluePrimary};
    }
  }
  padding: ${spacingWithPx.xs} 0;
  > div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;
