import React, { useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';

export function ClientSearchBar({
  onSearch,
}: {
  onSearch: (filteringData: {
    value: string;
    clientFilter: string;
    containsFilter: string;
  }) => void;
}) {
  const [value, setValue] = useState('');
  const [searchType, setSearchType] = useState('search');
  const [clientFilter, setClientFilter] = useState('Name'); // Default filter
  const [containsFilter, setContainsFilter] = useState('Contains'); // Default contains filter
  const [countryFilter, setCountryFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [municipalityFilter, setMunicipalityFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');

  const handleSearchClick = () => {
    onSearch({
      value,
      clientFilter,
      containsFilter,
    });
  };

  const handleSearchTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string,
  ) => {
    if (newType) setSearchType(newType);
  };

  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" gap={2} sx={{ mt: 7 }}>
      <Grid
        container
        paddingBottom={5}
        paddingTop={{ xs: 5, md: 0 }}
        width={'100%'}
      >
        <Grid size={12} paddingBottom={5}>
          <Card sx={{ width: 'fit-content' }}>
            <ToggleButtonGroup
              value={searchType}
              exclusive
              onChange={handleSearchTypeChange}
              sx={{
                '.Mui-selected': {
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  fontVariant: 'button',
                  color: `${theme.palette.common.white} !important`,
                },
                '.MuiToggleButton-root:not(.Mui-selected)': {
                  backgroundColor: `${theme.palette.common.white} !important`,
                  fontVariant: 'button !important',
                  color: `${theme.palette.primary.main} !important`,
                  border: `1px solid ${theme.palette.primary.main} !important`,
                },
              }}
            >
              <ToggleButton value="search">Search</ToggleButton>
              <ToggleButton value="location">Location Search</ToggleButton>
            </ToggleButtonGroup>
          </Card>
        </Grid>

        {/* SEARCH MODE */}
        {searchType === 'search' && (
          <>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={5}
              size={{ xs: 12 }}
              sx={{ alignItems: 'center' }}
            >
              <Grid container size={{ xs: 12, lg: 8 }}>
                <Grid size={{ xs: 12, md: 4, lg: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="client-filter-label">Client</InputLabel>
                    <Select
                      labelId="client-filter-label"
                      value={clientFilter}
                      onChange={(e: SelectChangeEvent) =>
                        setClientFilter(e.target.value)
                      }
                      label="Client"
                    >
                      <MenuItem value="Name">Client Name</MenuItem>
                      <MenuItem value="Email">Email</MenuItem>
                      <MenuItem value="Phone">Phone</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid size={{ xs: 12, md: 4, lg: 6 }}>
                  <FormControl fullWidth>
                    <InputLabel id="contains-filter-label">Contains</InputLabel>
                    <Select
                      labelId="contains-filter-label"
                      value={containsFilter}
                      onChange={(e: SelectChangeEvent) =>
                        setContainsFilter(e.target.value)
                      }
                      label="Contains"
                    >
                      <MenuItem value="Start with">Start with</MenuItem>
                      <MenuItem value="Contains">Contains</MenuItem>
                      <MenuItem value="Ends with">Ends with</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Search Input */}
              <Grid size={{ xs: 12, md: 7, lg: 4 }} width={'100%'}>
                <TextField
                  value={value}
                  placeholder="Name, email, etc..."
                  label="Search"
                  onChange={(e) => setValue(e.target.value)}
                  fullWidth
                  size={'small'}
                  sx={{
                    fontVariant: 'body1',
                    '.MuiFormLabel-root': { top: '3px' }, //This is a ui fix to a bug of elements label and placeholder being there at the same time.
                  }}
                />
              </Grid>

              {/* Search Button */}
              <Grid size={{ xs: 12, md: 3, lg: 2 }}>
                <Button variant="contained" onClick={handleSearchClick}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {searchType === 'location' && (
          <>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={5}
              size={{ xs: 12 }}
              sx={{ alignItems: 'center' }}
            >
              <Grid container size={{ xs: 12, lg: 8.5, xl: 7.5 }}>
                {/* Country Dropdown */}
                <Grid size={{ xs: 12, md: 3.5, lg: 4 }}>
                  <FormControl fullWidth>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                      labelId="country-label"
                      value={countryFilter}
                      onChange={(e: SelectChangeEvent) =>
                        setCountryFilter(e.target.value)
                      }
                      label="Country"
                    >
                      <MenuItem value="Canada">Canada</MenuItem>
                      <MenuItem value="Germany">Germany</MenuItem>
                      <MenuItem value="France">France</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* State Dropdown */}
                <Grid size={{ xs: 12, md: 3.5, lg: 4 }}>
                  <FormControl fullWidth>
                    <InputLabel id="state-label">State/Province</InputLabel>
                    <Select
                      labelId="state-label"
                      value={stateFilter}
                      onChange={(e: SelectChangeEvent) =>
                        setStateFilter(e.target.value)
                      }
                      label="State/Province"
                    >
                      <MenuItem value="British Columbia">
                        British Columbia
                      </MenuItem>
                      <MenuItem value="Ontario">Ontario</MenuItem>
                      <MenuItem value="Alberta">Alberta</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Municipality Dropdown */}
                <Grid size={{ xs: 12, md: 3.5, lg: 4 }}>
                  <FormControl fullWidth>
                    <InputLabel id="municipality-label">
                      Municipality
                    </InputLabel>
                    <Select
                      labelId="municipality-label"
                      value={municipalityFilter}
                      onChange={(e: SelectChangeEvent) =>
                        setMunicipalityFilter(e.target.value)
                      }
                      label="Municipality"
                    >
                      <MenuItem value="Metro Vancouver">
                        Metro Vancouver
                      </MenuItem>
                      <MenuItem value="West Van">West Van</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* City Dropdown */}
              <Grid size={{ xs: 12, md: 3.5, lg: 3, xl: 2.5 }}>
                <FormControl fullWidth>
                  <InputLabel id="city-label">City</InputLabel>
                  <Select
                    labelId="city-label"
                    value={cityFilter}
                    onChange={(e: SelectChangeEvent) =>
                      setCityFilter(e.target.value)
                    }
                    label="City"
                  >
                    <MenuItem value="Vancouver">Vancouver</MenuItem>
                    <MenuItem value="Burnaby">Burnaby</MenuItem>
                    <MenuItem value="Surrey">Surrey</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Search Button */}
              <Grid size={{ xs: 12, md: 4, xl: 1 }} sx={{ mt: 1 }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    alert(
                      'This function will be available after the lookup library is connected',
                    )
                  }
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
