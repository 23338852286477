import { DisplayTable } from '../../clients/components/clients-table';
import { ConsultantContainer } from '../../clients/components/clients-table/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { DataVisibilityType } from 'pages/clients/api/get-clients';
import { TableContainer } from 'pages/task-manager/components/styled';
import { ProfileImage } from 'pages/clients/components/clients-table/profile-image';
import { colors } from 'utils/colors';
import {
  FullContainer,
  ProjectHeaderContainer,
  StatusContainer,
} from 'pages/projects/components/styled';
import ProjectTableSubComponent from 'pages/projects/components/project-table-sub-component';
import { MD } from 'utils/breakpoints';
import { getWindowDimensions } from 'utils/window-utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Calendar } from 'icons';
import {
  NewProjectStep,
  UpdateProjectPlRequest,
  User,
} from '../../../react-query-toolkit/state/types';
import { useProjectsDataContext } from '../../../react-query-toolkit/state/projects-context';
import { useParams } from 'react-router-dom';
import { getUpdatedProjectSteps } from 'utils/get-project-current-step';
import { useUserDataContext } from '../../../react-query-toolkit/state/user-context';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslateAll } from 'locales/translation-hooks';
import { useTranslation } from 'react-i18next';
import {
  getTranslationKeyMapping,
  projectHeadersTranslationMappings,
  ProjectStepsTranslationKeys,
  projectStepsTranslationMappings,
} from 'locales/mappings';
import { DateTime } from 'luxon';

type ProjectTableProps = {
  setCommentsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStepComment: React.Dispatch<React.SetStateAction<string>>;
};

type UserInfo = {
  firstname: string;
  lastname: string;
  email: string;
} | null;

export const findUserInfoById = (id: string, users: User[]): UserInfo => {
  if (!Array.isArray(users)) {
    console.warn('Expected an array, but got:', users);
    return null;
  }

  const user = users.find((user) => user.id === id);

  if (!user) {
    console.warn(`User with ID ${id} not found.`);
  }

  return user
    ? {
        firstname: user.firstname || 'Unknown',
        lastname: user.lastname || 'User',
        email: user.email || 'No Email',
      }
    : null;
};

export const ProjectTable = (props: ProjectTableProps) => {
  const { setCommentsOpen, setStepComment } = props;
  const { translateCommon, translateProjects } = useTranslateAll([
    'common',
    'projects',
  ]);

  const {
    handleGetProject,
    isGetProjectsLoading,
    handleUpdateProjectPl,
    resetUpdateProjectQueryValues,
    isGetProjectsFetching,
  } = useProjectsDataContext();
  const { handleGetAllUsers, isGetUserLoading } = useUserDataContext();
  const rawUserData = handleGetAllUsers() || [];
  const { projectId } = useParams<{ projectId: string }>();

  const rawProjectData = handleGetProject() || {};

  const projectStepsData: NewProjectStep[] = rawProjectData.projectSteps || [];

  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  const [isTableWidth, setIsTableWidth] = useState<boolean>(
    windowSize.width > MD,
  );
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      assignees: isTableWidth,
      dueDate: isTableWidth,
    });

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
    resetUpdateProjectQueryValues(); // Reset error state after closing dialog
  };

  // Window resize handling
  const handleResize = () => {
    setWindowSize(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsTableWidth(windowSize.width >= MD);
    setColumnsVisibility({
      projectAssignees: isTableWidth,
      dueDate: isTableWidth,
    });
  }, [windowSize.width]);

  const stepOrder = [
    'set-up',
    'data-collection',
    'research',
    'calculation',
    'calculation-review',
    'draft-report',
    'report-review',
    'client-review',
    'finalize-report',
    'close-project',
  ];

  // Function to get the `ps#(number)` based on the step name
  function getTheSk_id(stepName: string): string {
    const stepIndex = stepOrder.indexOf(stepName);
    if (stepIndex !== -1) {
      return `${stepIndex + 1}`;
    }
    return 'Step not found';
  }

  // Optimistic updates state
  const [optimisticUpdates, setOptimisticUpdates] = useState<
    Record<string, string>
  >({});

  // Derived state using useMemo to prevent unnecessary renders
  const [localDisplayedData, setLocalDisplayedData] = useState<
    NewProjectStep[]
  >([]);

  useEffect(() => {
    setLocalDisplayedData((prevData) => {
      const updatedData = projectStepsData.map((step) => ({
        ...step,
        status: optimisticUpdates[step.name] ?? step.status, // Ensure UI shows optimistic value
      }));

      // Prevent unnecessary updates
      return JSON.stringify(prevData) !== JSON.stringify(updatedData)
        ? updatedData
        : prevData;
    });
  }, [projectStepsData, optimisticUpdates]);

  // Function to handle optimistic UI update
  const handleStatusChange = async (
    newStatus: string,
    stepName: string,
    stepSkId: string,
    dueDate: string,
    typename: 'projectstep',
  ) => {
    // Set optimistic update before making the request
    setOptimisticUpdates((prev) => ({ ...prev, [stepName]: newStatus }));

    try {
      const requestBody: UpdateProjectPlRequest[] = [
        {
          id: projectId!,
          typename: 'project',
          status: getUpdatedProjectSteps(
            stepName,
            newStatus,
            projectStepsData,
            'status',
          ),
          step: getUpdatedProjectSteps(
            stepName,
            newStatus,
            projectStepsData,
            'step',
          ),
          projectCurrentStep: getUpdatedProjectSteps(
            stepName,
            newStatus,
            projectStepsData,
            'step',
          ),
        },
        {
          typename: 'projectstep',
          id: stepSkId,
          name: stepName,
          status: newStatus,
          duedate: dueDate,
        },
      ];

      await handleUpdateProjectPl({ requestBody });

      // Instead of clearing immediately, wait for RTK to update
      setTimeout(() => {
        setOptimisticUpdates((prev) => ({ ...prev, [stepName]: newStatus }));
      }, 500);
    } catch (error) {
      // Rollback optimistic update on failure
      setOptimisticUpdates((prev) => {
        const updatedCache = { ...prev };
        delete updatedCache[stepName];
        return updatedCache;
      });

      console.error(error);
      setErrorDialogOpen(true);
    }
  };

  useEffect(() => {
    if (!isGetProjectsFetching) {
      setOptimisticUpdates((prev) => {
        let updatedCache = { ...prev };
        let hasChanges = false;

        projectStepsData.forEach((step) => {
          if (updatedCache[step.name] === step.status) {
            delete updatedCache[step.name]; //  Remove only confirmed updates
            hasChanges = true;
          }
        });

        return hasChanges ? updatedCache : prev; //  Only update if necessary
      });
    }
  }, [isGetProjectsFetching, projectStepsData]);

  const { i18n } = useTranslation();

  const statuses = [
    {
      value: 'not-started',
      label: translateProjects('statuses.projectSteps.notStarted'),
      color: 'secondary.dark',
    },
    {
      value: 'on-hold',
      label: translateProjects('statuses.projectSteps.onHold'),
      color: 'error.main',
    },
    {
      value: 'in-progress',
      label: translateProjects('statuses.projectSteps.inProgress'),
      color: 'primary.dark',
    },
    {
      value: 'done',
      label: translateProjects('statuses.projectSteps.done'),
      color: 'success.main',
    },
  ];

  const columns: ColumnDef<NewProjectStep>[] = useMemo(
    () => [
      {
        header: () => (
          <ProjectHeaderContainer>
            {translateCommon('headers.headerProjectStatus')}
          </ProjectHeaderContainer>
        ),
        accessorKey: 'expand',
        enableSorting: false,
        cell: ({ row }) => {
          const arrowColor = useMemo(() => {
            return (
              statuses.find((s) => s.value === row.original.status)?.color ||
              'inherit'
            );
          }, [row.original.status]);

          return (
            <FullContainer
              onKeyDown={row.getToggleExpandedHandler()}
              onClick={row.getToggleExpandedHandler()}
            >
              <StatusContainer>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    fullWidth
                    IconComponent={() => (
                      <ArrowDropDownIcon sx={{ color: arrowColor }} />
                    )}
                    value={row.original.status.toLowerCase()} // Convert to lowercase
                    onChange={async (e) => {
                      const newStatus = e.target.value.toLowerCase();
                      const stepName = row.original.name;
                      const stepSkId = getTheSk_id(stepName);
                      handleStatusChange(
                        newStatus,
                        stepName,
                        stepSkId,
                        row.original.duedate,
                        row.original.typename,
                      );
                    }}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '.MuiSelect-select': { paddingRight: '0 !important' }, // Workaround because of the mui select is not able to customize dynamic arrows
                      '& .css-15ouuql-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select .MuiTypography-root':
                        {
                          fontSize: '12px',
                          fontWeight: '400',
                          lineHeight: '143%',
                          letterSpacing: '0.17px', // Match body3 To override select
                        },
                    }}
                  >
                    {statuses.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        <Typography color={status.color} variant="body3">
                          {status.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StatusContainer>
            </FullContainer>
          );
        },
      },
      {
        header: `${translateCommon('headers.headerProjectSteps')}`,
        accessorKey: 'projectStep',
        enableSorting: false,
        cell: ({ row }) => (
          <div
            onKeyDown={row.getToggleExpandedHandler()}
            onClick={row.getToggleExpandedHandler()}
          >
            {translateProjects(
              getTranslationKeyMapping(
                row.original.name as keyof ProjectStepsTranslationKeys,
                projectStepsTranslationMappings,
              ),
            )}
          </div>
        ),
      },
      {
        header: `${translateCommon('headers.headerProjectAssignees')}`,
        accessorKey: 'projectAssignees',
        enableSorting: false,
        cell: ({ row }) => (
          <ConsultantContainer onClick={row.getToggleExpandedHandler()}>
            {row.original.assignees.map((assigneeId: string, idx: number) => {
              const userInfo = findUserInfoById(assigneeId, rawUserData);

              if (!userInfo) {
                return null; // Skip rendering if no user information is found
              }

              return (
                <ProfileImage
                  key={idx}
                  textToDisplayWhenClicked={translateCommon(
                    'messages.EmailCopiedMessage',
                  )}
                  moveValue={`${idx}0px`}
                  profileName={userInfo.firstname}
                  profileEmail={userInfo.email}
                  imageIdx={`${idx + 1}`}
                />
              );
            })}
          </ConsultantContainer>
        ),
      },
      {
        header: `${translateCommon('headers.headerDueDate')}`,
        accessorKey: 'dueDate',
        enableSorting: false,
        cell: ({ row }) => (
          <div
            tabIndex={0}
            role="button"
            onClick={row.getToggleExpandedHandler()}
            onKeyDown={row.getToggleExpandedHandler()}
          >
            <Calendar
              width={14}
              height={14}
              color={colors.lighterSecondaryBlue}
            />{' '}
            {DateTime.fromISO(row.original.duedate)
              .setLocale(i18n.language)
              .toLocaleString(DateTime.DATE_MED)}
          </div>
        ),
      },
      {
        header: `${translateCommon('headers.headerProjectComments')}`,
        accessorKey: 'projectComments',
        enableSorting: false,
        size: 50,
        cell: ({ row }) => (
          <Box
            justifyContent="start"
            display="flex"
            pr={5}
            onKeyDown={row.getToggleExpandedHandler()}
            onClick={row.getToggleExpandedHandler()}
          >
            <IconButton
              onClick={() => {
                setCommentsOpen(true);
                setStepComment(row.original.name);
              }}
            >
              <Badge
                color={'primary'}
                badgeContent={'123'}
                style={{ transform: 'translate(30px, -10px)' }}
                sx={{
                  transform: 'translate(0px, 0px)',
                  '& .MuiBadge-badge': {
                    fontSize: 10,
                    height: 15,
                    minWidth: 15,
                  },
                }}
              ></Badge>
              <MessageOutlinedIcon color={'secondary'} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [setCommentsOpen, translateCommon],
  );

  const tableKey = useMemo(() => Math.random(), [localDisplayedData]);

  return (
    <TableContainer maxHeight="auto">
      {/* Loading State */}
      {isGetProjectsLoading || isGetUserLoading ? (
        <Box
          display={'flex'}
          width={'100%'}
          height={'30%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <DisplayTable
          key={tableKey}
          // @ts-ignore
          data={localDisplayedData}
          columns={columns}
          getRowCanExpand={() => true}
          columnsVisibility={columnsVisibility}
          showFooter={false}
          RenderSubComponent={ProjectTableSubComponent}
          loadingItem={translateCommon('messages.loading')}
          translationKeysMapping={projectHeadersTranslationMappings}
          hideSubComponentFromSizeSm
        />
      )}

      {/* Error Dialog */}
      <Dialog open={errorDialogOpen} onClose={handleErrorDialogClose}>
        <DialogTitle>
          {translateCommon('errors.errorTitles.errorUpdatingTitle')}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {translateCommon('errors.errorMessages.errorUpdatingMessage')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose} variant="contained">
            {translateCommon('buttons.closeButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};
