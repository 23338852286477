{
    "description":{
        "default": "Notification",
        "receptionDate": "Received: {{createdAt}}",
        "changedProjectStepDueDate": "{{name}} step's due date was changed",
        "changedProjectDueDate": "Project due date was changed to {{duedate}}",
        "createdNewTask": "A new task \"{{name}}\" was created",
        "changedProjectStepStatus": "Status for \"{{name}}\" step was changed to {{status}}",
        "noDetailsAvailable": "No additional details available."
    },
    "feedBack":{
        "alertMessages":{
            "errors":{
                "deleteNotification": "Error deleting notification.",
                "deleteAllNotifications": "Error deleting all notifications."
            }
        }
    },
    "titles": {
        "systemNotices": "System Notices",
        "openProject": "Open Project",
        "reportReview": "Report Review",
        "tasks":{
            "create": "A new task \"{{name}}\" was created",
            "overdue": "Task \"{{name}}\" is overdue",
            "dueToday": "Task \"{{name}}\" is due today",
            "dueTomorrow": "Task \"{{name}}\" is due tomorrow"
        },
        "default": "Notification",
        "noInfoAvailable":"No title available"
    }
}
