export function getLocationLabel(location: string) {
  if (location.startsWith('/dashboard') || location === '/') return 'Dashboard';
  if (location.startsWith('/my-clients')) return 'My Clients';
  if (location.startsWith('/my-projects')) return 'My Projects';
  if (location.startsWith('/calendar')) return 'Calendar';
  if (location.startsWith('/notifications')) return 'Notifications';
  if (location.startsWith('/task-manager')) return 'Task Manager';
  if (location.startsWith('/system-management')) return 'System Management';
  if (location.startsWith('/help')) return 'Help';

  return 'Unknown Location';
}
