{
  "dataTable": "Table de données",
  "seeAll": "Tout voir",
  "expandAll": "Tout développer",
  "collapseAll": "Tout réduire",
  "more": "Plus",
  "uploading": "Téléversement en cours",
  "buttons": {
    "addButton": "AJOUTER",
    "backButton": "RETOUR",
    "cancelButton": "ANNULER",
    "closeButton": "FERMER",
    "confirmButton": "CONFIRMER",
    "confirmationButton": "CONTINUER",
    "submitButton": "SOUMETTRE",
    "deleteButton": "SUPPRIMER",
    "editButton": "MODIFIER",
    "saveButton": "SAUVEGARDER",
    "nextButton": "SUIVANT",
    "okButton": "OK",
    "uploadButton": "TÉLÉVERSER",
    "reviewButton": "REVISER"
  },
  "dueDate": {
    "dueDateTomorrow": "Demain",
    "dueDateYesterday": "Hier",
    "dueDateToday": "Aujourd'hui",
    "dueDateOverdue": "En retard",
    "dueDateIn": "Dans {{numberOfWeeks}} semaines"
  },
  "errors": {
    "errorButtons": {
      "errorCloseButton": "Fermer"
    },
    "errorTitles": {
      "errorTitle": "Erreur",
      "errorUpdatingTitle": "Erreur de mise à jour"
    },
    "errorMessages": {
      "errorDefaultMessage": "Une erreur est survenue.",
      "errorUpdatingMessage": "Nous n'avons pas pu mettre à jour à ce moment."
    }
  },
  "feedBack": {
    "alerts": {
      "success": "Succès",
      "error": "Erreur",
      "warning": "Attention",
      "info": "Info"
    }
  },
  "greetings": {
    "welcomeGreeting": "Bonjour, le monde !",
    "navBarGreeting": "Bonjour"
  },
  "headers": {
    "headerCalendar": "Calendrier",
    "headerCity": "Ville",
    "headerClient": "Clients",
    "headerUserProfile": "Profil Utilisateur",
    "headerConsultant": "Consultant",
    "headerDashboard": "Tableau de bord",
    "headerDueDate": "Date d'échéance",
    "headerDate": "",
    "headerEdit": "Modifier",
    "headerEmail": "E-mail",
    "headerHelpButton": "AIDE",
    "headerLocation": "Localisation",
    "headerLogin": "Se connecter",
    "headerName": "Nom",
    "headerNotifications": "Notifications",
    "headerPhone": "Téléphone",
    "headerPriority": "Priorité",
    "headerProjectAssignees": "Assignés",
    "headerProjectComments": "Commentaires",
    "headerProjectDueDate": "Date d'échéance",
    "headerProjectStatus": "Statut",
    "headerProjectSteps": "Étapes",
    "headerProjects": "Projets",
    "headerMyProjects": "Mes Projets",
    "headerSystemManagement": "",
    "headerSingleProject": "Projet",
    "headerSettings": "Profil Utilisateur",
    "headerTask": "Tâche",
    "headerTaskManager": "Gestionnaire de tâches",
    "headerClientRequests": "Demandes client",
    "headerScheduler": "Planificateur",
    "headerCustomProjects": "Projets personnalisés",
    "headerSofConect": "SofConect"
  },
  "labels": {
    "applicableStandard": "",
    "intendedUse": "",
    "successCriteria": ""
  },
  "messages": {
    "message": "Bienvenue sur GHG CI",
    "noDataMessage": "Aucune donnée",
    "noResultsMessage": "Aucun résultat",
    "confirmationMessage": "Your...has been confirmed/succesffully...",
    "textCopiedMessage": "Texte copié !",
    "EmailCopiedMessage": "Email copié !",
    "thankYouDisplayMessage": "Nous avons bien reçu votre inscription. Nous allons examiner vos informations et vous enverrons une confirmation par email sous peu."
  },
  "questions": {
    "typeOfOrganizationQuestion": "Quel est le type de votre organisation ?",
    "sizeOfOrganizationQuestion": "Quelle est la taille de votre organisation ?"
  },
  "titles": {
    "congratulations": "Félicitations !",
    "thankYouDisplayTitle": "Merci pour votre inscription !"
  },
  "unknowns": {
    "unknownClient": "Client inconnu",
    "unknownManager": "Manager inconnu",
    "unknownDueDate": "Date d'échéance inconnue",
    "unknownUser": "Utilisateur inconnu"
  },
  "breadcrumbs": {
    "systemManagement": "",
    "totalClients": "",
    "systemNotices": ""
  }
}
