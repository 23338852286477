import { colors } from 'utils/colors';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useIsWindowWidthMobile } from 'utils/hooks/useIsWindowWidthMobile';
import { DataVisibilityType } from 'pages/clients/api/get-clients';
import {
  CardHeaderContainer,
  SeeAllContainer,
  TableContainer,
  TodayTaskContainer,
} from 'pages/task-manager/components/styled';
import { DisplayTable } from 'pages/clients/components/clients-table';
import TaskTableSubComponent from 'pages/task-manager/components/task-table-sub-component';
import { useNavigate } from 'react-router-dom';
import { useIsWindowWidthDesktop } from 'utils/hooks/useIsWindowWidthDesktop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslateAll } from 'locales/translation-hooks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

//TODO - this is a temporal solution for the type - replace with actual
export type NewClientRequestsType = {
  id: string;
  name: string;
  date: string;
};
export const SofConect = () => {
  const clientRequestError = false;
  const clientRequestIsLoading = false;

  const clientRequestsData = [
    { id: '123', name: 'tod -data integration hub', date: '' },
    { id: '1223', name: 'tod -data integration hub', date: '' },
    { id: '1233', name: 'tod -data integration hub', date: '' },
    { id: '1423', name: 'tod -data integration hub', date: '' },
  ];

  const { isMobileWidth, windowSizeWidth } = useIsWindowWidthMobile();
  const { isDesktopWidth } = useIsWindowWidthDesktop();

  const { translateCommon, translateTasks } = useTranslateAll([
    'tasks',
    'common',
  ]);

  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      dueDate: !isMobileWidth,
    });

  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const columns: ColumnDef<NewClientRequestsType>[] = [
    {
      header: ``,
      accessorKey: 'name',
      enableSorting: false,
      size: undefined,
      minSize: 1,
      maxSize: 1,
      cell: ({ row }) => (
        <>
          <Box display={'flex'}>
            {/*<Tooltip title={row.original.taskName}>*/}
            <Tooltip title={row.original.name}>
              <Box
                pl={5}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: { xs: '100%' },
                  maxWidth: { xs: '100%', md: '180px', xl: '170px' },
                }}
              >
                <Typography variant={'body3'} p={0} m={0} color={'red'}>
                  {row.original.name}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </>
      ),
    },
    {
      header: ``,
      accessorKey: 'reviewButton',
      minSize: 50,
      size: undefined,
      cell: ({ row }: { row: Row<NewClientRequestsType> }) => (
        <Box alignItems={'center'} justifyContent={'end'} display={'flex'}>
          <Button
            variant={'outlined'}
            onClick={() => navigate('/client-requests')}
            sx={{
              px: 4,
              py: 2,
              mx: 5,
              textTransform: 'none',
              borderColor: 'primary.dark',
            }}
          >
            <Typography variant={'body3'} color={'primary.dark'}>
              PlaceHolder
            </Typography>
          </Button>
        </Box>
      ),
      enableSorting: false,
    },
  ];

  useEffect(() => {
    setColumnsVisibility({
      dueDate: !isMobileWidth,
    });
    return () => {};
  }, [windowSizeWidth]);

  const displayTableNoItemsMessage = translateTasks(
    'displayTaskTable.displayTableNoItemsMessage',
  );

  const theme = useTheme();
  return (
    <>
      <TodayTaskContainer hidePadding background={colors.white}>
        <CardHeaderContainer>
          <Typography
            variant={'subtitle2'}
            color={'primary.dark'}
            onClick={() => alert('to Sofconect')}
            sx={{
              paddingY: 2,
              cursor: 'pointer',
            }}
          >
            {translateCommon('headers.headerSofConect')}
          </Typography>
        </CardHeaderContainer>
        <TableContainer maxHeight={'100%'}>
          <DisplayTable
            data={clientRequestsData!}
            columns={columns}
            getRowCanExpand={() => true}
            columnsVisibility={columnsVisibility}
            showFooter={false}
            cellMaxWidth={'200'}
            RenderSubComponent={TaskTableSubComponent}
            loadingItem={
              clientRequestIsLoading ? (
                <Box
                  display={'flex'}
                  width={'100%'}
                  height={'30%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <CircularProgress size={30} />
                </Box>
              ) : clientRequestError ? (
                displayTableNoItemsMessage
              ) : undefined
            }
            hideHeader
          />
        </TableContainer>
        <SeeAllContainer onClick={() => navigate('/task-manager')}>
          <Box
            height={theme.spacing(9)}
            mx={6}
            alignItems={'center'}
            display={'flex'}
          >
            <Typography variant={'link'} color={'primary.dark'}>
              {translateCommon('seeAll')}
            </Typography>
          </Box>
        </SeeAllContainer>
      </TodayTaskContainer>
    </>
  );
};
