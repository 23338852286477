{
  "clientAccount": {
    "title": "",
    "placeHolders": {
      "address": "",
      "city": "",
      "client": "",
      "contact": "",
      "country": "",
      "email": "",
      "phone": "",
      "region": "",
      "state": ""
    },
    "labels": {
      "city": "",
      "client": "",
      "contact": "",
      "email": "",
      "phone": "",
      "state": "",
      "address": ""
    },
    "errors": {
      "contactNotEmpty": "",
      "contactRequired": "",
      "emailInvalid": "",
      "emailRequired": "",
      "nameNotEmpty": "",
      "nameRequired": "",
      "phoneInvalid": "",
      "phoneRequired": ""
    }
  },
  "totalClients": {
    "clientCreator": {
      "newClientTitle": "",
      "newClientSubTitle": ""
    },
    "allClients": {
      "allClientsTitle": "",
      "confirmDelete": "",
      "cancelDelete": "",
      "deleteTitle": ""
    },
    "errors": {
      "deleteClientError": ""
    }
  },
  "editClient": {
    "title": "",
    "labels": {
      "client": "",
      "firstName": "",
      "lastName": "",
      "phone": "",
      "email": "",
      "closeDate": "",
      "activeDate": "",
      "country": "",
      "stateProvince": "",
      "municipality": "",
      "city": "",
      "streetAddress": "",
      "postalCode": "",
      "organizationType": "",
      "addProjects": "",
      "addConsultants": "",
      "clientPool": ""
    },
    "options": {
      "government": "",
      "company": "",
      "industry": "",
      "nonProfit": "",
      "educationalInstitution": "",
      "communityGroup": "",
      "others": ""
    },
    "buttons": {
      "cancel": "",
      "save": "",
      "create": ""
    }
  }
}
