type ProjectStep = {
  sk_id: string;
  name: string;
  duedate: string;
  status: string;
  assignees: string[];
};

export function getUpdatedProjectSteps(
  projectStepUpdated: string,
  projectStepNewStatus: string,
  projectStepsData: ProjectStep[],
  logType: 'step' | 'status',
): string {
  // Create a new array with the updated step status
  // Store updated steps in a constant
  const updatedProjectSteps = projectStepsData.map((step) =>
    step.name === projectStepUpdated
      ? { ...step, status: projectStepNewStatus }
      : step,
  );

  // Find the first step that is NOT "done"
  const firstNotDoneStep = updatedProjectSteps.find(
    (step) => step.status !== 'done',
  );

  // Store first step that is not done in a constant
  const nextProjectStep = firstNotDoneStep
    ? { name: firstNotDoneStep.name, status: firstNotDoneStep.status }
    : { name: 'close-project', status: 'done' };

  // Log based on the `logType` parameter
  if (logType === 'step') {
    return nextProjectStep.name;
  } else return nextProjectStep.status;
}
