import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ProjectsPageFilter } from './types';
import { useUserDataContext } from '../../react-query-toolkit/state/user-context';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { Project } from '../../react-query-toolkit/state/types';
import { ProjectCard } from 'pages/projects/project-card';
import { ProjectsFilter } from 'pages/projects/project-filter';
import { PageWrapper } from 'components';
import { PageTitle } from 'components/atoms/page-title/page-title';
import Grid from '@mui/material/Grid2';
import { SearchBar } from 'pages/projects/components/search-bar';
import CircularProgress from '@mui/material/CircularProgress';
import { useClientDataContext } from '../../react-query-toolkit/state/client-context';
import { useTheme } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useParams } from 'react-router-dom';
import { useTranslateAll } from 'locales/translation-hooks';
import {
  ProjectStepsTranslationKeys,
  projectStepsTranslationMappings,
} from 'locales/mappings';

export const Projects = () => {
  const [filter, setFilter] = useState<ProjectsPageFilter>('ALL');
  const [searchQuery, setSearchQuery] = useState<string>(''); // Search Query

  const { clientName: urlClientName, tabSelected: urlTabSelected } = useParams<{
    clientName: string;
    tabSelected: string;
  }>();
  const handleFilterClick = (filterName: ProjectsPageFilter) =>
    setFilter(filterName);

  const { translateCommon, translateProjects } = useTranslateAll([
    'common',
    'projects',
  ]);
  const { handleGetUser, isGetUserLoading, handleGetAllUsers } =
    useUserDataContext();
  const { handleGetClients, clientListIsLoading } = useClientDataContext();

  const rawUserData = handleGetUser();
  const rawAllUsersData = handleGetAllUsers();
  const rawClientData = handleGetClients();

  type ClientData = {
    clientId: string;
    clientName: string;
    // other fields omitted for brevity
  };

  const getClientNameById = (
    clientId: string,
    clients: ClientData[],
  ): string => {
    // Find the client that matches the provided clientId
    const client = clients.find((client) => client.clientId === clientId);

    // Return the client name if found, otherwise return 'Unknown Client'
    return client
      ? client.clientName
      : `${translateCommon('unknowns.unknownClient')}`;
  };
  type User = {
    id: string;
    typename: string;
    firstname?: string;
    lastname?: string;
  };

  const getUserNameById = (userId: string, users: User[]): string => {
    // Find the user with the given ID and typename 'user'
    const user = users.find(
      (user) => user.id === userId && user.typename === 'user',
    );

    // Return the full name if the user exists, otherwise return 'Unknown User'
    return user
      ? `${user.firstname} ${user.lastname}`
      : `${translateCommon('unknowns.unknownUser')}`;
  };

  // // Memoize userProjects to prevent re-render issues
  // const currentYear = new Date().getFullYear().toString(); // TODO - Change this to uncomment and delete the below 2024!
  const currentYear = '2024';

  //Reverted this part to the old logic. projects period are not found in the id anymore
  const userProjects: Project[] = useMemo(() => {
    return Array.isArray(rawUserData)
      ? (findItemsByTypename(rawUserData, 'ref:project') as Project[]).filter(
          (project) => project.period === currentYear, // Use period instead of extracting from id
        )
      : [];
  }, [rawUserData, currentYear]);

  useEffect(() => {
    urlClientName ? setSearchQuery(urlClientName) : null;
    urlTabSelected ? setFilter(urlTabSelected) : null;
  }, [urlClientName]);

  // Filter Projects Based on Search Query
  const filteredProjects = useMemo(() => {
    const query = searchQuery.toLowerCase();

    let filtered = userProjects;

    // Apply tab filters
    switch (filter) {
      case 'STARTED':
        filtered = userProjects.filter(
          (project) =>
            !(project.step === 'set-up' && project.status === 'not-started'),
        );
        break;
      case 'REVIEW':
        filtered = userProjects.filter(
          (project) => project.step === 'report-review',
        );
        break;
      case 'COMPLETED':
        filtered = userProjects.filter(
          (project) =>
            project.step === 'close-project' && project.status === 'done',
        );
        break;
      default:
        break; // ALL tab shows all projects
    }

    // Apply search filter
    return filtered.filter((project) => {
      return (
        project.id.toLowerCase().includes(query) ||
        project.projecttype.toLowerCase().includes(query) ||
        getClientNameById(project.client, rawClientData)
          .toLowerCase()
          .includes(query) ||
        getUserNameById(project.accountmanager, rawAllUsersData)
          .toLowerCase()
          .includes(query) ||
        project.duedate.toLowerCase().includes(query) ||
        project.status.toLowerCase().includes(query) ||
        project.step.toLowerCase().includes(query)
      );
    });
  }, [searchQuery, userProjects, filter]); // Added 'filter' dependency

  const clearSearch = () => setSearchQuery('');

  const theme = useTheme();

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 9; // Limit to 9 cards per page
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject,
  );

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredProjects.length / projectsPerPage)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <PageWrapper>
      <Box justifyItems={'center'} sx={{ width: '100%', cursor: 'pointer' }}>
        <Box width={'100%'} maxWidth={theme.breakpoints.values.xxl}>
          <Grid container>
            <Grid size={{ xs: 12, xl: 2.5 }}>
              <PageTitle
                title={translateCommon?.('headers.headerMyProjects')}
              />
            </Grid>
            <Grid size={{ xs: 12, lg: 7, xl: 5.5 }}>
              <ProjectsFilter active={filter} onClick={handleFilterClick} />
            </Grid>
            {/* Search Bar */}
            <Grid
              size={{ xs: 12, lg: 5, xl: 4 }}
              paddingTop={{ xs: 3, lg: 0 }}
              paddingLeft={{ xs: 0, lg: 3 }}
            >
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                clearSearch={clearSearch}
              />
            </Grid>
          </Grid>
          {/* Header Section */}

          {/* Projects Grid */}
          {isGetUserLoading || clientListIsLoading ? (
            <Box
              display={'flex'}
              width={'100%'}
              height={'30%'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <CircularProgress size={50} sx={{ paddingY: 4 }} />
            </Box>
          ) : filteredProjects.length === 0 ? ( // Check if filteredProjects is empty
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={14}
              marginTop={6}
              color="text.secondary"
            >
              <Typography>
                {translateProjects('messages.displayProjectsNoItemsMessage')}
              </Typography>
            </Box>
          ) : (
            <Grid
              container
              spacing={5}
              paddingTop={7}
              alignItems={{ xs: 'inherent', md: 'stretch' }}
            >
              {currentProjects.map((project) => (
                <Grid
                  key={project.id}
                  size={{ xs: 12, md: 6, lg: 4 }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  paddingBottom={{ xs: 0, md: 5 }}
                >
                  <ProjectCard
                    projectId={project.id}
                    projectName={project.projecttype}
                    clientName={getClientNameById(
                      project.client,
                      rawClientData,
                    )}
                    consultant={getUserNameById(
                      project.accountmanager,
                      rawAllUsersData,
                    )}
                    dueDate={project.duedate}
                    step={translateProjects(
                      projectStepsTranslationMappings[
                        project.step as keyof ProjectStepsTranslationKeys
                      ],
                    )}
                    status={project.status}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {/*Arrows Next and before*/}
          <Box display="flex" justifyContent="flex-start" paddingY={7}>
            <IconButton
              onClick={handlePrevPage}
              disabled={currentPage === 1 || filteredProjects.length === 0}
              sx={{
                cursor:
                  currentPage === 1 || filteredProjects.length === 0
                    ? 'not-allowed'
                    : 'pointer',
                color:
                  currentPage === 1 || filteredProjects.length === 0
                    ? 'primary.main'
                    : 'textColor.main',
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton
              onClick={handleNextPage}
              disabled={
                currentPage ===
                  Math.ceil(filteredProjects.length / projectsPerPage) ||
                filteredProjects.length === 0
              }
              sx={{
                cursor:
                  currentPage ===
                    Math.ceil(filteredProjects.length / projectsPerPage) ||
                  filteredProjects.length === 0
                    ? 'not-allowed'
                    : 'pointer',
                color:
                  filteredProjects.length === 0
                    ? 'primary.main'
                    : 'textColor.main',
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};
