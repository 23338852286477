import { colors } from 'utils/colors';
import { Box, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ColumnDef, Row, Table } from '@tanstack/react-table';
import { useIsWindowWidthMobile } from 'utils/hooks/useIsWindowWidthMobile';
import { DataVisibilityType } from 'pages/clients/api/get-clients';
import {
  AddButtonContainer,
  AddTaskContainer,
  AddTaskSpacer,
  CardHeaderContainer,
  SeeAllContainer,
  TableContainer,
  TodayTaskContainer,
} from 'pages/task-manager/components/styled';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { DisplayTable } from 'pages/clients/components/clients-table';
import TaskTableSubComponent from 'pages/task-manager/components/task-table-sub-component';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useIsWindowWidthDesktop } from 'utils/hooks/useIsWindowWidthDesktop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslateAll } from 'locales/translation-hooks';
import { useTheme } from '@mui/material/styles';

export type NewCustomProjectType = {
  id: string;
  name: string;
  clientName: string;
};
export const CustomProjects = () => {
  const customProjectError = false;
  const customProjectIsLoading = false;
  //TODO - Replace this with Actual Data
  const customProjectsData = [
    {
      id: '123',
      name: 'Project 1',
      clientName: 'BC Hydro',
    },
    {
      id: '124',
      name: 'Project 2',
      clientName: 'Transalta',
    },
    {
      id: '125',
      name: 'Project 3',
      clientName: 'Uber',
    },
    {
      id: '126',
      name: 'Project 4',
      clientName: 'Generali',
    },
  ];

  const { isMobileWidth, windowSizeWidth } = useIsWindowWidthMobile();
  const { isDesktopWidth } = useIsWindowWidthDesktop();

  const { translateCommon, translateSystemManagement } = useTranslateAll([
    'systemManagement',
    'common',
  ]);

  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      dueDate: !isMobileWidth,
      reviewButton: !isDesktopWidth,
    });

  const [undoTimer, setUndoTimer] = useState<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();
  const columns: ColumnDef<NewCustomProjectType>[] = [
    {
      header: ``,
      accessorKey: 'name',
      enableSorting: false,
      size: undefined,
      minSize: 1,
      maxSize: 1,
      cell: ({ row }) => (
        <>
          <Box display={'flex'}>
            {/*<Tooltip title={row.original.taskName}>*/}
            <Tooltip title={row.original.name}>
              <Box
                pl={5}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: { xs: '100%' },
                  maxWidth: { xs: '100%', md: '180px', xl: '170px' },
                }}
              >
                <Typography
                  variant={'body3'}
                  p={0}
                  m={0}
                  color={'text.primary'}
                >
                  {row.original.name}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </>
      ),
    },
    {
      header: ``,
      accessorKey: 'icons',
      minSize: 1,
      size: 1,
      cell: ({
        table,
        row,
      }: {
        table: Table<NewCustomProjectType>;
        row: Row<NewCustomProjectType>;
      }) => (
        <Box
          tabIndex={0}
          role="button"
          display={'flex'}
          px={5}
          justifyContent={'flex-end'}
        >
          <Tooltip title={row.original.clientName}>
            <Box
              alignItems={'center'}
              textAlign={'end'}
              pl={2}
              pr={5}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: { xs: '100%', lg: '120px', xl: '70%' },
              }}
            >
              <Typography variant={'body3'} p={0} m={0} color={'text.primary'}>
                {row.original.clientName}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip
            title={translateSystemManagement('tooltTips.editCustomProject')}
          >
            <EditIcon
              onClick={() => alert('edit')}
              fontSize={'small'}
              sx={{ color: 'secondary.dark', pl: 5 }}
            />
          </Tooltip>
          <Tooltip
            title={translateSystemManagement('tooltTips.archiveCustomProject')}
          >
            <ArchiveOutlinedIcon
              onClick={() => alert('archive')}
              fontSize={'small'}
              sx={{ color: 'secondary.dark', pl: 0 }}
            />
          </Tooltip>
        </Box>
      ),
      enableSorting: false,
    },
  ];

  useEffect(() => {
    return () => {
      // Clear the undo timer when component unmounts
      clearTimeout(undoTimer!);
    };
  }, [undoTimer]);

  const addTaskHandler = () => {
    alert('To create custom project');
  };

  useEffect(() => {
    setColumnsVisibility({
      dueDate: isMobileWidth,
      reviewButton: isDesktopWidth,
    });
    return () => {};
  }, [windowSizeWidth]);

  const displayTableNoItemsMessage = translateSystemManagement(
    'displayCustomProjectsTable.displayTableNoItemsMessage',
  );

  const theme = useTheme();
  return (
    <>
      <TodayTaskContainer hidePadding background={colors.white}>
        <CardHeaderContainer>
          <Typography
            variant={'subtitle2'}
            color={'primary.dark'}
            onClick={() => navigate('/custom-project')}
            sx={{
              paddingY: 2,
              cursor: 'pointer',
            }}
          >
            {translateCommon('headers.headerCustomProjects')}
          </Typography>
          <AddTaskSpacer>
            <AddTaskContainer>
              <AddButtonContainer padding={'8px 7px'} onClick={addTaskHandler}>
                <Tooltip
                  title={translateSystemManagement(
                    'tooltTips.addNewCustomProject',
                  )}
                >
                  <AddIcon sx={{ color: 'primary.light', fontSize: '14' }} />
                </Tooltip>
              </AddButtonContainer>
            </AddTaskContainer>
          </AddTaskSpacer>
        </CardHeaderContainer>
        <TableContainer maxHeight={'100%'}>
          <DisplayTable
            data={customProjectsData!}
            columns={columns}
            getRowCanExpand={() => true}
            columnsVisibility={columnsVisibility}
            showFooter={false}
            cellMaxWidth={'200'}
            RenderSubComponent={TaskTableSubComponent}
            loadingItem={
              customProjectIsLoading ? (
                <Box
                  display={'flex'}
                  width={'100%'}
                  height={'30%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <CircularProgress size={30} />
                </Box>
              ) : customProjectError ? (
                displayTableNoItemsMessage
              ) : undefined
            }
            hideHeader
          />
        </TableContainer>
        <SeeAllContainer onClick={() => navigate('/task-manager')}>
          <Tooltip
            title={translateSystemManagement('tooltTips.seeAllCustomProjects')}
          >
            <Box
              height={theme.spacing(9)}
              mx={6}
              alignItems={'center'}
              display={'flex'}
            >
              <Typography variant={'link'} color={'primary.dark'}>
                {translateCommon('seeAll')}
              </Typography>
            </Box>
          </Tooltip>
        </SeeAllContainer>
      </TodayTaskContainer>
    </>
  );
};
