import Grid from '@mui/material/Grid2';
import {
  Box,
  Button,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslateAll } from 'locales/translation-hooks';
import { useSystemNoticesDataContext } from '../../../react-query-toolkit/state/system-notices-context';
import { SystemNoticeCreationType } from '../../../react-query-toolkit/state/types';
import { useDispatch } from 'react-redux';
import { ghgSfeApi } from '../../../react-query-toolkit/services/ghgci-sfe';
import { PlEntities } from '../../../react-query-toolkit/services/types';

export function NoticeCreator() {
  const dispatch = useDispatch();
  const {
    handleCreateSystemNotice,
    isCreateSystemNoticeLoading,
    isCreateSystemNoticeSuccess,
    resetCreateSystemNoticeQueryValues,
  } = useSystemNoticesDataContext();

  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    try {
      const newNotice = await handleCreateSystemNotice(
        formData.title,
        formData.date,
        formData.description,
      );

      // Manually update the cache so the new notice appears immediately
      dispatch(
        ghgSfeApi.util.updateQueryData(
          'getPlAll',
          { typename: PlEntities.System },
          (draft: any) => {
            if (draft?.Items) {
              draft.Items.unshift(newNotice); // Add new notice to the top of the list
            } else {
              draft.Items = [newNotice];
            }
          },
        ),
      );

      form.reset();
      resetCreateSystemNoticeQueryValues();
    } catch (e) {
      console.error('Error creating system notice:', e);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [formData, setFormData] = useState<SystemNoticeCreationType>({
    title: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
  });

  const { translateSystemNotices, translateCommon } = useTranslateAll([
    'systemNotices',
    'common',
  ]);

  const handleChange = <K extends keyof SystemNoticeCreationType>(
    field: K,
    value: SystemNoticeCreationType[K],
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const formDisabled = false;
  isCreateSystemNoticeLoading || isCreateSystemNoticeSuccess;

  return (
    <Box
      component="form"
      onSubmit={onSubmitForm}
      sx={{
        mt: 2,
        height: '100%',
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Grid container spacing={isMobile ? 4 : 4} px={{ xs: 7 }} py={{ xs: 9 }}>
        <Typography variant={'body1'} color={'text.primary'}>
          {translateSystemNotices('creator.header')}
        </Typography>
        <Grid size={{ xs: 12 }}>
          <TextField
            fullWidth
            label={translateSystemNotices('creator.titleLabel')}
            onChange={(e) => handleChange('title', e.target.value)}
            required
          />
        </Grid>

        <Grid size={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label={translateSystemNotices('creator.descriptionLabel')}
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </Grid>
        <Grid
          container
          size={{ xs: 12, md: 12 }}
          spacing={{ xs: 3, md: 2 }}
        ></Grid>

        {/* Action buttons */}
        <Grid
          container
          direction={{ md: 'row', sm: 'column' }}
          spacing={2}
          justifyContent="space-between"
          sx={{ paddingBottom: theme.spacing(10) }}
        >
          <Tooltip title={translateSystemNotices('tooltTips.add')}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formDisabled || !formData.title}
            >
              {translateCommon('buttons.addButton')}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
}
