import i18next from 'i18next';
import { useTranslation, UseTranslationOptions } from 'react-i18next';
import { LanguageGendering, Namespaces } from './languages';
import { TFunction } from 'i18next';

/*
- the purpose of this hook is to facilitate the use of the i18next namespaces while reducing the amount of repetitive typing that would be required when using more than one namespace per file.
- therefore, it is written in a way to leverage the autocomplete and strict typing of typescript as much as possible, in a way that will also prevent wrong namespace values from being passed to the hook as well as wrong translation function names from being returned or destructured from the hook call.
- the hook will return an object with functions corresponding to the namespaces passed to it, with the function names being generated based on the namespace values, in the format of "translate" followed by the capitalized namespace value. ie. "translateLogin" for the namespace "login".
- the usage of the N type parameter ensurers that the the autocomplete suggestions will only show the namespaces that are PASSED to the hook, and not all the namespaces available in the project. As well as removing the conditionality of the translate/namespace call. see ".?".
- Because this is meant to stay as faithful as possible to the i18n useTranslaton hook,it will also accept an optional options parameter that can be passed to the useTranslation hook/TFunction, which uses them internally..
*/
export type TranslationFunction = (...args: Parameters<TFunction>) => string;

export type NameSpaceFunctionsNames<N extends Namespaces> =
  `translate${Capitalize<N>}`;

export type TranslationsFunctions<N extends Namespaces> = Record<
  NameSpaceFunctionsNames<N>,
  TranslationFunction
>;

export type TranslateByWordGender = (
  translatorFunction: (context?: LanguageGendering) => string,
  languages1?: string[],
  context1?: LanguageGendering,
  languages2?: string[],
  context2?: LanguageGendering,
  languages3?: string[],
  context3?: LanguageGendering,
) => string;

const createTranslationsFunctions = <N extends Namespaces>(
  namespaces: N | N[],
  translateFunction: TFunction,
): TranslationsFunctions<N> => {
  const namespaceArray = Array.isArray(namespaces) ? namespaces : [namespaces];
  const translationsFunctions: TranslationsFunctions<N> =
    {} as TranslationsFunctions<N>;

  const formatNamespaceName = (namespace: N): NameSpaceFunctionsNames<N> =>
    `translate${namespace.charAt(0).toUpperCase()}${namespace.slice(1)}` as NameSpaceFunctionsNames<N>;

  namespaceArray.forEach((namespace) => {
    const functionName = formatNamespaceName(
      namespace,
    ) as keyof TranslationsFunctions<N>;
    translationsFunctions[functionName] = (...args: Parameters<TFunction>) => {
      const [key, ...restArgs] = args;
      return translateFunction(`${namespace}:${key}`, ...restArgs);
    };
  });

  return translationsFunctions;
};

export const createGetPropertyTranslation = (tKey, tFunction) => {
  return tFunction(tKey);
};

export const translateAll = <N extends Namespaces>(
  namespaces: N | N[],
): TranslationsFunctions<N> => {
  const t = i18next.t.bind(i18next);
  return createTranslationsFunctions(namespaces, t);
};

export const useTranslateAll = <N extends Namespaces>(
  namespaces: N | N[],
  options?: UseTranslationOptions<string>,
): TranslationsFunctions<N> & {
  translateByWordGender: TranslateByWordGender;
  language: string;
} => {
  const { t, i18n } = useTranslation(namespaces, options);
  const language = i18n.language;
  const translationsFunctions = createTranslationsFunctions(namespaces, t);

  const translateByWordGender: TranslateByWordGender = (
    translatorFunction,
    languages1,
    context1,
    languages2,
    context2,
    languages3,
    context3,
  ) => {
    return languages1?.includes(language)
      ? translatorFunction(context1)
      : languages2?.includes(language)
        ? translatorFunction(context2)
        : languages3?.includes(language)
          ? translatorFunction(context3)
          : translatorFunction();

    //the translatorFunction() is meant to be a wrapper to the namespace specific translation functions, so it should return those translation functions with the context passed to it. If you'd like to directly use the translation function, you can do so the same way youd'd pass an unnamed function to a react component:
    // ie. translateByWordGender((context?) => translateCommon('someKey',{context}))
  };

  return { ...translationsFunctions, translateByWordGender, language, t };
};
