{
  "pageTitle": "System Notices",
  "creator": {
    "header": "Add new system notice. This message will be visible to all users.",
    "titleLabel": "Title",
    "descriptionLabel": "Description"
  },
  "tooltTips":{
    "add":"Save and add this new system notice",
    "delete":"Delete this system notice for all users"
  },
  "systemNoticesList":{
    "header": "Current system notices",
    "deleteHeader": "Delete for all users"
  },
  "messages":{
    "loadingError": "Error loading notices.",
    "noNoticesAvailable": "No system notices available."
  }
}
