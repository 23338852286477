import { Divider, Span, useNavAndSideBarContext } from 'components';
import { spacingWithPx } from 'utils/styles';
import { MobileArrowRightIcon } from './icons/mobile-arrow-right';
import { MobileCalendarIcon } from './icons/mobile-calendar-icon';
import { MobileNotificationIcon } from './icons/mobile-notification-icon';
import { UserProfilePicture } from './icons/mobile-user-icon';
import { Flex, MobileMenuContainer, NavBarItem, NavBarList } from './styled';
import useAuth from 'configs/AuthContext';
import { useNavigate } from 'react-router-dom';
import { RefObject, useEffect, useRef, useState } from 'react';
import { GetUserFromEmail } from 'utils/get-user-from-email';
import { useTranslateAll } from 'locales/translation-hooks';

type Props = {
  showMenuBar: boolean;
  profilePicture?: string;
  iconRef: RefObject<HTMLButtonElement>;
};

export const MobileMenu = ({ showMenuBar, profilePicture, iconRef }: Props) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [userName, setUserName] = useState('');
  const { setShowMenuBar, setShowSideBar } = useNavAndSideBarContext();
  const { translateMisc } = useTranslateAll(['misc']);
  const menuRef = useRef<HTMLDivElement>(null);

  function signOutHandler() {
    try {
      auth.signout(() => navigate('/login'));
      setShowMenuBar(false);
      setShowSideBar(false);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        iconRef.current !== event.target && // Ignore direct clicks on the icon
        !iconRef.current?.contains(event.target as Node) // Ignore clicks inside the icon
      ) {
        setShowMenuBar(false);
      }
    }

    if (showMenuBar) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenuBar, setShowMenuBar, iconRef]);

  const menuItems = {
    get myProfile() {
      return {
        key: translateMisc('NavBar.navBarNavigationItems.userProfile'),
        Icon: <UserProfilePicture picture={profilePicture ?? ''} />,
        onClick: () => navigate('/user-profile'),
      };
    },
    get calendar() {
      return {
        key: translateMisc('NavBar.navBarNavigationItems.calendar'),
        Icon: <MobileCalendarIcon />,
        onClick: () => navigate('/calendar'),
      };
    },
    get notifications() {
      return {
        key: translateMisc('NavBar.navBarNavigationItems.notifications'),
        Icon: <MobileNotificationIcon />,
        onClick: () => navigate('/notifications'),
      };
    },
    get signOut() {
      return {
        key: translateMisc('NavBar.navBarNavigationItems.signout'),
        Icon: <MobileArrowRightIcon />,
        onClick: () => signOutHandler(),
      };
    },
  };

  useEffect(() => {
    const currentUserEmail =
      auth.user?.getSignInUserSession()?.getIdToken().payload.email || '';
    setUserName(GetUserFromEmail(currentUserEmail));
  }, [auth.user]);

  return (
    <>
      {auth.user && (
        <MobileMenuContainer
          ref={menuRef}
          id="mobile-menu-container"
          className={showMenuBar ? 'show' : ''}
        >
          <Flex
            flexDirection="column"
            padding={`${spacingWithPx.md} ${spacingWithPx.sm}`}
          >
            <Flex flexDirection="column">
              <Span fontSize="18px" color="secondaryBlue" fontWeight="600">
                {userName}
              </Span>
              <Span fontSize="14px" color="black">
                Consultant
              </Span>
            </Flex>
            <Divider />
            <Flex flexDirection="column">
              <NavBarList>
                {Object.entries(menuItems).map(([key, values], index) => {
                  const hasEndBorder =
                    index === Object.keys(menuItems).length - 2;
                  return (
                    <div key={`${key}-${index}`}>
                      <NavBarItem key={key} onClick={values.onClick}>
                        {error && error}
                        <div>
                          <>{values.Icon}</>
                          <Span color="secondaryBlue">{values.key}</Span>
                        </div>
                      </NavBarItem>
                      {hasEndBorder ? <Divider /> : null}
                    </div>
                  );
                })}
              </NavBarList>
            </Flex>
          </Flex>
        </MobileMenuContainer>
      )}
    </>
  );
};
