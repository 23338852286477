import React from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { translateAll } from 'locales/translation-hooks';
import { defaultTransitionTimeAndStyle } from 'utils/styles';
import { PageTitle } from 'components/atoms/page-title/page-title';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Theme } from '@mui/material/styles';

const { translateMisc } = translateAll(['misc']);

interface RightSidebarTab {
  key: string;
  path: string;
}

const rightSidebarTabs: Record<string, RightSidebarTab> = {
  'System Monitoring': {
    key: 'systemManagerSideBar.sideBarNavigationItems.systemMonitoring',
    path: '/system-monitoring',
  },
  Mandates: {
    key: 'systemManagerSideBar.sideBarNavigationItems.mandates',
    path: '/mandates',
  },
  'Project Types': {
    key: 'systemManagerSideBar.sideBarNavigationItems.projectTypes',
    path: '/project-types',
  },
  'Client Pools': {
    key: 'systemManagerSideBar.sideBarNavigationItems.clientPools',
    path: '/client-pools',
  },
  Programs: {
    key: 'systemManagerSideBar.sideBarNavigationItems.programs',
    path: '/programs',
  },
  'Emission factors': {
    key: 'systemManagerSideBar.sideBarNavigationItems.emissionFactors',
    path: '/emission-factors',
  },
  Transformations: {
    key: 'systemManagerSideBar.sideBarNavigationItems.transformations',
    path: '/transformations',
  },
  jurisdictions: {
    key: 'systemManagerSideBar.sideBarNavigationItems.jurisdictions',
    path: '/jurisdictions',
  },
  SofConect: {
    key: 'systemManagerSideBar.sideBarNavigationItems.sofConect',
    path: '/sofconect',
  },
  Reports: {
    key: 'systemManagerSideBar.sideBarNavigationItems.reports',
    path: '/reports',
  },
};

type RightSidebarProps = {
  showSMSidebar: boolean;
  setShowSMSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SMSidebar: React.FC<RightSidebarProps> = ({
  showSMSidebar,
  setShowSMSidebar,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  ); // Matches xs (mobile)
  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        width: '100%',
        flexShrink: 0,
        zIndex: 900,
        transition: `transform ${defaultTransitionTimeAndStyle}`,
        '& .MuiDrawer-paper': {
          width: { sm: '100%', md: 'auto' },
          p: { sm: 'auto', md: '0 !important' },
          transition: `transform ${defaultTransitionTimeAndStyle}`,
          transform: {
            md: 'none',
            sm: `${showSMSidebar ? 'translate(0, 0)' : 'translate(100%, 0)'}`,
            lg: 'none',
          },
          position: { sm: 'fixed', md: 'relative' },
          mt: { sm: '60px', md: 0 },
          paddingY: { sm: 7, md: theme.spacing(2) },
          paddingX: { sm: 7, md: theme.spacing(2) },
          boxSizing: 'border-box',
          backgroundColor: {
            sm: theme.palette.secondary.light,
            md: 'transparent',
          },
          borderLeft: 'none',
        },
      }}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {isMobile ? (
          <ArrowForwardIosIcon
            sx={{ color: 'primary.light', pt: 6, pb: 2 }}
            onClick={() => setShowSMSidebar(!showSMSidebar)}
          />
        ) : null}
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <List
          sx={{
            display: 'flex',
            py: 0,
            flexDirection: 'column',
            gap: { xs: 2, md: 4 },
          }}
        >
          {Object.entries(rightSidebarTabs).map(([key, values], index) => (
            <ListItem key={index} disableGutters sx={{ py: 0 }}>
              <Paper
                elevation={1}
                sx={{
                  flex: 1, // Ensures equal height in the Grid
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 2,
                }}
              >
                <ListItemButton
                  onClick={() => {
                    setShowSMSidebar(!showSMSidebar);
                    navigate(values.path);
                  }}
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.text.contrast,
                    textAlign: 'center',
                    fontVariant: 'button',
                    paddingY: theme.spacing(3),
                    paddingX: {
                      xs: theme.spacing(7),
                      lg: theme.spacing(3),
                      xl: theme.spacing(7),
                    },
                    borderRadius: 2,
                    transition: 'background-color 0.2s ease-in-out',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                    '&:focus': {
                      backgroundColor: theme.palette.primary.light, // Prevents unwanted hover color change
                      outline: 'none', // Removes focus outline
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {translateMisc(values.key)}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </Paper>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box pb={9} />
    </Drawer>
  );
};
