import React, { useState, useRef, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  Avatar,
  Stack,
  Divider,
  TextField,
  Icon,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@mui/material';
import Card from '@mui/material/Card';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';
import { useProjectsDataContext } from '../../../../react-query-toolkit/state/projects-context';
import { ProjectComment } from '../../../../react-query-toolkit/state/types';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';
import { useUserDataContext } from '../../../../react-query-toolkit/state/user-context';
import { findUserById } from 'utils/find-user-by-id';
import Popper from '@mui/material/Popper';

interface Comment extends ProjectComment {
  replies: Comment[];
  isMine: boolean;
}

interface CommentsDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  stepComment: string;
}

export const ProjectComments: React.FC<CommentsDrawerProps> = ({
  open,
  toggleDrawer,
  stepComment,
}) => {
  const theme = useTheme();
  const [replyInputValue, setReplyInputValue] = useState<string>('');
  const {
    handleGetProject,
    isGetProjectsLoading,
    ghgciId,
    handleCreateProjectComment,
    handleUpdateProjectComment,
    handleDeleteComment,
  } = useProjectsDataContext();
  const { handleGetAllUsers, isGetUserLoading } = useUserDataContext();
  const rawUserData = handleGetAllUsers() || [];

  const rawProjectData = handleGetProject();
  const commentsData: ProjectComment[] = rawProjectData?.projectComments || [];

  // Memoize the filtered data to avoid unnecessary re-renders
  const filteredCommentsData = useMemo(() => {
    return commentsData.filter(
      (comment) => comment.projectstep === stepComment,
    );
  }, [commentsData, stepComment]);

  const nestComments = (comments: ProjectComment[]): Comment[] => {
    const commentMap = new Map<string, Comment>();

    // Initialize all comments in the map
    comments.forEach((comment) => {
      commentMap.set(comment.id, {
        ...comment,
        replies: [],
        isMine: comment.author === ghgciId,
      });
    });

    const nestedComments: Comment[] = [];

    comments.forEach((comment) => {
      if (comment.parent) {
        // Add the comment as a reply to its parent
        const parentComment = commentMap.get(comment.parent);
        if (parentComment) {
          parentComment.replies.push(commentMap.get(comment.id)!);
        }
      } else {
        // Add top-level comments to the nested array
        nestedComments.push(commentMap.get(comment.id)!);
      }
    });

    return nestedComments;
  };

  // Initialize comments state and update it only if filteredCommentsData changes
  const [comments, setComments] = useState<Comment[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!open) {
      setInputValue(''); // Clear the input when the drawer is closed
    } else {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  }, [open]);

  useEffect(() => {
    const nested = nestComments(filteredCommentsData);
    // Only update state if the nested data is different
    if (JSON.stringify(comments) !== JSON.stringify(nested)) {
      setComments(nested);
    }
  }, [filteredCommentsData]);

  const [areAllRepliesVisible, setAreAllRepliesVisible] =
    useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const [replyTo, setReplyTo] = useState<{
    id: string | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const [inputValue, setInputValue] = useState<string>('');
  const [replyVisibility, setReplyVisibility] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleRepliesVisibility = (commentId: string) => {
    setReplyVisibility((prevVisibility) => ({
      ...prevVisibility,
      [commentId]: !prevVisibility[commentId],
    }));
  };

  const toggleAllRepliesVisibility = () => {
    setAreAllRepliesVisible((prev) => !prev);
    if (areAllRepliesVisible) setReplyVisibility({});
  };

  const [loadingComments, _setLoadingComments] = useState<{
    [key: string]: boolean;
  }>({});

  const handleCheckComment = async (
    commentId: string,
    currentChecked: boolean,
  ) => {
    // Save the previous state for rollback in case of failure
    const previousComments = comments;

    // Optimistically update the UI
    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.id === commentId
          ? { ...comment, checked: !currentChecked }
          : {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply.id === commentId
                  ? { ...reply, checked: !currentChecked }
                  : reply,
              ),
            },
      ),
    );

    try {
      // Perform the mutation
      await handleUpdateProjectComment(
        commentId,
        '',
        stepComment, // Provide the current step if applicable
        [], // Read array
        '', // Tags (modify based on your needs)
        '', // Text (modify based on your needs)
        !currentChecked,
      );
    } catch (error) {
      // Rollback the state in case of failure
      setComments(previousComments);
      console.error('Failed to update comment:', error);
      alert('Failed to update comment. Please try again.');
    }
  };

  const addReply = (
    commentsList: Comment[],
    parentId: string,
    newReply: Comment,
  ): Comment[] => {
    return commentsList.map((comment) => {
      if (comment.id === parentId) {
        // Add reply to the correct parent
        return {
          ...comment,
          replies: [...comment.replies, newReply],
        };
      }
      // Recursively check replies
      return {
        ...comment,
        replies: addReply(comment.replies, parentId, newReply),
      };
    });
  };

  const handleSend = () => {
    if (!replyTo.id && !inputValue.trim()) return; // Skip if no comment
    if (replyTo.id && !replyInputValue.trim()) return; // Skip if no reply

    const text = replyTo.id ? replyInputValue : inputValue;

    const newComment: Comment = {
      id: Date.now().toString(),
      typename: 'comment',
      created_at: new Date().toISOString(),
      author: ghgciId,
      parent: replyTo.id || '',
      projectstep: stepComment,
      read: [],
      checked: false,
      tags: '',
      text,
      replies: [],
      isMine: true,
    };

    if (replyTo.id) {
      // Add reply to the correct parent
      setComments((prevComments) =>
        addReply(prevComments, replyTo.id!, newComment),
      );
      handleCreateProjectComment(
        newComment.id,
        newComment.parent,
        newComment.projectstep,
        newComment.read,
        newComment.tags,
        newComment.text,
        newComment.checked,
        newComment.author,
      );
      setReplyInputValue(''); // Clear reply input
    } else {
      // Add a new top-level comment
      setComments((prevComments) => [...prevComments, newComment]);
      handleCreateProjectComment(
        newComment.id,
        newComment.parent,
        newComment.projectstep,
        newComment.read,
        newComment.tags,
        newComment.text,
        newComment.checked,
        newComment.author,
      );
      setInputValue(''); // Clear main comment input
    }

    setReplyTo({ id: null, name: null }); // Reset reply state
  };

  const deleteCommentOrReply = (
    comments: Comment[],
    commentId: string,
  ): Comment[] => {
    return comments.reduce<Comment[]>((acc, comment) => {
      if (comment.id === commentId) return acc;
      const updatedReplies = deleteCommentOrReply(comment.replies, commentId);
      return [...acc, { ...comment, replies: updatedReplies }];
    }, []);
  };

  const handleDelete = (commentId: string) => {
    const commentToDelete = commentsData.find(
      (comment) => comment.id === commentId,
    );

    if (commentToDelete) {
      // Check if the comment is a top-level comment (no parent)
      if (!commentToDelete.parent) {
        // Confirmation message for deleting a top-level comment and its replies
        const confirmDelete = window.confirm(
          'You are about to delete this comment and all of its replies. Are you sure?',
        );

        if (confirmDelete) {
          // Remove the top-level comment and all nested replies
          const deleteNestedReplies = (
            commentsList: Comment[],
            targetId: string,
          ): Comment[] => {
            return commentsList.filter((comment) => {
              if (comment.id === targetId) {
                // Recursively delete replies from the backend
                comment.replies.forEach((reply) =>
                  handleDeleteComment(reply.id),
                );
                handleDeleteComment(comment.id); // Delete the main comment
                return false; // Remove this comment from the list
              }
              // Recursively check and update replies
              comment.replies = deleteNestedReplies(comment.replies, targetId);
              return true;
            });
          };

          setComments((prevComments) =>
            deleteNestedReplies(prevComments, commentId),
          );
        }
      } else {
        // Confirmation message for deleting a reply only
        const confirmDelete = window.confirm(
          'Are you sure you want to delete this reply?',
        );

        if (confirmDelete) {
          // Remove only the specific reply from the nested structure
          setComments((prevComments) =>
            deleteCommentOrReply(prevComments, commentId),
          );

          // Here is the delete query
          handleDeleteComment(commentId);
        }
      }
    }
  };

  const highlightText = (text: string) => {
    if (!filterText) return text;
    const parts = text.split(new RegExp(`(${filterText})`, 'gi'));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === filterText.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </>
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterText(value);
    if (value.trim()) {
      setReplyVisibility({});
      setAreAllRepliesVisible(true);
    } else {
      setAreAllRepliesVisible(false);
    }
  };
  useEffect(() => {
    if (open) {
      inputRef.current?.focus(); // Focus the input when the drawer opens
    }
  }, [open]);

  const getFilteredComments = (filter: string) => {
    if (!filter) return comments;
    return comments
      .map((comment) => ({
        ...comment,
        replies: comment.replies.filter((reply) =>
          reply.text.toLowerCase().includes(filter.toLowerCase()),
        ),
      }))
      .filter(
        (comment) =>
          comment.text.toLowerCase().includes(filter.toLowerCase()) ||
          comment.replies.length > 0,
      );
  };

  const handleReply = (commentId: string, commenterName: string) => {
    setReplyTo((prevReplyTo) =>
      prevReplyTo.id === commentId
        ? { id: null, name: null }
        : { id: commentId, name: commenterName },
    );
    if (replyTo.id !== commentId) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  };

  const [menuState, setMenuState] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const handleMenuToggle = (commentId: string, anchor: HTMLElement | null) => {
    setMenuState((prev) => ({
      ...prev,
      [commentId]: prev[commentId] ? null : anchor,
    }));
  };

  const handleMenuClose = (commentId: string) => {
    setMenuState((prev) => ({
      ...prev,
      [commentId]: null,
    }));
  };

  const [editingComment, setEditingComment] = useState<{
    id: string | null;
    text: string;
  } | null>(null);

  // Function to handle editing a comment or reply
  const handleEditComment = (commentId: string, currentText: string) => {
    setEditingComment({ id: commentId, text: currentText });
  };

  // Function to handle changes in the text field while editing
  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editingComment) {
      setEditingComment({ ...editingComment, text: e.target.value });
    }
  };

  // Function to save the edited comment or reply
  const handleSaveEdit = async () => {
    if (!editingComment) return;

    const { id, text } = editingComment;

    // Find the comment being edited in the current comments list
    const commentToEdit = commentsData.find((comment) => comment.id === id);
    if (!commentToEdit) return;

    try {
      await handleUpdateProjectComment(
        id!,
        commentToEdit.parent,
        commentToEdit.projectstep,
        commentToEdit.read,
        commentToEdit.tags,
        text,
        commentToEdit.checked,
      );

      // Update the local state with the edited comment
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === id
            ? { ...comment, text } // Update the comment's text
            : {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply.id === id ? { ...reply, text } : reply,
                ), // Update text if it's a reply
              },
        ),
      );

      // Clear the editing state
      setEditingComment(null);
    } catch (error) {
      console.error('Failed to update comment:', error);
      alert('Failed to update the comment. Please try again.');
    }
  };

  // Function to cancel editing
  const handleCancelEdit = () => {
    setEditingComment(null);
  };

  const renderComment = (comment: Comment, indentLevel: number = 0) => {
    const user =
      rawUserData.length > 0 ? findUserById(comment.author, rawUserData) : null;
    const userName = user ? user.firstname : 'Unknown User';
    const repliesCount = comment.replies.length;
    const areRepliesVisible = replyVisibility[comment.id] || false;
    const formattedDate = DateTime.fromISO(comment.created_at).toFormat(
      'MMM dd, yyyy',
    );
    const isMenuOpen = Boolean(menuState[comment.id]);
    const isEditing = editingComment?.id === comment.id;

    return (
      <Box key={comment.id} ml={indentLevel * 2}>
        <Card
          elevation={comment.parent ? 0 : 3} // Set elevation to 0 for replies and 3 for comments
          sx={{ my: 4, mx: 2, paddingX: 5, paddingY: 4 }}
        >
          <Stack direction="row" alignItems="center" spacing={1} mb={1}>
            <Avatar sx={{ width: theme.spacing(6), height: theme.spacing(6) }}>
              <Icon>{userName}</Icon>
            </Avatar>
            <Typography color="primary.dark" variant="subtitle2">
              {comment.isMine ? 'Me' : userName}
            </Typography>
            <Typography color="secondary.dark" variant="body3">
              {formattedDate}
            </Typography>
            <Box flexGrow={1} />
            <IconButton
              onClick={() =>
                handleOpenDialog(comment.id, !!comment.parent, comment.checked)
              }
              sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
            >
              {loadingComments[comment.id] ? (
                <Box>
                  <CircularProgress size={24} />
                </Box>
              ) : comment.checked ? (
                <CircleIcon
                  color="primary"
                  sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
                />
              ) : (
                <CheckCircleOutlineIcon
                  color="primary"
                  sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
                />
              )}
            </IconButton>
            {comment.isMine && (
              <IconButton
                id={`menu-button-${comment.id}`}
                aria-controls={isMenuOpen ? `menu-${comment.id}` : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? 'true' : undefined}
                onClick={(e) => handleMenuToggle(comment.id, e.currentTarget)}
                sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
              >
                <MoreVertIcon
                  color="primary"
                  sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
                />
              </IconButton>
            )}
            <Popper
              open={isMenuOpen}
              anchorEl={menuState[comment.id]}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'left top',
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => handleMenuClose(comment.id)}
                    >
                      <MenuList
                        id={`menu-${comment.id}`}
                        aria-labelledby={`menu-button-${comment.id}`}
                        sx={{
                          backgroundColor: 'secondary.dark',
                          borderRadius: theme.spacing(2),
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleEditComment(comment.id, comment.text);
                            handleMenuClose(comment.id);
                          }}
                          sx={{ typography: 'body2', color: 'bg.box' }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleDelete(comment.id);
                            handleMenuClose(comment.id);
                          }}
                          sx={{ typography: 'body2', color: 'bg.box' }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>

          {isEditing ? (
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                value={editingComment.text}
                onChange={handleEditChange}
                multiline
                rows={2}
              />
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Button onClick={handleCancelEdit} color="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveEdit}
                  color="primary"
                  variant="contained"
                  sx={{ ml: 1 }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant="body3" color="text.primary" mb={1}>
              {highlightText(comment.text)}
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {!comment.parent && (
              <Box
                display="flex"
                alignItems="center"
                onClick={() => handleReply(comment.id, userName)}
              >
                <IconButton>
                  {replyTo.id === comment.id ? (
                    <CloseIcon
                      color="primary"
                      sx={{ width: theme.spacing(4), height: 'auto' }}
                    />
                  ) : (
                    <ReplyIcon
                      color="primary"
                      sx={{ width: theme.spacing(4), height: 'auto' }}
                    />
                  )}
                </IconButton>
                <Typography color="primary.light" variant="link">
                  {replyTo.id === comment.id ? 'Close' : 'Reply'}
                </Typography>
              </Box>
            )}
            {repliesCount > 0 && (
              <Typography
                variant="link"
                color="primary.light"
                sx={{ cursor: 'pointer', mt: 1 }}
                onClick={() => toggleRepliesVisibility(comment.id)}
              >
                {areRepliesVisible ? 'Hide Replies' : 'Show Replies'}
                {/*: `${repliesCount} replies - Last reply today at ${new Date().toLocaleTimeString()}`}*/}
              </Typography>
            )}
          </Box>

          {/* Render reply TextField if this comment is being replied to */}
          {replyTo.id === comment.id && (
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a Reply..."
              value={replyInputValue}
              onChange={(e) => setReplyInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                } else if (e.key === 'Enter' && e.shiftKey) {
                  setReplyInputValue((prevValue) => prevValue + '\n');
                }
              }}
              inputRef={inputRef}
              sx={{
                mt: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  backgroundColor: 'bg.main',
                  height: 'auto',
                  padding: '0px 10px 0px 30px',
                },
                '& .MuiInputBase-input': {
                  ...theme.typography.body3,
                  padding: '8px',
                  whiteSpace: 'pre-wrap',
                },
              }}
              multiline
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      handleSend();
                      setReplyInputValue(''); // Clear reply input after sending
                    }}
                  >
                    <SendIcon sx={{ color: '#4682b4' }} />
                  </IconButton>
                ),
              }}
            />
          )}

          {(areRepliesVisible || areAllRepliesVisible) && (
            <>
              {comment.replies.map((reply) => (
                <Box
                  sx={{
                    borderTop: `1px solid ${theme.palette.notificationBorderShadow.main}`,
                  }}
                >
                  {renderComment(reply, indentLevel + 1)}
                </Box>
              ))}
            </>
          )}
          <Divider sx={{ my: 2 }} />
        </Card>
      </Box>
    );
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState<{
    id: string;
    isReply: boolean;
    currentChecked: boolean;
  } | null>(null);
  const [previousCheckedState, setPreviousCheckedState] = useState<
    boolean | null
  >(null);

  const handleOpenDialog = (
    commentId: string,
    isReply: boolean,
    currentChecked: boolean,
  ) => {
    // Save the current checked state for potential rollback
    setPreviousCheckedState(currentChecked);
    setSelectedComment({ id: commentId, isReply, currentChecked });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    // Revert to the previous state if dialog is cancelled
    if (selectedComment) {
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === selectedComment.id
            ? { ...comment, checked: previousCheckedState ?? false } // Ensure checked is a boolean
            : {
                ...comment,
                replies: comment.replies.map((reply) =>
                  reply.id === selectedComment.id
                    ? { ...reply, checked: previousCheckedState ?? false } // Ensure checked is a boolean
                    : reply,
                ),
              },
        ),
      );
    }
    setDialogOpen(false);
    setSelectedComment(null);
  };

  const handleConfirmDialog = async () => {
    if (selectedComment) {
      const { id, currentChecked } = selectedComment;
      try {
        // Toggle the state and perform the mutation
        await handleCheckComment(id, currentChecked);
      } catch (error) {
        console.error('Failed to update comment:', error);
      }
    }
    setDialogOpen(false);
    setSelectedComment(null);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: { sm: '100%', md: 300 },
            boxSizing: 'border-box',
            top: 60,
          },
          '& .MuiBackdrop-root': { backgroundColor: 'transparent !important' },
        }}
      >
        <Box
          p={6}
          display="flex"
          flexDirection="column"
          height="calc(100vh - 100px)"
        >
          <Box display="block" alignItems="center" mb={2}>
            <Box
              display={'flex'}
              width={'100%'}
              paddingBottom={0}
              justifyContent="flex-end"
            >
              <IconButton onClick={toggleDrawer}>
                <CloseIcon color="primary" />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder=""
              value={filterText}
              onChange={handleSearchChange}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  backgroundColor: 'bg.main',
                  height: '28px', // Set the height to 28px
                  fontSize: '14px', // Adjust font size for smaller height
                  padding: '0 10px', // Adjust padding for better alignment
                  '&:hover': {
                    backgroundColor: '#eef1f5',
                  },
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 2px #b0c4de',
                    borderColor: 'transparent',
                  },
                },
                '& .MuiInputBase-input': {
                  ...theme.typography.body3, // Apply body3 variant directly
                  padding: '0 8px', // Align text properly
                  typography: 'secondary.main',
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      if (filterText) {
                        setFilterText('');
                        setAreAllRepliesVisible(false);
                        setReplyVisibility({});
                      }
                    }}
                  >
                    {filterText ? (
                      <CloseIcon sx={{ color: 'primary.dark' }} /> // Shows CloseIcon if filterText exists
                    ) : (
                      <SearchIcon sx={{ color: 'primary.dark' }} /> // Shows SearchIcon otherwise
                    )}
                  </IconButton>
                ),
              }}
            />
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent="space-between"
            >
              <Typography variant="h6" color="info.main">
                Comments
              </Typography>
              <Typography
                onClick={toggleAllRepliesVisibility}
                variant="link"
                color="primary.light"
              >
                {areAllRepliesVisible ? 'Collapse all' : 'Expand all'}
              </Typography>
            </Box>
            <Typography variant="subtitle2" color="text.primary">
              {capitalizeFirstLetter(stepComment)}
            </Typography>
          </Box>

          {isGetUserLoading || isGetProjectsLoading ? (
            // Display the loading message while data is being fetched
            <Box
              flexGrow={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Loading...</Typography>
            </Box>
          ) : (
            // Render comments once data is loaded
            <Box flexGrow={1} overflow="auto">
              {getFilteredComments(filterText).map((comment) =>
                renderComment(comment),
              )}
            </Box>
          )}

          <Box mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Add comment"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              disabled={!!replyTo.id} // Disable the comment input when replying
              inputRef={inputRef}
              sx={{
                mt: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  backgroundColor: 'bg.main',
                  height: 'auto',
                  padding: '0px 10px 0px 30px',
                  '&:hover': {
                    backgroundColor: 'bg.main',
                  },
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 2px #b0c4de',
                    borderColor: 'transparent',
                  },
                },
                '& .MuiInputBase-input': {
                  ...theme.typography.body3,
                  padding: '8px',
                  whiteSpace: 'pre-wrap',
                },
              }}
              multiline
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSend}>
                    <SendIcon sx={{ color: '#4682b4' }} />
                  </IconButton>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                } else if (e.key === 'Enter' && e.shiftKey) {
                  setInputValue((prevValue) => prevValue + '\n');
                }
              }}
            />
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            You are about to{' '}
            {selectedComment?.currentChecked ? 'unmark' : 'mark'} this{' '}
            {selectedComment?.isReply ? 'reply' : 'comment'} as addressed. Do
            you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
