import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { useNotificationCardInfo } from 'pages/dashboard/components/notification-card/notification-card-info';
import { PageTitle } from 'components/atoms/page-title/page-title';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslateAll } from 'locales/translation-hooks';
import { Theme, useTheme } from '@mui/material/styles';
import { SMSidebar } from 'pages/system-management/components/sm-sidebar';
import { SMDashboard } from 'pages/system-management/components/sm-dashboard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useHasSystemManagementAccess from 'utils/hooks/useHasSystemManagementAccess';
import UnauthorizedMessage from 'components/unauthorized-message/unauthorized-message';

export const SystemManagement = () => {
  const { translateCommon, translateSystemManagement } = useTranslateAll([
    'common',
    'systemManagement',
  ]);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  ); // Matches xs (mobile)

  const { isLoading } = useNotificationCardInfo();
  const theme = useTheme();
  const [showSMSidebar, setShowSMSidebar] = useState(false);
  const hasSystemManagementAccess = useHasSystemManagementAccess();
  return (
    <>
      {isLoading ? (
        <Box
          display={'flex'}
          width={'100%'}
          height={'30%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <>
          {hasSystemManagementAccess ? (
            <Box>
              <PageWrapper pxValue={7} pyValue={9}>
                <Box
                  justifyItems={'center'}
                  sx={{ width: '100%', cursor: 'pointer' }}
                >
                  <Box width={'100%'} maxWidth={theme.breakpoints.values.xxl}>
                    {/* FLEX CONTAINER to keep sidebar on right */}
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      pb={6}
                    >
                      <PageTitle
                        title={translateSystemManagement('pageTitle')}
                      />
                      {isMobile ? (
                        <ArrowBackIosIcon
                          sx={{
                            color: 'primary.light',
                            pl: 4,
                            margin: ' 0 !important',
                            left: `${theme.spacing(4)} !important`,
                            position: 'relative !important',
                          }}
                          onClick={() => setShowSMSidebar(!showSMSidebar)}
                        />
                      ) : null}
                    </Box>
                    <Box display="flex" width="100%">
                      {/* Main Content - Dashboard */}
                      <Box
                        pr={{ sm: 0, md: 7 }}
                        sx={{ flexGrow: 1, minWidth: 0 }}
                      >
                        <SMDashboard />
                      </Box>

                      {/* Sidebar - Placed at the END to appear on RIGHT */}
                      <Box
                        sx={{
                          maxWidth: { sm: '100%', md: '345px' }, //hardcoded by Ana
                          flexShrink: 0,
                        }}
                      >
                        <SMSidebar
                          showSMSidebar={showSMSidebar}
                          setShowSMSidebar={setShowSMSidebar}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </PageWrapper>
            </Box>
          ) : (
            <UnauthorizedMessage />
          )}
        </>
      )}
    </>
  );
};
