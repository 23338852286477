import { ReactNode, useEffect, useRef, useState } from 'react';
import { MobileMenu, NavBar } from './nav-bar';
import { Sidebar } from './sidebar';
import { useNavAndSideBarContext } from 'components/nav-and-sidebar/state/context';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslateAll } from 'locales/translation-hooks';
import { useRetrieveImage } from 'pages/user-profile/components/utilities/retrieve-images';
import { useParametersContext } from '../../react-query-toolkit/state/parameters-context';
import { useUserDataContext } from '../../react-query-toolkit/state/user-context';
import { Item, User } from '../../react-query-toolkit/state/types';

export const NavAndSideBar = ({ children }: { children: ReactNode }) => {
  const { ghgciWebSiteBucket, ghgciBucketFolder } = useParametersContext();
  const { showSideBar, showMenuBar, imageVersion, imageId, setImageId } =
    useNavAndSideBarContext();
  const [selectedComponent, setSelectedComponent] = useState('');
  const { translateCommon } = useTranslateAll(['common']);
  const location = useLocation();
  const { pathname: currentPath } = location;
  const { handleGetUser } = useUserDataContext();
  const rawUserData = handleGetUser() || [];

  function isUser(item: Item): item is User {
    return 'typename' in item && item.typename === 'user';
  }

  function getProfilePicture(userData: Item[]): string {
    const userItem = userData.find(isUser);
    return userItem ? userItem.profilepicture : '';
  }

  const profilePictureId =
    rawUserData.length > 0 ? getProfilePicture(rawUserData) : '';

  useEffect(() => {
    setImageId(profilePictureId);
  }, [profilePictureId]);

  const {
    imageFile,
    handleRetrieveImage,
    error: imageRetrievalError,
  } = useRetrieveImage(ghgciWebSiteBucket, ghgciBucketFolder, imageId);

  useEffect(() => {
    handleRetrieveImage();
  }, [imageVersion, imageId]);

  const profilePictureFile = imageRetrievalError ? '' : imageFile;

  useEffect(() => {
    function getPageName(currentPath: string) {
      const pageNames: Record<string, string> = {
        '/my-clients': translateCommon('headers.headerClient'),
        '/': translateCommon('headers.headerDashboard'),
        '/dashboard': translateCommon('headers.headerDashboard'),
        '/task-manager': translateCommon('headers.headerTaskManager'),
        '/my-projects': translateCommon('headers.headerMyProjects'),
        '/calendar': translateCommon('headers.headerCalendar'),
        '/notifications': translateCommon('headers.headerNotifications'),
        '/user-profile': translateCommon('headers.headerUserProfile'),
        '/system-management': translateCommon('headers.headerSystemManagement'),
      };
      return pageNames[currentPath] || '';
    }
    setSelectedComponent(getPageName(currentPath));
  }, [location]);

  // 🔹 Create a ref for the IconButton in NavBar
  const iconRef = useRef<HTMLButtonElement>(null);

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
      <NavBar
        pageName={selectedComponent}
        profilePicture={profilePictureFile}
        iconRef={iconRef}
      />
      <MobileMenu
        showMenuBar={showMenuBar}
        profilePicture={profilePictureFile}
        iconRef={iconRef}
      />
      <Sidebar showSideBar={showSideBar} profilePicture={profilePictureFile} />
      <Box
        component="main"
        sx={{
          mt: '60px',
          width: '100%',
          flexGrow: 1, // ✅ Allows the main container to expand as needed
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Ensures children take only the available space */}
        <Box sx={{ height: '100%', overflow: 'auto' }}>{children}</Box>
      </Box>
    </Box>
  );
};
