import React, { ReactNode, useState } from 'react';
import { Typography, Box, Paper, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { NoticesCardsNames } from './notification-card-info';
import { useTranslateAll } from '../../../../locales/translation-hooks';
import { useTranslation } from 'react-i18next';
import { LanguageGendering } from '../../../../locales/languages';

interface NotificationCardPropType {
  activeNumber: string | number;
  cardTitle: string;
  cardIcon: ReactNode;
  pathLink: string;
  setSelectedComponent: React.Dispatch<React.SetStateAction<string | null>>;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
  cardIconLink: string;
  name: NoticesCardsNames;
}

export default function NotificationCard(props: NotificationCardPropType) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { translateSystemManagement, translateByWordGender } = useTranslateAll([
    'systemManagement',
  ]);

  const { i18n } = useTranslation();
  const language = i18n.language;

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const getTooltipTitleForCard = () => {
    return props.name === 'systemNotices'
      ? translateSystemManagement('titles.systemNotices')
      : props.name === 'openProject'
        ? translateSystemManagement('titles.openProjects')
        : props.name === 'totalClients'
          ? translateSystemManagement('titles.totalClients')
          : props.name === 'totalConsultants'
            ? translateSystemManagement('titles.consultants')
            : '';
  };

  const elementToAdd =
    props.name === 'systemNotices'
      ? translateSystemManagement('labels.systemNotice').toLowerCase()
      : props.name === 'openProject'
        ? translateSystemManagement('labels.project').toLowerCase()
        : props.name === 'totalClients'
          ? translateSystemManagement('labels.client').toLowerCase()
          : props.name === 'totalConsultants'
            ? translateSystemManagement('labels.consultant').toLowerCase()
            : ''; //todo-cg:find better way to handle case where name is not part of the type

  const getTooltipTitleForAddButton = (name: NoticesCardsNames) => {
    const localTranslation = (context?: LanguageGendering) => {
      return translateSystemManagement('tooltTips.addNew', {
        context,
        what: elementToAdd,
      });
    };

    switch (name) {
      case 'systemNotices':
        return translateByWordGender(localTranslation, ['de', 'fr'], 'f');
      case 'openProject':
        return translateByWordGender(localTranslation);
      case 'totalClients':
        return translateByWordGender(localTranslation);
      case 'totalConsultants':
        return translateByWordGender(localTranslation);
      default:
        return '';
    }
  };

  const tooltipTitleForAddButton = getTooltipTitleForAddButton(props.name);

  return (
    <Tooltip
      title={getTooltipTitleForCard()}
      open={tooltipIsOpen}
      onMouseEnter={() => setTooltipIsOpen(true)}
      onMouseLeave={() => setTooltipIsOpen(false)}
    >
      <Box
        onClick={() => {
          props.setModalIsOpen(!props.modalIsOpen);
          props.setSelectedComponent(props.cardIconLink);
        }}
        sx={{
          width: '100%',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Paper
          elevation={1}
          sx={{
            flex: 1, // Ensures equal height in the Grid
            display: 'flex',
            flexDirection: 'column',
            p: 5,
          }}
        >
          <Grid container spacing={1} alignItems="center">
            {/* Icon + Number (Same Row) */}
            <Grid size={12} sx={{ display: 'flex' }}>
              {props.cardIcon}

              {/* Number + Title (Stacked) */}
              <Box
                width={'100%'}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Typography variant="h5" color="primary.dark">
                    {props.activeNumber}
                  </Typography>

                  {/* Add Icon - Prevent Modal Triggering */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click bubbling to parent
                      navigate(props.pathLink);
                    }}
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      backgroundColor: theme.palette.background.default,
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                  >
                    <Tooltip
                      title={tooltipTitleForAddButton}
                      onMouseEnter={() => setTooltipIsOpen(false)}
                      onMouseLeave={() => setTooltipIsOpen(true)}
                    >
                      <AddIcon
                        fontSize="large"
                        sx={{
                          color: theme.palette.primary.main,
                          '&:hover': {
                            color: theme.palette.common.white,
                          },
                        }}
                      />
                    </Tooltip>
                  </Box>
                </Box>

                <Typography
                  color="text.primary"
                  variant="body2"
                  sx={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    maxWidth: '100%',
                  }}
                >
                  {props.cardTitle}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Tooltip>
  );
}
