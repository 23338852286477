{
    "clientAccount":{
            "title": "Client Account Information",
            "placeHolders":{
                "address": "Address...",
                "city": "City...",
                "client": "ClientName...",
                "contact": "Contact Name...",
                "country": "Country...",
                "email": "Email...",
                "phone": "Phone Number...",
                "region": "Region...",
                "state": "State..."
            },
            "labels":{
                "city": "City",
                "client": "Client *",
                "contact": "Contact Name*",
                "email": "Email *",
                "phone": "Phone *",
                "state":"State *",
                "address":"Address *"
            },
            "errors":{
                "contactNotEmpty": "Contact Name cannot be empty.",
                "contactRequired": "Contact Name is required",
                "emailInvalid": "Invalid email address",
                "emailRequired": "Email is required",
                "nameNotEmpty": "Client Name cannot be empty.",
                "nameRequired": "Client Name is required",
                "phoneInvalid": "Invalid phone number.",
                "phoneRequired": "Phone Number is required"
            }
        },
      "totalClients":{
        "clientCreator":{
        "newClientTitle": "New Client",
        "newClientSubTitle": "Add your client information here, along with assigned consultants and projects."
        },
        "allClients": {
          "allClientsTitle": "All Clients",
          "confirmDelete": "OK",
          "cancelDelete": "Cancel",
          "deleteTitle": "Delete this client"
        },
        "errors": {
          "deleteClientError": "Error deleting Client"
        }
      },
    "editClient":{
        "title": "Edit Client",
        "labels":{
            "client": "Client",
            "firstName": "First Name",
            "lastName": "Last Name",
            "phone": "Phone",
            "email": "E-mail",
            "closeDate": "Close Date",
            "activeDate": "Active Date",
            "country": "Country",
            "stateProvince": "State/Province",
            "municipality": "Municipality",
            "city": "City",
            "streetAddress": "Street Address",
            "postalCode": "Postal Code",
            "organizationType": "Organization Type",
            "addProjects": "Add Projects",
            "addConsultants": "Add Consultants",
            "clientPool": "Client Pool"
        },
        "options":{
            "government": "Government (Regional or Local)",
            "company": "Company / Business",
            "industry": "Industry",
            "nonProfit": "Non-profit / Charity",
            "educationalInstitution": "Educational Institution",
            "communityGroup": "Community Group",
            "others": "Others"
        },
        "buttons":{
            "cancel": "Cancel",
            "save": "Save",
            "create": "Create"
        }
    }
}
