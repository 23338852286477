import React, { useEffect } from 'react';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { Box } from '@mui/material';
import { useTranslateAll } from 'locales/translation-hooks';
import { useWebSocketContext } from '../../react-query-toolkit/state/websocket-context';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import { PageTitle } from 'components/atoms/page-title/page-title';
import useHasSystemManagementAccess from 'utils/hooks/useHasSystemManagementAccess';
import { ClientCreator } from 'pages/total-clients/components/client-creator';
import ClientsTable from 'pages/total-clients/components/clients-table';
import UnauthorizedMessage from 'components/unauthorized-message/unauthorized-message';
import { CustomBreadcrumb } from 'components';

export const TotalClients: React.FC = () => {
  const { translateClients, translateCommon } = useTranslateAll([
    'clients',
    'common',
  ]);

  const { lastMessageData } = useWebSocketContext();

  useEffect(() => {
    console.log(lastMessageData);
  }, [lastMessageData]);

  const theme = useTheme();
  const hasSystemManagementAccess = useHasSystemManagementAccess();

  return (
    <>
      {hasSystemManagementAccess ? (
        <Box
          position="relative"
          sx={{
            overflowX: { xs: 'hidden' },
          }}
        >
          <CustomBreadcrumb
            links={[
              {
                title: `${translateCommon('breadcrumbs.systemManagement')}`,
                to: '/system-management/',
              },
              {
                title: `${translateCommon('breadcrumbs.totalClients')}`,
                to: '/system-management/total-clients/',
                current: true,
              },
            ]}
          />
          <PageWrapper heightValue={'100%'}>
            {/* Ensures main container only takes available space */}
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                padding: { xs: 0, lg: 2 },
                justifyContent: 'center',
                minHeight: 0,
              }}
            >
              <Box
                width="100%"
                maxWidth={theme.breakpoints.values.xxl}
                flexGrow={1}
                justifyContent={'center'}
              >
                <Grid
                  container
                  spacing={7}
                  justifyContent={'center'}
                  alignItems={'start'} // This prevents the grid to be the same height for both
                  width={'100%'}
                  sx={{ flexGrow: 1, minHeight: 0 }}
                >
                  {/* Left Side: Title & Client Creator */}
                  <Grid
                    container
                    direction={{ xs: 'row', lg: 'column' }}
                    size={{ xs: 12, lg: 5 }}
                    rowSpacing={6}
                    sx={{
                      px: 8,
                      py: 9,
                      flexDirection: 'column',
                      flexGrow: 1,
                      minHeight: 0,
                      borderRadius: 2,
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    <Grid size={{ xs: 12 }}>
                      <PageTitle
                        title={translateClients(
                          'totalClients.clientCreator.newClientTitle',
                        )}
                        py={0}
                      />
                    </Grid>

                    {/* NoticeCreator with fixed height */}
                    <Grid
                      size={{ xs: 12 }}
                      sx={{
                        flexShrink: 0,
                        flexGrow: 1,
                        overflow: 'hidden',
                        borderRadius: 2,
                      }}
                    >
                      <ClientCreator />
                    </Grid>
                  </Grid>

                  {/* Right Side: Current System Notices (Scrollable) */}
                  <Grid
                    container
                    rowSpacing={6}
                    alignContent={'start'}
                    size={{ xs: 12, lg: 'grow' }}
                    px={{ xs: 7 }}
                    py={{ xs: 9 }}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: theme.palette.background.paper,
                      display: 'flex',
                      flexGrow: 0,
                      minHeight: 0,
                    }}
                  >
                    {/* Scrollable Notices Section */}
                    <ClientsTable />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </PageWrapper>
        </Box>
      ) : (
        <UnauthorizedMessage />
      )}
    </>
  );
};
