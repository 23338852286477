import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  OnChangeFn,
  useReactTable,
} from '@tanstack/react-table';
import {
  DataVisibilityType,
  NewClientDataType,
  NewDataType,
} from 'pages/clients/api/get-clients';
import { Dispatch, SetStateAction } from 'react';

export function tableLogic(
  data: NewDataType[],
  // "@ts-expect-error"
  columns: ColumnDef<string>[],
  pageAmount: number,
  getRowCanExpand: (row: Row<NewClientDataType>) => boolean,
  setSorting: OnChangeFn<SortingState>,
  sorting: SortingState,
  setExpanded: OnChangeFn<ExpandedState>,
  expanded: ExpandedState,
  filtering?: string,
  setFiltering: OnChangeFn<string>,
  columnsVisibility: DataVisibilityType,
  onGlobalFilterChange: Dispatch<SetStateAction<string>>,
  globalFilter: string,
) {
  const table = useReactTable({
    getRowId: (row) => row.taskId || row.clientId,
    data,
    columns: columns,
    initialState: {
      pagination: {
        pageSize: pageAmount,
      },
    },
    defaultColumn: {
      maxSize: Number.MAX_SAFE_INTEGER,
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
    },
    getRowCanExpand: getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableColumnResizing: true,
    state: {
      sorting,
      globalFilter,
      expanded,
      columnVisibility: columnsVisibility,
    },

    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: onGlobalFilterChange,

    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
  });

  return table;
}
