import { DateTime } from 'luxon';

//todo-cg: list of possible future params (e.g. date, priority, status, limits etc)

export const TaskPriorityRanking = {
  Urgent: 1,
  High: 2,
  Medium: 3,
  Low: 4,
};

export const sortByPriority = <T extends { priority: string }>(tasks: T[]) => {
  const sortedByPriority = tasks.sort(
    (a, b) =>
      TaskPriorityRanking[a.priority as keyof typeof TaskPriorityRanking] -
      TaskPriorityRanking[b.priority as keyof typeof TaskPriorityRanking],
  );

  return sortedByPriority;
};

//todo-cg: update so that function can take different filtering parameter. e.g. date, priority, status, limits etc
export const filterTodayByPriority = <
  T extends { duedate?: string; dueDate?: string; priority: string },
>(
  tasks: T[],
  isDueDatePreFiltered?: boolean,
) => {
  const todaysTasks = () => {
    if (isDueDatePreFiltered) {
      return tasks;
    } else {
      return tasks.filter((task) => {
        const dueDate = task.duedate ?? task.dueDate;

        if (dueDate && typeof dueDate === 'string') {
          const taskDate = DateTime.fromISO(dueDate).startOf('day');
          return taskDate.equals(DateTime.now().startOf('day'));
        }
        console.warn('Task without due date:', task);
        return false;
      });
    }
  };

  return sortByPriority(todaysTasks());
};
