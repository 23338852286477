import { useMemo } from 'react';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import theme from '../../../../theme/theme';
import { useUserDataContext } from '../../../../react-query-toolkit/state/user-context';
import { Project, User } from '../../../../react-query-toolkit/state/types';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { useTranslateAll } from 'locales/translation-hooks';
import { useSystemNoticesDataContext } from '../../../../react-query-toolkit/state/system-notices-context';
import { useClientDataContext } from '../../../../react-query-toolkit/state/client-context';

export type NoticesCardsNames =
  | 'systemNotices'
  | 'openProject'
  | 'totalClients'
  | 'totalConsultants';

export interface NoticesCards {
  name: NoticesCardsNames;
  IconPathLink: string;
  activeNumber: number | string;
  cardTitle: string;
  cardIcon: JSX.Element;
  pathLink: string;
}

export const useNotificationCardInfo = () => {
  const { translateSystemManagement } = useTranslateAll(['systemManagement']);

  const { handleGetUser, isGetUserLoading, handleGetAllUsers } =
    useUserDataContext();

  // //Get All Consultants
  const rawAllUsersData = handleGetAllUsers() || [];
  const usersOnly: User[] = rawAllUsersData.filter(
    (item: User): item is User => item.typename === 'user',
  );

  const countConsultants = (users: User[]): number => {
    return users.filter(
      (user) => user.typename === 'user' && user.role === 'consultant',
    ).length;
  };
  // //Get All Projects Under Current User
  const rawUserData = handleGetUser();

  const userProjects: Project[] = useMemo(() => {
    return Array.isArray(rawUserData)
      ? findItemsByTypename(rawUserData, 'ref:project')
      : [];
  }, [rawUserData]);

  const filteredOpenProjects = userProjects.filter(
    (project) => project.step !== 'close-project',
  );

  // //Get All Clients
  const { handleGetClients } = useClientDataContext();
  const allClientData = handleGetClients() || [];
  const allClientsCount = allClientData.length;

  // //Get All System Notifications
  const { handleGetSystemNotices } = useSystemNoticesDataContext();
  const notifications = handleGetSystemNotices() || [];
  const notificationCount = notifications.length;

  const activeNumbers = {
    systemNotices: notificationCount,
    openProject: filteredOpenProjects.length.toString(),
    totalClients: allClientsCount,
    totalConsultants: countConsultants(usersOnly),
  };

  if (isGetUserLoading) {
    return { isLoading: true, noticesCards: [] };
  }

  const noticesCards: NoticesCards[] = [
    {
      name: 'systemNotices',
      activeNumber: activeNumbers.systemNotices,
      IconPathLink: '/system-notices',
      cardTitle: translateSystemManagement('titles.systemNotices'),
      cardIcon: (
        <AssignmentLateOutlinedIcon
          sx={{ p: 3, color: theme.palette.secondary.dark }}
        />
      ),
      pathLink: '/system-management/system-notices/',
    },
    {
      name: 'openProject',
      IconPathLink: '/open-projects',
      activeNumber: activeNumbers.openProject,
      cardTitle: translateSystemManagement('titles.openProjects'),
      cardIcon: (
        <AssignmentOutlinedIcon
          sx={{ p: 3, color: theme.palette.secondary.dark }}
        />
      ),
      pathLink: '/my-projects/tab/STARTED',
    },
    {
      name: 'totalClients',
      IconPathLink: '/total-clients',
      activeNumber: activeNumbers.totalClients,
      cardTitle: translateSystemManagement('titles.totalClients'),
      cardIcon: (
        <PeopleIcon sx={{ p: 3, color: theme.palette.secondary.dark }} />
      ),
      pathLink: '/system-management/total-clients/',
    },
    {
      name: 'totalConsultants',
      IconPathLink: '/consultants',
      activeNumber: activeNumbers.totalConsultants,
      cardTitle: translateSystemManagement('titles.consultants'),
      cardIcon: (
        <AssignmentIndOutlinedIcon
          sx={{ p: 3, color: theme.palette.secondary.dark }}
        />
      ),
      pathLink: '/system-management/consultants/',
    },
  ];

  return { isLoading: false, noticesCards };
};
