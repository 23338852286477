import React, { Dispatch, SetStateAction, ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  useTheme,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export type ModalProps = {
  isOpen: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
  title?: string;
  children: ReactNode; // Accepts any content inside the modal
};

export const CustomModal = ({
  isOpen,
  toggleModal,
  title,
  children,
}: ModalProps) => {
  const theme = useTheme();

  function closeModalHandler() {
    toggleModal(false);
    document.body.style.overflow = 'visible';
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeModalHandler}
      sx={{
        marginTop: { xs: theme.spacing(9), md: 'auto' },
        '& .MuiDialog-paper': {
          maxWidth: '630px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        component="div"
        sx={{
          paddingTop: theme.spacing(6),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          {title && (
            <Typography variant={'h6'} color={'primary.dark'}>
              {title}
            </Typography>
          )}
          <IconButton
            onClick={closeModalHandler}
            sx={{
              p: 0,
              color: theme.palette.primary.dark,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ paddingTop: theme.spacing(3) }}>
        {children} {/* Renders whatever is passed inside the modal */}
      </DialogContent>
    </Dialog>
  );
};
