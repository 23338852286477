import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useCreateAllPlMutation,
  useDeletePlAllMutation,
  useGetPlAllQuery,
} from '../services/ghgci-sfe';
import { PlEntities } from '../services/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { SystemNoticesType } from './types';

type notificationContextType = {
  notices: SystemNoticesType[];
  handleGetSystemNotices: () => SystemNoticesType[] | undefined;
  systemNoticesListIsLoading: boolean;
  systemNoticesFetchingError: FetchBaseQueryError | SerializedError | undefined;
  refetchSystemNotices: () => void;

  handleDeleteNotice: (noticeId: string) => void;
  deleteNoticeLoading: boolean;
  deletingNoticeId: string | null;
  deleteNoticeError: FetchBaseQueryError | SerializedError | undefined;
  handleCreateSystemNotice: (
    systemNoticeTitle: string,
    date: string | Date,
    description: string,
  ) => Promise<SystemNoticesType>;
  isCreateSystemNoticeLoading: boolean;
  isCreateSystemNoticeSuccess: boolean;
  resetCreateSystemNoticeQueryValues: () => void;
};
const defaultSystemNoticesContext: notificationContextType = {
  handleGetSystemNotices: () => [], // Provide a default implementation for handleGetSystemNotices
  systemNoticesListIsLoading: false,
  notices: [],
  systemNoticesFetchingError: {},
  deletingNoticeId: null,
  handleDeleteNotice: () => {}, // Provide a default implementation for handleDeleteNotification
  deleteNoticeLoading: false,
  deleteNoticeError: undefined,
  handleCreateSystemNotice: async () => Promise.reject({}),
  isCreateSystemNoticeLoading: false,
  isCreateSystemNoticeSuccess: false,
  resetCreateSystemNoticeQueryValues: () => {},
  refetchSystemNotices: () => {},
};
const DataContext = createContext(defaultSystemNoticesContext);

export const SystemNoticesDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [deletingNoticeId, setDeletingNoticeId] = useState<string | null>(null);

  const {
    data: systemNoticesData,
    isLoading: systemNoticesListIsLoading,
    error: systemNoticesFetchingError,
    refetch: refetchSystemNotices,
  } = useGetPlAllQuery(
    { typename: PlEntities.System },
    {
      // This helps ensure it always refetches after invalidation when cache is empty
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    },
  );

  const [
    createSystemNotice,
    {
      isLoading: isCreateSystemNoticeLoading,
      isSuccess: isCreateSystemNoticeSuccess,
      reset: resetCreateSystemNoticeQueryValues,
    },
  ] = useCreateAllPlMutation();

  const handleCreateSystemNotice = (
    systemNoticeTitle: string,
    date: string | Date,
    description: string,
  ): Promise<any> => {
    return createSystemNotice({
      typename: PlEntities.System,
      requestBody: [
        {
          typename: PlEntities.System,
          title: systemNoticeTitle,
          date: date,
          description: description,
        },
      ],
    }).unwrap();
  };

  const [
    deleteNotice,
    { isLoading: deleteNoticeLoading, error: deleteNoticeError },
  ] = useDeletePlAllMutation();

  const handleDeleteNotice = async (noticeId: string) => {
    setDeletingNoticeId(noticeId);

    try {
      await deleteNotice({
        typename: PlEntities.System,
        id: noticeId,
      }).unwrap();

      // Begin polling for disappearance
      const startTime = Date.now();
      const timeout = 4000; // 4 seconds max wait

      const pollUntilGone = async () => {
        while (Date.now() - startTime < timeout) {
          await new Promise((res) => setTimeout(res, 100)); // check every 100ms
          const stillThere = systemNoticesData?.Items?.some(
            (item: SystemNoticesType) => item.id === noticeId,
          );
          if (!stillThere) return;
        }
        console.warn('Timeout: Notice still present in list after delete.');
      };

      await pollUntilGone();
    } catch (e) {
      console.error('Failed to delete notification:', e);
    } finally {
      setDeletingNoticeId(null);
    }
  };

  function isSoft404(error: unknown): boolean {
    if (
      error &&
      typeof error === 'object' &&
      'status' in error &&
      (error as any).status === 404
    ) {
      const data = (error as any).data;
      return data?.Items?.length === 0;
    }
    return false;
  }

  const notices = React.useMemo(() => {
    if (systemNoticesFetchingError && isSoft404(systemNoticesFetchingError)) {
      return [];
    }

    return systemNoticesData?.Items
      ? sortSystemNoticesTypesByDate(
          systemNoticesData.Items.filter(
            (notice: SystemNoticesType) => notice.title !== '__rtk_fix__',
          ),
        )
      : [];
  }, [systemNoticesData, deletingNoticeId, systemNoticesFetchingError]);

  useEffect(() => {
    if (systemNoticesData?.Items?.length === 0) {
      createSystemNotice({
        typename: PlEntities.System,
        requestBody: [
          {
            typename: PlEntities.System,
            title: '__rtk_fix_dont_delete__',
            description: 'RTK bootstrap cache entry',
            date: new Date().toISOString(),
          },
        ],
      });
    }
  }, [systemNoticesData]);

  const handleGetSystemNotices = () => {
    return notices;
  };

  function sortSystemNoticesTypesByDate(
    notifications: SystemNoticesType[],
  ): SystemNoticesType[] {
    return [...notifications].sort(
      // Clone the array before sorting
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(), // Ascending order
    );
  }

  return (
    <DataContext.Provider
      value={{
        notices,
        handleGetSystemNotices,
        systemNoticesFetchingError,
        systemNoticesListIsLoading,
        refetchSystemNotices,

        handleDeleteNotice,
        deleteNoticeLoading,
        deleteNoticeError,

        handleCreateSystemNotice,
        isCreateSystemNoticeLoading,
        isCreateSystemNoticeSuccess,
        resetCreateSystemNoticeQueryValues,

        deletingNoticeId,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useSystemNoticesDataContext = () => useContext(DataContext);
