{
  "clientAccount": {
    "title": "Informations sur le compte client",
    "placeHolders": {
      "address": "Adresse...",
      "city": "Ville...",
      "client": "Nom du client...",
      "contact": "Nom du contact...",
      "country": "Pays...",
      "email": "E-mail...",
      "phone": "Numéro de téléphone...",
      "region": "Région...",
      "state": "État..."
    },
    "labels": {
      "city": "Ville",
      "client": "Client *",
      "contact": "Nom du contact *",
      "email": "E-mail *",
      "phone": "Téléphone *",
      "state": "État *",
      "address": "Adresse *"
    },
    "errors": {
      "contactNotEmpty": "Le nom du contact ne peut pas être vide.",
      "contactRequired": "Le nom du contact est requis.",
      "emailInvalid": "Adresse e-mail invalide.",
      "emailRequired": "Un e-mail est requis.",
      "nameNotEmpty": "Le nom du client ne peut pas être vide.",
      "nameRequired": "Le nom du client est requis.",
      "phoneInvalid": "Numéro de téléphone invalide.",
      "phoneRequired": "Un numéro de téléphone est requis."
    }
  },
  "totalClients": {
    "clientCreator": {
      "newClientTitle": "",
      "newClientSubTitle": ""
    },
    "allClients": {
      "allClientsTitle": "",
      "confirmDelete": "",
      "cancelDelete": "",
      "deleteTitle": ""
    },
    "errors": {
      "deleteClientError": ""
    }
  },
  "editClient": {
    "title": "Modifier le client",
    "labels": {
      "client": "Client",
      "firstName": "Prénom",
      "lastName": "Nom",
      "phone": "Téléphone",
      "email": "E-mail",
      "closeDate": "Date de clôture",
      "activeDate": "Date d'activité",
      "country": "Pays",
      "stateProvince": "État/Province",
      "municipality": "Municipalité",
      "city": "Ville",
      "streetAddress": "Adresse",
      "postalCode": "Code postal",
      "organizationType": "Type d'organisation",
      "addProjects": "",
      "addConsultants": "",
      "clientPool": ""
    },
    "options": {
      "government": "Gouvernement (Régional ou Local)",
      "company": "Entreprise / Société",
      "industry": "Industrie",
      "nonProfit": "Organisation à but non lucratif / Charité",
      "educationalInstitution": "Établissement d’enseignement",
      "communityGroup": "Groupe communautaire",
      "others": "Autres"
    },
    "buttons": {
      "cancel": "Annuler",
      "save": "Sauvegarder",
      "create": ""
    }
  }
}
