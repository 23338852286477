import { useMemo } from 'react';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import theme from '../../../../theme/theme';
import { useUserDataContext } from '../../../../react-query-toolkit/state/user-context';
import { Project } from '../../../../react-query-toolkit/state/types';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { useTranslateAll } from 'locales/translation-hooks';
import { useSystemNoticesDataContext } from '../../../../react-query-toolkit/state/system-notices-context';

export const useNotificationCardInfo = () => {
  const { translateNotifications } = useTranslateAll(['notifications']);
  const { handleGetUser, isGetUserLoading } = useUserDataContext();
  const rawUserData = handleGetUser();

  const userProjects: Project[] = useMemo(() => {
    return Array.isArray(rawUserData)
      ? findItemsByTypename(rawUserData, 'ref:project')
      : [];
  }, [rawUserData]);

  const filteredReviewProjects = userProjects.filter(
    (project) => project.step === 'report-review',
  );
  const filteredOpenProjects = userProjects.filter(
    (project) => project.step !== 'close-project',
  );

  const { handleGetSystemNotices } = useSystemNoticesDataContext();
  const notifications = handleGetSystemNotices() || [];
  const notificationCount = notifications.length;

  const activeNumbers = {
    systemNotices: notificationCount,
    openProject: filteredOpenProjects.length.toString(),
    reportsReview: filteredReviewProjects.length.toString(),
  };

  if (isGetUserLoading) {
    return { isLoading: true, notificationCards: [] };
  }

  const notificationCards = [
    {
      activeNumber: activeNumbers.systemNotices,
      cardTitle: translateNotifications('titles.systemNotices'),
      cardIcon: (
        <AssignmentLateOutlinedIcon
          sx={{ p: 3, color: theme.palette.secondary.main }}
        />
      ),
      pathLink: '/dashboard/system-notices',
    },
    {
      activeNumber: activeNumbers.openProject,
      cardTitle: translateNotifications('titles.openProject'),
      cardIcon: (
        <AssignmentIcon sx={{ p: 3, color: theme.palette.secondary.main }} />
      ),
      pathLink: '/my-projects/tab/STARTED',
    },
    {
      activeNumber: activeNumbers.reportsReview,
      cardTitle: translateNotifications('titles.reportReview'),
      cardIcon: (
        <ContentPasteSearchIcon sx={{ color: theme.palette.secondary.main }} />
      ),
      pathLink: '/my-projects/tab/REVIEW',
    },
  ];

  return { isLoading: false, notificationCards };
};
