import { User } from '../../../../../react-query-toolkit/state/types';
import { ProjectComment } from '../../../../../react-query-toolkit/state/types';
import {
  MenuStateManagement,
  ReplyManagement,
  CommentEditingManagement,
  DialogManagement,
  BackendManagement,
  TextManagement,
  CommentDataManagement,
} from '../hooks';
import { Comment } from '../project-comments';

export type CommentCardManagementProps =
  | MenuStateManagementProps
  | ReplyManagementProps
  | CommentEditingManagementProps
  | DialogManagementProps
  | BackendManagementProps
  | TextManagementProps
  | CommentDataManagementProps;

export type MenuStateManagementProps = Pick<
  MenuStateManagement,
  'menuState' | 'handleMenuToggle' | 'handleMenuClose'
>;

export type ReplyManagementProps = Pick<
  ReplyManagement,
  | 'replyTo'
  | 'handleReply'
  | 'replyVisibility'
  | 'toggleRepliesVisibility'
  | 'replyInputValue'
  | 'setReplyInputValue'
  | 'areAllRepliesVisible'
  | 'addReply'
  | 'setReplyTo'
>;

export type CommentEditingManagementProps = Pick<
  CommentEditingManagement,
  | 'editingComment'
  | 'handleEditComment'
  | 'handleCancelEdit'
  | 'handleEditChange'
  | 'handleSaveEdit'
>;

export type DialogManagementProps = Pick<
  DialogManagement,
  | 'dialogOpen'
  | 'handleOpenDialog'
  | 'handleCloseDialog'
  | 'handleConfirmDialog'
  | 'selectedComment'
  | 'setSelectedComment'
>;

export type BackendManagementProps = Pick<
  BackendManagement,
  'handleDelete' | 'handleSend'
>;

export type TextManagementProps = Pick<
  TextManagement,
  | 'highlightText'
  | 'filterText'
  | 'setFilterText'
  | 'inputValue'
  | 'setInputValue'
>;

export type CommentDataManagementProps = Pick<
  CommentDataManagement,
  'loadingComments' | 'setLoadingComments' | 'setComments'
>;

export interface CommentCardProps {
  ghgciId: string;
  stepComment: string;
  comment: Comment;
  commentsData: ProjectComment[];
  indentLevel: number;
  rawUserData: User[];
  handleUpdateProjectComment: (
    projectCommentId: string,
    parent: string,
    projectstep: string,
    read: string[],
    tags: string,
    text: string,
    checked: boolean,
  ) => Promise<void>;
  handleCreateProjectComment: (
    id: string,
    parent: string,
    projectstep: string,
    read: string[],
    tags: string,
    text: string,
    checked: boolean,
    author: string,
  ) => Promise<void>;
  handleDeleteComment: (customId: string) => void;
  deleteCommentOrReply: (comments: Comment[], commentId: string) => Comment[];
  menuStateManagement: MenuStateManagementProps;
  commentEditingManagement: CommentEditingManagementProps;
  replyManagement: ReplyManagementProps;
  dialogManagement: DialogManagementProps;
  backendManagement: BackendManagementProps;
  textManagement: TextManagementProps;
  commentDataManagement: CommentDataManagementProps;
}

export const defaultMenuStateManagement: MenuStateManagementProps = {
  menuState: {},
  handleMenuToggle: () => {},
  handleMenuClose: () => {},
};

export const defaultReplyManagement: ReplyManagementProps = {
  replyTo: { id: '', name: '' },
  handleReply: () => {},
  replyVisibility: {},
  toggleRepliesVisibility: () => {},
  replyInputValue: '',
  setReplyInputValue: () => {},
  areAllRepliesVisible: false,
  addReply: () => [],
  setReplyTo: () => {},
};

export const defaultCommentEditingManagement: CommentEditingManagementProps = {
  editingComment: null,
  handleEditComment: () => {},
  handleCancelEdit: () => {},
  handleEditChange: () => {},
  handleSaveEdit: () => Promise.resolve(),
};

export const defaultDialogManagement: DialogManagementProps = {
  dialogOpen: false,
  handleOpenDialog: () => {},
  handleCloseDialog: () => {},
  handleConfirmDialog: () => Promise.resolve(),
  selectedComment: null,
  setSelectedComment: () => {},
};

export const defaultBackendManagement: BackendManagementProps = {
  handleDelete: () => Promise.resolve(),
  handleSend: () => Promise.resolve(),
};

export const defaultTextManagement: TextManagementProps = {
  highlightText: () => '',
  filterText: '',
  setFilterText: () => {},
  inputValue: '',
  setInputValue: () => {},
};

export const defaultCommentDataManagement: CommentDataManagementProps = {
  loadingComments: {},
  setLoadingComments: () => {},
  setComments: () => [],
};

export type CommentModules =
  | 'menuManagement'
  | 'replyManagement'
  | 'commentEditingManagement'
  | 'dialogManagement'
  | 'backendManagement'
  | 'textManagement'
  | 'commentDataManagement';

export type InfoObject = {
  defaultObject: CommentCardManagementProps;
};

export const getDefaultObject = (module: CommentModules) => {
  switch (module) {
    case 'menuManagement':
      return defaultMenuStateManagement;
    case 'replyManagement':
      return defaultReplyManagement;
    case 'commentEditingManagement':
      return defaultCommentEditingManagement;
    case 'dialogManagement':
      return defaultDialogManagement;
    case 'backendManagement':
      return defaultBackendManagement;
    case 'textManagement':
      return defaultTextManagement;
    case 'commentDataManagement':
      return defaultCommentDataManagement;
    default:
      throw new Error(`Unknown comments module: ${module}`);
  }
};
