export * from './colors';

export const defaultFontFamily = 'Open Sans, system-ui, sans-serif';

export const fontSize = {
  h1: '28px',
  h2: '24px',
  h3: '20px',
  h4: '18px',
  textLarge: '16px',
  text: '14px',
  textSm: '12px',
  textXs: '10px',
  nano: '8px',
};

export const fontWeight = {
  lightest: 300,
  lighter: 400,
  normal: 500,
  semiBold: 600,
  bold: 700,
};

export const spacing = {
  xxl: 56,
  xl: 48,
  lg: 42,
  tb: 30,
  md: 24,
  gap: 20,
  sm: 16,
  xs: 8,
};

export const spacingWithPx = {
  xxl: '56px',
  xl: '48px',
  lg: '40px',
  tb: '30px',
  md: '24px',
  gap: '20px',
  sm: '14px',
  xs: '8px',
};

export const defaultTransitionTimeAndStyle = '200ms ease-in-out';
