import { Container } from 'pages/dashboard/styled';
import { TableTopContainer } from '../clients/components/clients-table/styled';
import { spacingWithPx } from 'utils/styles';
import React, { useEffect, useRef, useState } from 'react';
import { TodayTasks } from 'pages/task-manager/components/TodayTasks';
import { TaskModalForm } from 'pages/task-manager/components/popup-modal/task-modal-form';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import {
  HeadingContainer,
  TaskTableDisplayContainer,
} from 'pages/task-manager/components/styled';
import { UpcomingTasks } from 'pages/task-manager/components/upcoming-tasks';
import { OverDueTasks } from 'pages/task-manager/components/overdue-tasks';
import { CompletedTasks } from 'pages/task-manager/components/completed-tasks';
import { TaskSearchBar } from 'pages/task-manager/components/task-search-bar';
import { useTaskDataContext } from '../../react-query-toolkit/state/tasks-context';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import {
  Box,
  IconButton,
  Snackbar,
  SnackbarContent,
  useMediaQuery,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { PageTitle } from 'components/atoms/page-title/page-title';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslateAll } from 'locales/translation-hooks';
import { useLocation } from 'react-router-dom';
import { formatTaskData } from './api/format-task-data';

import { Task } from '../../react-query-toolkit/state/types';

export const TaskManager = () => {
  const { handleGetTasks } = useTaskDataContext();
  const rawTaskData = handleGetTasks() || [];

  const [selectedData, setSelectedData] = useState<NewTaskDataType | null>(
    null,
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const { translateCommon, translateTasks } = useTranslateAll([
    'common',
    'tasks',
  ]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [completedTabActive, setCompletedTabActive] = useState(false);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  ); // Matches xs (mobile)
  const theme = useTheme();
  const [mobileSearchBarActive, setMobileSearchBarActive] = useState(false);
  const [iconClicked, setIconClicked] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      !globalFilter // Check if the search bar has text
    ) {
      setMobileSearchBarActive(false); // Hide search bar
      setIconClicked(false); // Show search icon
    }
  };

  // Add event listener for clicks outside the search bar
  useEffect(() => {
    if (mobileSearchBarActive) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobileSearchBarActive, globalFilter]);

  const location = useLocation();
  const linkedTaskId: string | null = location.state?.taskId;

  const [tasksData] = useState<Task[] | []>(rawTaskData);

  useEffect(() => {
    if (linkedTaskId && tasksData.length > 0) {
      const formattedTaskData = formatTaskData(tasksData);

      const linkedTaskData =
        formattedTaskData.find((task) => task.taskId === linkedTaskId) || null;

      setSelectedData(linkedTaskData);
      setModalIsOpen(true);
      setModalType('update');
    }
  }, [tasksData]);

  return (
    <>
      <PageWrapper>
        <Box justifyItems={'center'} sx={{ width: '100%' }}>
          <Box width={'100%'} maxWidth={theme.breakpoints.values.xxl}>
            <TaskTableDisplayContainer width={'100%'} margin={'0'}>
              <Container
                alignItems={'center'}
                justifyContent={isMobile ? 'space-between' : 'auto'}
                width="100%"
                margin="0"
                flexDirection={'row'}
              >
                <HeadingContainer>
                  <PageTitle
                    title={`${translateCommon('headers.headerTaskManager')}`}
                  />
                </HeadingContainer>
                {isMobile ? (
                  !mobileSearchBarActive ? (
                    <IconButton
                      onClick={() => {
                        setIconClicked(true);
                        setMobileSearchBarActive(!mobileSearchBarActive);
                        // Wait for the TextField to render and then focus
                        setTimeout(() => inputRef.current?.focus(), 0);
                      }}
                      size="small"
                      sx={{ padding: 0 }}
                    >
                      <SearchIcon
                        color="primary"
                        sx={{ fontSize: theme.spacing(9) }}
                      />
                    </IconButton>
                  ) : null
                ) : (
                  <TaskSearchBar
                    inputRef={inputRef}
                    iconClicked={iconClicked}
                    setIconClicked={setIconClicked}
                    mobileSearchBarActive={mobileSearchBarActive}
                    setMobileSearchBarActive={setMobileSearchBarActive}
                    onClick={() => {
                      setGlobalFilter('');
                    }}
                    value={globalFilter ?? ''}
                    onChange={(value) => setGlobalFilter(String(value))}
                    placeholder={translateTasks('searchTaskPlaceholder')}
                  />
                )}
              </Container>
              {iconClicked && isMobile ? (
                <TaskSearchBar
                  inputRef={inputRef}
                  iconClicked={iconClicked}
                  setIconClicked={setIconClicked}
                  mobileSearchBarActive={mobileSearchBarActive}
                  setMobileSearchBarActive={setMobileSearchBarActive}
                  onClick={() => {
                    setGlobalFilter('');
                  }}
                  value={globalFilter ?? ''}
                  onChange={(value) => setGlobalFilter(String(value))}
                  placeholder="Search..."
                />
              ) : null}
            </TaskTableDisplayContainer>
            <Container width="100%" flexDirection="row" margin={`0`}>
              <TaskTableDisplayContainer
                width={'100%'}
                margin={`${spacingWithPx.xxl} 0 0 0`}
              >
                <TableTopContainer></TableTopContainer>
                {completedTabActive ? (
                  <CompletedTasks
                    setModalType={setModalType}
                    setSelectedData={setSelectedData}
                    setModalIsOpen={setModalIsOpen}
                    setCompletedTabActive={setCompletedTabActive}
                    completedTabActive={completedTabActive}
                    setGlobalFilter={setGlobalFilter}
                    globalFilter={globalFilter}
                  />
                ) : (
                  <>
                    <TodayTasks
                      setModalType={setModalType}
                      setSelectedData={setSelectedData}
                      setModalIsOpen={setModalIsOpen}
                      setCompletedTabActive={setCompletedTabActive}
                      completedTabActive={completedTabActive}
                      setGlobalFilter={setGlobalFilter}
                      globalFilter={globalFilter}
                    />
                    <UpcomingTasks
                      setModalType={setModalType}
                      setSelectedData={setSelectedData}
                      setModalIsOpen={setModalIsOpen}
                      setGlobalFilter={setGlobalFilter}
                      globalFilter={globalFilter}
                    />
                    <OverDueTasks
                      setModalIsOpen={setModalIsOpen}
                      setSelectedData={setSelectedData}
                      setModalType={setModalType}
                      setGlobalFilter={setGlobalFilter}
                      globalFilter={globalFilter}
                    />
                  </>
                )}
              </TaskTableDisplayContainer>
            </Container>
          </Box>
        </Box>
      </PageWrapper>
      {selectedData ? (
        <TaskModalForm
          selectedData={selectedData}
          isOpen={modalIsOpen}
          toggleModal={setModalIsOpen}
          modalType={modalType}
        />
      ) : null}
    </>
  );
};
