import React, { RefObject, useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import { useNavAndSideBarContext } from '../state/context';
import { LanguageSelector } from './language-selector';
import useAuth from 'configs/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ArrowDown } from 'icons/arrow-down';
import { LogoContainer } from 'components/nav-and-sidebar/nav-bar/components/logo-container';
import { NotificationsContainer } from 'components/nav-and-sidebar/nav-bar/components/notifications-container';
import { CalendarContainer } from 'components/nav-and-sidebar/nav-bar/components/calendar-container';
import { PageNameContainer } from 'components/nav-and-sidebar/nav-bar/components/page-name-container';
import { NotificationDataProvider } from '../../../react-query-toolkit/state/notifications-context';
import { useTranslateAll } from 'locales/translation-hooks';
import { UserProfilePicture } from './mobile-menu/icons/mobile-user-icon';

type Props = {
  pageName: string;
  profilePicture?: string;
  iconRef: RefObject<HTMLButtonElement>;
};

export const NavBar: React.FC<Props> = ({
  iconRef,
  pageName,
  profilePicture,
}: Props) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { showMenuBar, setShowMenuBar, showSideBar, setShowSideBar } =
    useNavAndSideBarContext();

  const handleBurgerClick = () => {
    setShowSideBar(!showSideBar);
    setShowMenuBar(false);
  };

  const handleMobileUserIconClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent this click from triggering outside click handler
    setShowMenuBar((prev: boolean) => !prev);
    setShowSideBar(false);
  };

  const [givenName, setGivenName] = useState<string | null>(null);

  useEffect(() => {
    if (auth.user) {
      if (auth.user.attributes?.given_name) {
        setGivenName(auth.user.attributes.given_name);
      }
    }
  }, [auth.user?.attributes?.given_name]);

  const { translateCommon } = useTranslateAll('common');

  return (
    <AppBar position="fixed" elevation={0} sx={{ height: '60px' }}>
      <Toolbar>
        {auth.user ? (
          <IconButton
            edge="start"
            onClick={handleBurgerClick}
            sx={{ display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        <PageNameContainer pageName={pageName} />
        <Box
          onClick={handleMobileUserIconClick}
          sx={{ display: { md: 'none' } }}
        >
          {auth.user && (
            <IconButton>
              {profilePicture ? (
                <UserProfilePicture
                  picture={profilePicture}
                  width="24px"
                  height="24px"
                />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
          )}
        </Box>
        {!auth.user && (
          <Box
            width={'100%'}
            justifyContent={'space-between'}
            sx={{
              display: {
                xs: 'flex',
              },
            }}
          >
            <LogoContainer onClick={() => navigate('/')} />
          </Box>
        )}
        <Box
          width={'100%'}
          justifyContent={'space-between'}
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        >
          {auth.user?.attributes && (
            <>
              <NotificationDataProvider>
                <LogoContainer onClick={() => navigate('/')} />
                <Stack
                  spacing={3}
                  direction="row"
                  sx={{ alignItems: 'center' }}
                >
                  <CalendarContainer />
                  <NotificationsContainer />
                  <LanguageSelector />
                  <IconButton
                    ref={iconRef}
                    onClick={handleMobileUserIconClick}
                    disableRipple
                  >
                    {profilePicture ? (
                      <UserProfilePicture
                        picture={profilePicture}
                        width="24px"
                        height="24px"
                      />
                    ) : (
                      <AccountCircle />
                    )}
                    <Box
                      justifyContent={'center'}
                      pl={3}
                      sx={{ display: { lg: 'flex', sm: 'none' } }}
                    >
                      <Typography variant={'body1'}>
                        {translateCommon('greetings.navBarGreeting')},{' '}
                        {givenName}
                      </Typography>
                      <Box display={'flex'} alignItems={'center'} pl={3}>
                        <ArrowDown
                          style={{
                            transition: 'transform 150ms ease-in-out',
                            transform: showMenuBar ? 'rotate(180deg)' : 'none',
                          }}
                        />
                      </Box>
                    </Box>
                  </IconButton>
                </Stack>
              </NotificationDataProvider>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
