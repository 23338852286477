import React, { useEffect, useState } from 'react';
import { Calendar } from './components/calendar';
import { Box, Paper } from '@mui/material';
import { Card } from 'components';
import { List } from './components/list';
import { TaskManager } from './components/task-manager';
import { Container } from './styled';
import { myProjectsList } from './temp-data/my-projects';
import { TaskModalForm } from 'pages/task-manager/components/popup-modal/task-modal-form';
import { NewTaskDataType } from 'pages/task-manager/api/task-type';
import { Clients } from 'pages/dashboard/components/clients';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import Grid from '@mui/material/Grid2';
import NotificationCard from 'pages/dashboard/components/notification-card/notification-card';
import { useNotificationCardInfo } from 'pages/dashboard/components/notification-card/notification-card-info';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { useUserDataContext } from '../../react-query-toolkit/state/user-context';
import { Item, Project } from '../../react-query-toolkit/state/types';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslateAll } from 'locales/translation-hooks';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { CustomModal } from 'components/molecules/custom-modal/custom-modal';
import { SystemNotices } from 'pages/system-management/components/modal-components';

export const Dashboard = () => {
  const [selectedData, setSelectedData] = useState<NewTaskDataType | null>(
    null,
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const { translateCommon } = useTranslateAll(['common']);

  const { handleGetUser, isGetUserLoading } = useUserDataContext();

  // Adjust rawUserData to be Item[] | null
  const rawUserData: Item[] | null = handleGetUser() || null;

  const userProjects: Project[] =
    rawUserData && Array.isArray(rawUserData)
      ? (findItemsByTypename(rawUserData, 'ref:project') as Project[])
          .filter(
            (project) =>
              project.typename === 'project' ||
              project.typename === 'ref:project',
          )
          .filter((project) => project.step !== 'finished')
      : [];

  const { isLoading, notificationCards } = useNotificationCardInfo();
  const theme = useTheme();
  const location = useLocation();
  const [systemNoticeModalIsOpen, setSystemNoticeModalIsOpen] = useState(false);
  useEffect(() => {
    if (location.pathname === '/dashboard/system-notices')
      setSystemNoticeModalIsOpen(true);
    console.log('systemNoticeModalIsOpen', systemNoticeModalIsOpen);
  }, [location]);

  return (
    <>
      {isGetUserLoading && isLoading ? (
        <Box
          display={'flex'}
          width={'100%'}
          height={'30%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <>
          <PageWrapper>
            <Box justifyItems={'center'} sx={{ width: '100%' }}>
              <Box width={'100%'} maxWidth={theme.breakpoints.values.xxl}>
                <Grid container direction="column" alignItems={'center'}>
                  <Grid
                    container
                    spacing={6}
                    maxWidth={{ xs: theme.breakpoints.values.xl, xl: '100%' }}
                  >
                    <Grid size={12}>
                      <Box maxWidth={'570px'} width={'100%'}>
                        <Container width={'100%'} margin={'0'}>
                          <PageTitle
                            title={translateCommon('headers.headerDashboard')}
                          />
                        </Container>
                      </Box>
                    </Grid>

                    <Grid
                      container
                      direction={'column'}
                      spacing={6}
                      size={{ xs: 12, xl: 7 }}
                      rowSpacing={6}
                    >
                      <Grid
                        container
                        size={12}
                        spacing={6}
                        alignItems="stretch"
                      >
                        {notificationCards.map((card, index) => (
                          <Grid size={{ xs: 12, md: 4 }} key={index}>
                            <NotificationCard
                              key={index}
                              cardIcon={card.cardIcon}
                              cardTitle={card.cardTitle}
                              pathLink={card.pathLink}
                              activeNumber={card.activeNumber}
                            />
                          </Grid>
                        ))}
                      </Grid>

                      <Grid container size={{ xs: 12 }}>
                        <Grid size={{ xs: 12, lg: 6, xl: 12 }}>
                          <Box height={'100%'}>
                            <Paper
                              elevation={1}
                              sx={{ width: '100%', height: 'auto', p: 0 }}
                            >
                              {/*Reusage of previous component - no mui*/}
                              <Clients />
                            </Paper>
                          </Box>
                        </Grid>

                        <Grid size={{ xs: 12, lg: 6, xl: 12 }}>
                          <Box>
                            {/*Reusage of previous component - no mui*/}
                            <Paper
                              elevation={1}
                              sx={{ width: '100%', height: 'auto', p: 0 }}
                            >
                              <Card height={'100%'} width={'100%'} noPadding>
                                <List
                                  title={translateCommon(
                                    'headers.headerMyProjects',
                                  )}
                                  listData={myProjectsList(userProjects)}
                                />
                              </Card>
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container spacing={6} size={{ xs: 12, xl: 5 }}>
                      <Grid size={{ xs: 12, lg: 6, xl: 12 }}>
                        <Paper
                          elevation={1}
                          sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            p: 0,
                          }}
                        >
                          <Calendar />
                        </Paper>
                      </Grid>
                      <Grid size={{ xs: 12, lg: 6, xl: 12 }}>
                        <Box>
                          <Paper
                            elevation={1}
                            sx={{
                              flexGrow: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              p: 0,
                            }}
                          >
                            {/*Reusage of previous component - no mui*/}
                            <TaskManager
                              setModalIsOpen={setModalIsOpen}
                              setModalType={setModalType}
                              setSelectedData={setSelectedData}
                            />
                          </Paper>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {selectedData ? (
                  <TaskModalForm
                    selectedData={selectedData}
                    isOpen={modalIsOpen}
                    toggleModal={setModalIsOpen}
                    modalType={modalType}
                  />
                ) : null}
                <CustomModal
                  isOpen={systemNoticeModalIsOpen}
                  toggleModal={setSystemNoticeModalIsOpen}
                  title={'System Notices'}
                >
                  <SystemNotices />
                </CustomModal>
              </Box>
            </Box>
          </PageWrapper>
        </>
      )}
    </>
  );
};
