import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import env from '../../env.json';

type ConfigType = {
  '/contentful/accessToken': string;
  '/contentful/spaceId': string;
  '/ghgci/app/bucket': string;
  '/ghgci/app/identitypool': string;
  '/ghgci/app/userpool': string;
  '/ghgci/app/userpoolclient': string;
  build: string;
};

type ParametersContextType = {
  contentFullAccessToken: string;
  contentFullSpaceId: string;
  ghgciWebSiteBucket: string;
  ghgciIdentityPool: string;
  ghgciUserPool: string;
  ghgciUserPoolClient: string;
  build: string;
};

const defaultContext: ParametersContextType = {
  contentFullAccessToken: '',
  contentFullSpaceId: '',
  ghgciWebSiteBucket: '',
  ghgciIdentityPool: '',
  ghgciUserPool: '',
  ghgciUserPoolClient: '',
  build: '',
};

const ParametersContext = createContext<ParametersContextType>(defaultContext);

export const ParametersProvider = ({ children }: { children: ReactNode }) => {
  const [parameters, setParameters] =
    useState<ParametersContextType>(defaultContext);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const config: ConfigType = env;
        if (
          !(
            config['/contentful/accessToken'] &&
            config['/contentful/spaceId'] &&
            config['/ghgci/app/bucket'] &&
            config['/ghgci/app/identitypool'] &&
            config['/ghgci/app/userpool'] &&
            config['/ghgci/app/userpoolclient'] &&
            config['build']
          )
        ) {
          throw new Error('Missing required configuration values');
        }

        setParameters({
          contentFullAccessToken: config['/contentful/accessToken'],
          contentFullSpaceId: config['/contentful/spaceId'],
          ghgciWebSiteBucket: config['/ghgci/app/bucket'],
          ghgciIdentityPool: config['/ghgci/app/identitypool'],
          ghgciUserPool: config['/ghgci/app/userpool'],
          ghgciUserPoolClient: config['/ghgci/app/userpoolclient'],
          build: config['build'],
        });
      } catch (error) {
        console.error('Error loading parameters', error);
        // You can also set a default or empty context in case of failure
      } finally {
        setLoading(false);
      }
    };

    loadConfig();
  }, []);

  if (loading) {
    //todo-cg: implement better loading indicator
    return <div>Loading parameters...</div>;
  }

  return (
    <ParametersContext.Provider value={parameters}>
      {children}
    </ParametersContext.Provider>
  );
};

export const useParametersContext = () => useContext(ParametersContext);
