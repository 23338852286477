import SystemNoticeCard from 'pages/system-notices/components/system-notice-card';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSystemNoticesDataContext } from '../../../../../react-query-toolkit/state/system-notices-context';
import ClientsTable from 'pages/total-clients/components/clients-table';
import { getWindowDimensions } from 'utils/window-utils';
import { LG, MD } from 'utils/breakpoints';
import {
  ClientSubComponentHeaders,
  DataVisibilityType,
  NewClientDataType,
} from 'pages/clients/api/get-clients';
import { useTranslateAll } from 'locales/translation-hooks';
import { useClientDataContext } from '../../../../../react-query-toolkit/state/client-context';
import { useNavigate } from 'react-router-dom';
import { Cell } from '@tanstack/react-table';
import { ActiveIcon } from 'icons';
import { isFirstDatePastSecondDate } from 'utils/date-validations';
import { CopyToClipboardTruncated } from 'utils/copy-to-clipboard-truncated';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { ProfileImageIcon } from 'pages/clients/components/clients-table/styled';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useUserDataContext } from '../../../../../react-query-toolkit/state/user-context';
import { Item } from '../../../../../react-query-toolkit/state/types';
import { getClientIdsFromProjects } from 'utils/get-client-ids-from-projects';
import { findItemsByTypename } from 'utils/filterItemsbyTypeName';
import { useTheme } from '@mui/material/styles';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { ClientSearchBar } from 'pages/total-clients/components/client-search-bar/client-search-bar';
import { DisplayTable } from 'pages/clients/components/clients-table';
import { RenderSubComponent } from 'pages/clients/components/clients-table/render-sub-component';
import { clientHeadersTranslationMappings } from 'locales/mappings';
import { ClientModalForm } from 'pages/clients/components/popUp-Modal/client-modal-form';

export function TotalClients() {
  const [filtering, setFiltering] = useState({
    value: '',
    clientFilter: 'Name', // Default to searching by Name
    containsFilter: 'Contains', // Default to "Contains"
  });
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  const [isMobileWidth, setIsMobileWidth] = useState<boolean>(
    windowSize.width < MD,
  );
  const [setIsDesktopWidth] = useState<boolean>(windowSize.width > LG);
  const [columnsVisibility, setColumnsVisibility] =
    useState<DataVisibilityType>({
      contactName: isMobileWidth,
      projectConsultants: !isMobileWidth,
    });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<NewClientDataType>();
  const { translateCommon, translateClients, translateProjects } =
    useTranslateAll(['common', 'clients', 'projects']);

  // Fetch client data
  const {
    handleGetClients,
    clientListIsLoading,
    clientsFetchingError,
    handleDeleteClient,
    deleteClientLoading,
    deleteClientError,
  } = useClientDataContext();
  const rawClientData = handleGetClients() || [];

  //TODO - delete this function if this is not going to be use to find consultants
  //  const findUserInfoById = (id: string, users: User[]): UserInfo => {
  //   if (!Array.isArray(users)) {
  //     console.warn('Expected an array, but got:', users);
  //     return null;
  //   }
  //
  //   const user = users.find((user) => user.id === id);
  //
  //   if (!user) {
  //     console.warn(`User with ID ${id} not found.`);
  //   }
  //
  //   return user
  //     ? {
  //       firstname: user.firstname || 'Unknown',
  //       lastname: user.lastname || 'User',
  //       email: user.email || 'No Email',
  //     }
  //     : null;
  // };

  const subComponentHeaders: ClientSubComponentHeaders = {
    clientId: 'ID',
    clientName: 'Name',
    clientPhone: 'Phone',
    clientEmail: 'Email',
    clientLocation: 'City',
  };
  const [deletingClientId, setDeletingClientId] = useState<string | null>(null);
  const handleConfirmDeleteOpen = () => {
    setOpenConfirmDialog(true);
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleConfirmDeleteClose = () => {
    setOpenConfirmDialog(false);
    setDeletingClientId(null);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    if (deletingClientId) handleDeleteClient(deletingClientId);
  };

  const navigate = useNavigate();
  function createColumnInfo() {
    return [
      {
        header: `${translateCommon('headers.headerName')}`,
        accessorKey: 'clientName',
        size: 100,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '3px', alignItems: 'center' }}>
              <ActiveIcon
                active={isFirstDatePastSecondDate(
                  row.original.closeDate,
                  row.original.activeDate,
                )}
              />
            </div>
            <CopyToClipboardTruncated copyText={row.original.clientName}>
              {row.original.clientName}
            </CopyToClipboardTruncated>
          </div>
        ),
      },
      {
        header: `${translateCommon('headers.headerProjects')}`,
        accessorKey: 'clientId',
        enableSorting: false,
        size: 1,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <div>
            <Tooltip title={translateProjects('seeAllProjectsForClientTitle')}>
              <button
                onClick={() =>
                  navigate(
                    `/my-projects/client-name/${row.original.clientName}`,
                  )
                }
                {...{
                  style: { cursor: 'pointer' },
                }}
              >
                <AssignmentIcon sx={{ color: 'secondary.dark' }} />
              </button>
            </Tooltip>
          </div>
        ),
      },
      {
        header: `Consultants`,
        accessorKey: 'projectConsultants',
        enableSorting: false,
        size: 1,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <>
            {/*<ConsultantContainer onClick={row.getToggleExpandedHandler()}>*/}
            {/*{row.original.assignees.map((assigneeId: string, idx: number) => {*/}
            {/*  const userInfo = findUserInfoById(assigneeId, rawUserData);*/}

            {/*  if (!userInfo) {*/}
            {/*    return null; // Skip rendering if no user information is found*/}
            {/*  }*/}

            {/*  return (*/}
            {/*    <ProfileImage*/}
            {/*      key={idx}*/}
            {/*      textToDisplayWhenClicked={translateCommon(*/}
            {/*        'messages.EmailCopiedMessage',*/}
            {/*      )}*/}
            {/*      moveValue={`${idx}0px`}*/}
            {/*      profileName={userInfo.firstname}*/}
            {/*      profileEmail={userInfo.email}*/}
            {/*      imageIdx={`${idx + 1}`}*/}
            {/*    />*/}
            {/*  );*/}
            {/*})}*/}
            <Box>
              <ProfileImageIcon
                move={'1px'}
                color={theme.palette.primary.main}
                zIndex={'10000'}
              />
            </Box>
            {/*</ConsultantContainer>*/}
          </>
        ),
      },
      {
        header: `${translateCommon('headers.headerEdit')}`,
        accessorKey: 'action',
        enableSorting: false,
        maxSize: 10,
        cell: ({ row }: Cell<NewClientDataType, NewClientDataType>) => (
          <Box display={'flex'}>
            <button
              onClick={() => {
                setSelectedData(row.original);
                setModalIsOpen(true);
              }}
            >
              <EditIcon sx={{ color: 'secondary.dark', cursor: 'pointer' }} />
            </button>
            <button
              onClick={() => {
                setSelectedData(row.original);
                setModalIsOpen(true);
              }}
            >
              <ArchiveOutlinedIcon
                sx={{ color: 'secondary.dark', cursor: 'pointer' }}
              />
            </button>
            {deletingClientId === row.original.clientId ? (
              <CircularProgress size={24} />
            ) : (
              <button
                onClick={() => {
                  handleConfirmDeleteOpen();
                  setSelectedData(row.original);
                  setDeletingClientId(row.original.clientId);
                }}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{ color: 'secondary.dark', cursor: 'pointer' }}
                />
              </button>
            )}
          </Box>
        ),
      },
    ];
  }

  // Column Info State
  const columnInfo = React.useMemo(
    () => createColumnInfo(),
    [deleteClientLoading, deletingClientId],
  );

  // Fetch user data
  const { handleGetUser } = useUserDataContext();
  const rawUserData: Item[] | null = handleGetUser();

  useEffect(() => {
    console.log('rawClientData', rawClientData);
  }, [rawClientData]);

  // Extract client IDs from user projects if user data exists
  const userClientsIds =
    rawUserData && Array.isArray(rawUserData)
      ? getClientIdsFromProjects(
          findItemsByTypename(rawUserData, 'ref:project'),
        )
      : [];

  // Helper function to filter items by IDs
  function getItemsByIds(data: NewClientDataType[], ids: (string | null)[]) {
    return data.filter((item) => ids.includes(item.clientId));
  }

  // Determine final client data to be displayed
  let clientData: NewClientDataType[];
  if (rawClientData) {
    clientData = rawClientData;
  }

  // Apply filtering to `clientsToBeDisplayed`
  const filteredClients = rawClientData.filter((client) => {
    if (!filtering.value) return true; // Show all if no search input

    const searchTerm = filtering.value.toLowerCase();
    const { clientFilter, containsFilter } = filtering;

    // Get the field to search
    let fieldToSearch = '';
    if (clientFilter === 'Name')
      fieldToSearch = client.clientName.toLowerCase();
    if (clientFilter === 'Email') fieldToSearch = client.email.toLowerCase();
    if (clientFilter === 'Phone') fieldToSearch = client.phone;

    if (!fieldToSearch) return false;

    // Apply filtering logic
    if (containsFilter === 'Contains')
      return fieldToSearch.includes(searchTerm);
    if (containsFilter === 'Start with')
      return fieldToSearch.startsWith(searchTerm);
    if (containsFilter === 'Ends with')
      return fieldToSearch.endsWith(searchTerm);

    return true;
  });

  const handleResize = () => {
    setWindowSize(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsMobileWidth(windowSize.width <= 768);

    setColumnsVisibility({
      contactName: !isMobileWidth,
      projectConsultants: !isMobileWidth,
    });
    return () => {};
  }, [windowSize.width]);

  const theme = useTheme();

  const [openDeleteErrorSnackbar, setOpenDeleteErrorSnackbar] = useState(false);
  useEffect(() => {
    if (deleteClientError) {
      setOpenDeleteErrorSnackbar(true);
    }
  }, [deleteClientError]);

  const isFetchBaseQueryError = (
    error: unknown,
  ): error is { status: number | string; data?: any } => {
    return typeof error === 'object' && error !== null && 'status' in error;
  };

  useEffect(() => {
    if (deleteClientError) setDeletingClientId(null);
  }, [deleteClientError]);

  return (
    <>
      <Box justifyItems={'center'} sx={{ width: '100%' }}>
        <Box width={'100%'} maxWidth={theme.breakpoints.values.xxl}>
          <ClientSearchBar onSearch={setFiltering} />
          <DisplayTable
            filtering={filtering.value}
            setFiltering={(newValue) =>
              setFiltering((prev) => ({
                ...prev,
                value:
                  typeof newValue === 'string' ? newValue : String(newValue), // Ensure it's a string
              }))
            }
            data={filteredClients}
            columns={columnInfo}
            getRowCanExpand={() => true}
            columnsVisibility={columnsVisibility}
            showFooter
            loadingItem={
              clientListIsLoading ? (
                <Box
                  display={'flex'}
                  width={'100%'}
                  height={'30%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <CircularProgress size={50} sx={{ paddingY: 4 }} />
                </Box>
              ) : clientsFetchingError ? (
                'There was an error'
              ) : undefined
            }
            RenderSubComponent={RenderSubComponent}
            subComponentHeaders={subComponentHeaders}
            translationKeysMapping={clientHeadersTranslationMappings}
            headersTranslationFunction={translateCommon}
          />
        </Box>
      </Box>
      {selectedData ? (
        <ClientModalForm
          data={clientData!}
          selectedData={selectedData}
          isOpen={modalIsOpen}
          toggleModal={setModalIsOpen}
        />
      ) : null}

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        sx={{ zIndex: '99999!important' }}
      >
        <DialogTitle id="confirm-dialog-title">
          <Typography color="primary.dark" variant="h6" textAlign="center">
            {translateClients('totalClients.allClients.deleteTitle')}
          </Typography>
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedData(undefined);
              setDeletingClientId(null);
              handleConfirmDeleteClose();
            }}
            color="secondary"
          >
            {translateClients('totalClients.allClients.cancelDelete')}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleConfirmDelete()}
            color="primary"
          >
            {translateClients('totalClients.allClients.confirmDelete')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openDeleteErrorSnackbar}
        onClose={() => setOpenDeleteErrorSnackbar(false)}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: isMobileWidth ? 'center' : 'right',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={() => setOpenDeleteErrorSnackbar(false)}
          sx={{ width: '100%' }}
        >
          <AlertTitle>
            {translateClients('totalClients.errors.deleteClientError')}
          </AlertTitle>
          {isFetchBaseQueryError(deleteClientError) &&
          typeof deleteClientError.data === 'object' &&
          deleteClientError.data !== null &&
          'message' in deleteClientError.data
            ? (deleteClientError.data as { message?: string })?.message
            : translateCommon('totalClients.errors.deleteClientError')}
        </Alert>
      </Snackbar>
    </>
  );
}
