import React, { useEffect, useState } from 'react';
import NotificationCard from 'pages/notifications/components/notifications-card';
import { PageWrapper } from 'components/molecules/page-wrapper/page-wrapper';
import { useNotificationDataContext } from '../../react-query-toolkit/state/notifications-context';
import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  Slide,
  Stack,
  Tooltip,
} from '@mui/material';
import { PageTitle } from 'components/atoms/page-title/page-title';
import { useTranslateAll } from 'locales/translation-hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { useWebSocketContext } from '../../react-query-toolkit/state/websocket-context';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { ActionFeedback } from '../task-manager/components/action-feedback';

export const Notifications: React.FC = () => {
  const { translateCommon, translateNotifications } = useTranslateAll([
    'common',
    'notifications',
  ]);
  const {
    handleGetNotifications,
    notificationsFetchingError,
    notificationsListIsLoading,
    handleDeleteNotification,
    deleteNotificationLoading,

    deleteNotificationIsError,
    resetDeleteNotificationQueryValues,
  } = useNotificationDataContext();

  const notifications = handleGetNotifications() || []; //todo-cg:reinstate once the backend throws an event in case of failure to delete notifications

  const { lastMessageData } = useWebSocketContext();
  useEffect(() => {
    console.log(lastMessageData);
  }, [lastMessageData]);

  const theme = useTheme();

  const getNotificationsIdList = () => {
    return notifications.map((notification) => notification.id);
  };

  const [isDeleteAllNotificationsLoading, setIsDeleteAllNotificationsLoading] =
    useState(false);

  const [deleteAllNotificationsError, setDeleteAllNotificationsError] =
    useState(false);

  const handleDeleteAllNotifications = async (
    notificationsIdList: string[],
  ) => {
    if (!notificationsIdList.length) {
      return;
    }
    setDeleteAllNotificationsError(false);

    try {
      setIsDeleteAllNotificationsLoading(true);

      await Promise.all(
        notificationsIdList.map((notificationId) =>
          handleDeleteNotification(notificationId),
        ),
      );

      setIsDeleteAllNotificationsLoading(false);
    } catch (e) {
      setIsDeleteAllNotificationsLoading(false);
      console.error('Error deleting all notifications:', e);
      resetDeleteNotificationQueryValues();
      setDeleteAllNotificationsError(true);
    }
  };

  const dismissErrorAlert = () => {
    setDeleteAllNotificationsError(false);
    resetDeleteNotificationQueryValues();
  };

  const notificationsDeletionErrorText = deleteAllNotificationsError
    ? 'feedBack.alertMessages.errors.deleteAllNotifications'
    : 'feedBack.alertMessages.errors.deleteNotification';

  const deletionErrorAlert = (
    <Slide in={true} direction="left">
      <Alert
        severity="error"
        variant="filled"
        onClose={() => {
          dismissErrorAlert();
        }}
        slotProps={{ icon: { sx: { color: 'common.white' } } }}
      >
        <AlertTitle>{translateCommon('feedBack.alerts.error')}</AlertTitle>
        {translateNotifications(notificationsDeletionErrorText)}
      </Alert>
    </Slide>
  );

  return (
    <PageWrapper>
      <Box justifyItems={'center'} sx={{ width: '100%' }}>
        <Box width={'100%'} maxWidth={theme.breakpoints.values.xxl}>
          <Stack spacing={7}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <PageTitle
                title={translateCommon('headers.headerNotifications')}
                py={0}
              />
              <Tooltip title="Delete all notifications">
                <IconButton
                  onClick={() =>
                    handleDeleteAllNotifications(getNotificationsIdList())
                  }
                  aria-label="delete-all-notifications"
                  sx={{
                    p: 2,
                    color: 'neutral.main',
                  }}
                >
                  {!notifications.length ? null : isDeleteAllNotificationsLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Close
                      sx={{
                        width: { xs: '30px' },
                        height: { xs: '30px' },
                        color: 'secondary.dark',
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack spacing={{ xs: 7, xxl: 5 }}>
              {notificationsListIsLoading ? (
                <Box
                  display={'flex'}
                  width={'100%'}
                  height={'30%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <CircularProgress size={50} />
                </Box>
              ) : notificationsFetchingError ? (
                <Box>Error loading notifications</Box>
              ) : (
                <>
                  {/* Render notifications */}
                  {notifications.map((notification) => (
                    <NotificationCard
                      key={notification.id}
                      notification={notification}
                    />
                  ))}
                </>
              )}
              {deleteNotificationLoading && <Box>Deleting notification...</Box>}

              <ActionFeedback
                autoHideDuration={7000}
                onClose={dismissErrorAlert}
                isOpen={
                  deleteNotificationIsError || deleteAllNotificationsError
                }
                message={translateNotifications(notificationsDeletionErrorText)}
                content={deletionErrorAlert}
              />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </PageWrapper>
  );
};
