import {
  Box,
  Card,
  Stack,
  Avatar,
  Icon,
  Typography,
  IconButton,
  CircularProgress,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  TextField,
  Button,
  Divider,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { DateTime } from 'luxon';
import theme from '../../../../theme/theme';
import { findUserById } from '../../../../utils/find-user-by-id';
import { useTranslation } from 'react-i18next';
import { useTranslateAll } from '../../../../locales/translation-hooks';
import { forwardRef } from 'react';
import { CommentCardProps } from './types/comment-card-props-types';

export const CommentCard = forwardRef(function CommentCard(
  {
    ghgciId,
    stepComment,
    comment,
    commentsData,
    indentLevel,
    rawUserData,
    handleUpdateProjectComment,
    handleDeleteComment,
    handleCreateProjectComment,
    menuStateManagement,
    replyManagement,
    commentEditingManagement,
    dialogManagement,
    backendManagement,
    textManagement,
    commentDataManagement,
    deleteCommentOrReply,
  }: CommentCardProps,
  inputRef,
) {
  const {
    replyTo,
    handleReply,
    replyVisibility,
    toggleRepliesVisibility,
    replyInputValue,
    setReplyInputValue,
    areAllRepliesVisible,
    addReply,
    setReplyTo,
  } = replyManagement;

  const { menuState, handleMenuToggle, handleMenuClose } = menuStateManagement;

  const {
    editingComment,
    handleEditComment,
    handleCancelEdit,
    handleEditChange,
    handleSaveEdit,
  } = commentEditingManagement;
  const { handleOpenDialog } = dialogManagement;
  const { handleSend, handleDelete } = backendManagement;
  const { highlightText, inputValue, setInputValue } = textManagement;

  const { loadingComments, setComments } = commentDataManagement;

  const user =
    rawUserData.length > 0 ? findUserById(comment.author, rawUserData) : null;
  const userName = user ? user.firstname : 'Unknown User';
  const repliesCount = comment.replies.length;
  const areRepliesVisible = replyVisibility[comment.id] || false;
  const { translateCommon, translateProjects } = useTranslateAll([
    'projects',
    'common',
  ]);
  const { i18n } = useTranslation();

  const formattedDate = DateTime.fromISO(comment.creationDate)
    .setLocale(i18n.language)
    .toLocaleString(DateTime.DATE_MED);

  const isMenuOpen = Boolean(menuState[comment.id]);
  const isEditing = editingComment?.id === comment.id;

  return (
    <Box key={comment.id} ml={indentLevel * 2}>
      <Card
        elevation={comment.parent ? 0 : 3} // Set elevation to 0 for replies and 3 for comments
        sx={{
          my: 4,
          mx: 2,
          paddingX: 5,
          paddingY: 4,
          // bgcolor:"yellow"
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1} mb={1}>
          <Avatar sx={{ width: theme.spacing(6), height: theme.spacing(6) }}>
            <Icon>{userName}</Icon>
          </Avatar>
          <Typography color="primary.dark" variant="subtitle2">
            {comment.isMine ? translateProjects('me') : userName}
          </Typography>
          <Typography color="secondary.dark" variant="body3">
            {formattedDate}
          </Typography>
          <Box flexGrow={1} />
          <IconButton
            onClick={() =>
              handleOpenDialog(comment.id, !!comment.parent, comment.checked)
            }
            sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
          >
            {loadingComments[comment.id] ? (
              <Box>
                <CircularProgress size={24} />
              </Box>
            ) : comment.checked ? (
              <CircleIcon
                color="primary"
                sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
              />
            ) : (
              <CheckCircleOutlineIcon
                color="primary"
                sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
              />
            )}
          </IconButton>
          {comment.isMine && (
            <IconButton
              id={`menu-button-${comment.id}`}
              aria-controls={isMenuOpen ? `menu-${comment.id}` : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? 'true' : undefined}
              onClick={(e) => handleMenuToggle(comment.id, e.currentTarget)}
              sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
            >
              <MoreVertIcon
                color="primary"
                sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
              />
            </IconButton>
          )}
          <Popper
            open={isMenuOpen}
            anchorEl={menuState[comment.id]}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'left top',
                }}
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={() => handleMenuClose(comment.id)}
                  >
                    <MenuList
                      id={`menu-${comment.id}`}
                      aria-labelledby={`menu-button-${comment.id}`}
                      sx={{
                        backgroundColor: 'secondary.dark',
                        borderRadius: theme.spacing(2),
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleEditComment(comment.id, comment.text);
                          handleMenuClose(comment.id);
                        }}
                        sx={{ typography: 'body2', color: 'bg.box' }}
                      >
                        {translateProjects('actions.edit')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDelete(
                            comment.id,
                            commentsData,
                            setComments,
                            handleDeleteComment,
                          );
                          handleMenuClose(comment.id);
                        }}
                        sx={{ typography: 'body2', color: 'bg.box' }}
                      >
                        {translateProjects('actions.delete')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Stack>

        {isEditing ? (
          <Box>
            <TextField
              fullWidth
              variant="outlined"
              value={editingComment.text}
              onChange={handleEditChange}
              multiline
              rows={2}
              autoFocus
            />
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Button onClick={handleCancelEdit} color="secondary">
                {translateCommon('buttons.cancelButton')}
              </Button>
              <Button
                onClick={() =>
                  handleSaveEdit(
                    commentsData,
                    handleUpdateProjectComment,
                    setComments,
                  )
                }
                color="primary"
                variant="contained"
                sx={{ ml: 1 }}
              >
                {translateCommon('buttons.saveButton')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography variant="body3" color="text.primary" mb={1}>
            {highlightText(comment.text)}
          </Typography>
        )}

        <Box display="flex" justifyContent="space-between" alignItems="center">
          {!comment.parent && (
            <Box
              display="flex"
              alignItems="center"
              onClick={() => handleReply(comment.id, userName)}
            >
              <IconButton>
                {replyTo.id === comment.id ? (
                  <CloseIcon
                    color="primary"
                    sx={{ width: theme.spacing(4), height: 'auto' }}
                  />
                ) : (
                  <ReplyIcon
                    color="primary"
                    sx={{ width: theme.spacing(4), height: 'auto' }}
                  />
                )}
              </IconButton>
              <Typography color="primary.light" variant="link">
                {replyTo.id === comment.id
                  ? translateProjects('actions.close')
                  : translateProjects('actions.reply')}
              </Typography>
            </Box>
          )}
          {repliesCount > 0 && (
            <Typography
              variant="link"
              color="primary.light"
              sx={{ cursor: 'pointer', mt: 1 }}
              onClick={() => toggleRepliesVisibility(comment.id)}
            >
              {areRepliesVisible
                ? translateProjects('actions.hideReplies')
                : translateProjects('actions.showReplies')}
              {/* : `${repliesCount} replies - Last reply today at ${new Date().toLocaleTimeString()}`
              } */}
            </Typography>
          )}
        </Box>

        {/* Render reply TextField if this comment is being replied to */}
        {replyTo.id === comment.id && (
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type a Reply..."
            value={replyInputValue}
            onChange={(e) => setReplyInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSend(
                  ghgciId,
                  stepComment,
                  replyTo,
                  replyInputValue,
                  inputValue,
                  setInputValue,
                  setComments,
                  addReply,
                  setReplyTo,
                  setReplyInputValue,
                  handleCreateProjectComment,
                );
              } else if (e.key === 'Enter' && e.shiftKey) {
                setReplyInputValue((prevValue) => prevValue + '\n');
              }
            }}
            inputRef={inputRef}
            sx={{
              mt: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
                backgroundColor: 'bg.main',
                height: 'auto',
                padding: '0px 10px 0px 30px',
              },
              '& .MuiInputBase-input': {
                ...theme.typography.body3,
                padding: '8px',
                whiteSpace: 'pre-wrap',
              },
            }}
            multiline
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    handleSend(
                      ghgciId,
                      stepComment,
                      replyTo,
                      replyInputValue,
                      inputValue,
                      setInputValue,
                      setComments,
                      addReply,
                      setReplyTo,
                      setReplyInputValue,
                      handleCreateProjectComment,
                    );
                    setReplyInputValue(''); // Clear reply input after sending
                  }}
                >
                  <SendIcon sx={{ color: '#4682b4' }} />
                </IconButton>
              ),
            }}
          />
        )}

        {(areRepliesVisible || areAllRepliesVisible) && (
          <>
            {comment.replies.map((reply) => (
              <Box
                sx={{
                  borderTop: `1px solid ${theme.palette.notificationBorderShadow.main}`,
                }}
                key={reply.id}
              >
                <CommentCard
                  ghgciId={ghgciId}
                  stepComment={stepComment}
                  comment={reply}
                  commentsData={commentsData}
                  indentLevel={indentLevel + 1}
                  rawUserData={rawUserData}
                  handleUpdateProjectComment={handleUpdateProjectComment}
                  handleDeleteComment={handleDeleteComment}
                  handleCreateProjectComment={handleCreateProjectComment}
                  menuStateManagement={menuStateManagement}
                  commentEditingManagement={commentEditingManagement}
                  replyManagement={replyManagement}
                  dialogManagement={dialogManagement}
                  backendManagement={backendManagement}
                  textManagement={textManagement}
                  commentDataManagement={commentDataManagement}
                  deleteCommentOrReply={deleteCommentOrReply}
                />
              </Box>
            ))}
          </>
        )}
        <Divider sx={{ my: 2 }} />
      </Card>
    </Box>
  );
});
