import SystemNoticeCard from 'pages/system-notices/components/system-notice-card';
import React from 'react';
import { Box } from '@mui/material';
import { useSystemNoticesDataContext } from '../../../../../react-query-toolkit/state/system-notices-context';

export function OpenProjects() {
  const {
    handleGetSystemNotices,
    systemNoticesFetchingError,
    systemNoticesListIsLoading,
  } = useSystemNoticesDataContext();

  const notices = handleGetSystemNotices() || [];
  return (
    <>
      isLoading?
      {notices.map((notice) => (
        <SystemNoticeCard key={notice.id} notice={notice} />
      ))}
      <Box></Box>
    </>
  );
}
