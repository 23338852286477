import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SystemNoticesType } from '../../../react-query-toolkit/state/types';
import { useTranslateAll } from 'locales/translation-hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { useSystemNoticesDataContext } from '../../../react-query-toolkit/state/system-notices-context';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import useHasSystemManagementAccess from 'utils/hooks/useHasSystemManagementAccess';
import UnauthorizedMessage from 'components/unauthorized-message/unauthorized-message';

interface SystemNoticeCardProps {
  notice: SystemNoticesType;
  handleRequestDelete: (notice: SystemNoticesType) => void;
  isDeleting?: boolean;
}
const SystemNoticeCard: React.FC<SystemNoticeCardProps> = ({
  notice,
  handleRequestDelete,
  isDeleting,
}) => {
  const { translateCommon } = useTranslateAll(['common']);
  const { deleteNoticeError, deletingNoticeId } = useSystemNoticesDataContext();

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const handleCloseDialog = () => setOpenErrorDialog(false);

  // Open error dialog when deleteNoticeError occurs for this notice
  useEffect(() => {
    if (deleteNoticeError && deletingNoticeId === notice.id) {
      setOpenErrorDialog(true);
    }
  }, [deleteNoticeError, deletingNoticeId, notice.id]);

  const theme = useTheme();
  const hasSystemManagementAccess = useHasSystemManagementAccess();
  return (
    <>
      <Box
        sx={{
          borderRadius: 1,
          border: `1px solid ${theme.palette.primary.dark}`,
          py: 3,
          px: 5,
          maxWidth: { xs: '980px' },
        }}
      >
        <Grid container columnSpacing={5}>
          <Grid size={{ xs: 10, md: 9 }}>
            <Typography
              color={'primary.dark'}
              variant="subtitle1"
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {notice.title}
            </Typography>
            <Typography
              variant="body3"
              color="textColor.main"
              sx={{ display: 'inline-flex' }}
            >
              {notice.description}
            </Typography>
          </Grid>
          <Grid size={'grow'} display={{ xs: 'auto', md: 'none' }}>
            {hasSystemManagementAccess ? (
              <IconButton
                onClick={() => handleRequestDelete(notice)}
                sx={{
                  width: '100%',
                  boxSizing: 'content-box',
                  p: 0,
                  color: 'neutral.main',
                  justifyContent: 'end',
                }}
              >
                {isDeleting ? (
                  <Box
                    display={'flex'}
                    width={'100%'}
                    height={'30%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <Tooltip
                    title={'Delete this system notice for all users'}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'beforeWrite',
                          fn: ({ state }) => {
                            state.styles.popper.zIndex = '9999'; // Higher than Dialog (1300)
                          },
                        },
                      ],
                    }}
                  >
                    <DeleteIcon
                      sx={{
                        width: { xs: '22px' },
                        height: { xs: '22px' },
                        color: 'secondary.dark',
                      }}
                    />
                  </Tooltip>
                )}
              </IconButton>
            ) : (
              <UnauthorizedMessage />
            )}
          </Grid>
          <Grid
            container
            columnSpacing={5}
            alignItems={'center'}
            size={{ xs: 12, md: 'grow' }}
          >
            <Grid size={{ xs: 12 }}>
              <Typography
                variant="subtitle2"
                color="primary.dark"
                py={2}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {translateCommon('headers.headerDate')}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Typography
                variant="body3"
                color="textColor.main"
                py={2}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {notice.date}
              </Typography>
            </Grid>
          </Grid>
          {hasSystemManagementAccess ? (
            <Grid
              display={{ xs: 'none', md: 'flex' }}
              size={'grow'}
              alignItems={'start'}
              justifyContent={'end'}
            >
              <IconButton
                onClick={() => handleRequestDelete(notice)}
                sx={{
                  boxSizing: 'content-box',
                  p: 0,
                  color: 'neutral.main',
                }}
              >
                {isDeleting ? (
                  <Box
                    display={'flex'}
                    width={'100%'}
                    height={'30%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <Tooltip
                    title={'Delete this system notice for all users'}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'zIndex',
                          enabled: true,
                          phase: 'beforeWrite',
                          fn: ({ state }) => {
                            state.styles.popper.zIndex = '9999'; // Higher than Dialog (1300)
                          },
                        },
                      ],
                    }}
                  >
                    <DeleteIcon
                      sx={{
                        width: { xs: '22px' },
                        height: { xs: '22px' },
                        color: 'primary.dark',
                      }}
                    />
                  </Tooltip>
                )}
              </IconButton>
            </Grid>
          ) : (
            <UnauthorizedMessage />
          )}
        </Grid>
      </Box>

      {/* Error Dialog */}
      <Dialog open={openErrorDialog} onClose={handleCloseDialog}>
        <DialogTitle id="error-dialog-title">
          {translateCommon('errors.errorTitles.errorTitle')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography id="error-dialog-description">
            {translateCommon('errors.errorTitles.errorTitle')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {translateCommon('errors.errorButtons.errorCloseButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SystemNoticeCard;
