{
  "description":{
    "default": "Notification",
    "receptionDate": "Reçu : {{createdAt}}",
    "changedProjectStepDueDate": "La date d’échéance de l’étape de {{name}} a été modifiée.",
    "changedProjectDueDate": "La date d’échéance du projet a été modifiée pour le {{duedate}}.",
    "createdNewTask": "Une nouvelle tâche \"{{name}}\" a été créée",
    "changedProjectStepStatus": "Le statut de l'étape \"{{name}}\" a été modifié pour {{status}}.",
    "noDetailsAvailable": "Aucun détail supplémentaire disponible."
  },
  "feedBack":{
    "alertMessages":{
      "errors":{
        "deleteNotification": "Une erreur est survenue lors de la suppression de la notification.",
        "deleteAllNotifications": "Une erreur est survenue lors de la suppression de toutes les notifications."
      }
    }
  },
  "titles": {
    "systemNotices": "Notifications système",
    "openProject": "Projets Ouverts",
    "reportReview": "Revue du rapport",
    "tasks":{
            "create": "Une nouvelle tâche \"{{name}}\" a été créée",
            "overdue": "La tâche \"{{name}}\" est en retard",
            "dueToday": "La tâche \"{{name}}\" est due aujourd'hui",
            "dueTomorrow": "La tâche \"{{name}}\" est due demain"
        },
    "default": "Notification",
    "noInfoAvailable":"Titre non disponible"
  }
}
