import React, { useEffect, useState } from 'react';
import { HelpIcon, UserIcon } from 'icons';
import { SvgIconComponent } from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomButton, useNavAndSideBarContext } from 'components';
import useAuth from 'configs/AuthContext';
import { GetUserFromEmail } from 'utils/get-user-from-email';
import { getLocationLabel } from 'components/nav-and-sidebar/sidebar/utils';
import { defaultTransitionTimeAndStyle } from 'utils/styles';
import { Theme } from '@mui/material/styles';
import env from '../../../../src/env.json';
// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import EventIcon from '@mui/icons-material/Event';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useFaqContext } from '../../../react-query-toolkit/state/faq-context';
import { translateAll, useTranslateAll } from 'locales/translation-hooks';

const { translateMisc } = translateAll(['misc']);
import { UserProfilePicture } from '../nav-bar/mobile-menu/icons/mobile-user-icon';
import useHasSystemManagementAccess from 'utils/hooks/useHasSystemManagementAccess';
import { useWebSocketContext } from '../../../react-query-toolkit/state/websocket-context';

interface TabType {
  key: string; // Translation key for i18n
  path: string; // Fixed route path
  Icon: SvgIconComponent; // MUI icon component
}

type Props = {
  showSideBar: boolean;
  profilePicture?: string;
};

export const Sidebar: React.FC<Props> = ({ showSideBar, profilePicture }) => {
  const { setShowSideBar } = useNavAndSideBarContext();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const theme: Theme = useTheme();
  const { readyState, connectionStatus } = useWebSocketContext();

  const tabs: Record<string, TabType> = {
    Dashboard: {
      key: 'NavBar.navBarNavigationItems.dashboard',
      path: '/dashboard',
      Icon: DashboardIcon,
    },
    'My Clients': {
      key: 'NavBar.navBarNavigationItems.myClients',
      path: '/my-clients',
      Icon: PeopleIcon,
    },
    'My Projects': {
      key: 'NavBar.navBarNavigationItems.projects',
      path: '/my-projects',
      Icon: AssignmentOutlinedIcon,
    },
    Calendar: {
      key: 'NavBar.navBarNavigationItems.calendar',
      path: '/calendar',
      Icon: EventIcon,
    },
    Notifications: {
      key: 'NavBar.navBarNavigationItems.notifications',
      path: '/notifications',
      Icon: NotificationsIcon,
    },
    'Task Manager': {
      key: 'NavBar.taskManager',
      path: '/task-manager',
      Icon: TaskAltIcon,
    },
  };

  const hasSystemManagementAccess = useHasSystemManagementAccess();

  // Conditionally add System Management tab
  if (hasSystemManagementAccess) {
    tabs['System Management'] = {
      key: 'NavBar.navBarNavigationItems.systemManager',
      path: '/system-management',
      Icon: DesktopWindowsOutlinedIcon,
    };
  }

  const currentUserEmail =
    auth.user?.getSignInUserSession()?.getIdToken().payload.email || '';

  const userName = currentUserEmail
    ? GetUserFromEmail(currentUserEmail)
    : 'Guest';
  const userRole = 'Admin'; // Assuming the role is fixed

  const sidebarStyles = {
    drawer: {
      transform: {
        md: 'none',
        sm: `${showSideBar ? 'translate(0, 0)' : 'translate(-100%, 0)'}`,
        lg: 'none',
      },
      position: { sm: 'fixed', md: 'static', lg: 'static' },
      display: 'flex',
      zIndex: '1000 !important',
      height: '100%',
      width: { lg: '210px', sm: '84px' },
      flexShrink: 0,
      transition: `transform ${defaultTransitionTimeAndStyle}`,
      '& .MuiDrawer-paper': {
        width: { lg: '210px', sm: '84px' },
        transition: `transform ${defaultTransitionTimeAndStyle}`,
        transform: {
          md: 'none',
          sm: `${showSideBar ? 'translate(0, 0)' : 'translate(-100%, 0)'}`,
          lg: 'none',
        },
        border: { sm: 'auto', md: 'none' },
        boxSizing: 'border-box',
      },
    },
    list: {
      gap: 4,
      padding: () => {
        const availableHeight = window.innerHeight; // Or a specific container height
        return availableHeight > 850 ? '50px 0px 0 0px' : '20px 0 0 0'; //This is a dynamic padding to match Svend screen size
      },
      justifyContent: { md: 'center', lg: 'auto' },
    },
    listItemButton: {
      px: 0,
      gap: 3,
      py: 2,
      borderRadius: 2,
      justifyContent: 'center',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      // This is a workaround to match the design
      width: { xs: '110%', lg: '100%' },
      left: { xs: '-3px', lg: '0px' },
      top: { xs: '10px' },
      position: 'relative',
    },
    listItemIcon: {
      minWidth: theme.spacing(7),
      justifyContent: 'center',
      paddingTop: theme.spacing(2),
      pl: 0,
    },
    listItemProfile: {
      pl: { xs: 0, lg: 6 },
      '& .MuiButtonBase-root': {
        py: 3,
        justifyContent: { sm: 'center', lg: 'start' },
      },
    },
  };

  const { translateCommon } = useTranslateAll('common');

  const [selectedTab, setSelectedTab] = useState('');
  useEffect(() => {
    const currentTab = getLocationLabel(location.pathname);
    setSelectedTab(currentTab);
  }, [location.pathname]);

  const routeChange = (key: string) => {
    const tab = tabs[key as keyof typeof tabs]; // Safe type assertion
    if (!tab) return;

    navigate(tab.path); // Always use the fixed route
    setShowSideBar(false);
    setSelectedTab(key); // Update selected tab
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        showSideBar &&
        event.target instanceof Node &&
        !document.querySelector('.MuiDrawer-paper')?.contains(event.target)
      ) {
        setShowSideBar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSideBar]);

  const buildNumber = env.build;

  const { openFaq } = useFaqContext();

  //  Handle FAQ Button Click to Open Modal
  const handleFaqClick = () => {
    let currentPage = 'Unknown Page';

    // If the pathname starts with "/my-projects/", set it to "MyProjectsPage"
    if (location.pathname.startsWith('/my-projects')) {
      currentPage = 'MyProjectsPage';
    } else {
      // Define other static pages
      const staticPageNames: Record<string, string> = {
        '/': 'DashboardPage',
        '/dashboard': 'DashboardPage',
        '/task-manager': 'TaskManagerPage',
        '/calendar': 'CalendarPage',
        '/my-clients': 'MyClientsPage',
        '/notifications': 'NotificationsPage',
        '/system-management': 'systemManagementPage',
      };

      currentPage = staticPageNames[location.pathname] || 'Unknown Page';
    }

    openFaq(currentPage); // Open FAQ Modal with correct page name
  };

  useEffect(() => {
    console.log(
      'WebSocket Ready State:',
      readyState,
      ' - Status:',
      connectionStatus,
    );
  }, [readyState, connectionStatus]);

  return (
    <>
      {auth.user && (
        <Drawer variant="permanent" sx={sidebarStyles.drawer}>
          <Toolbar />
          <Box
            height={'100%'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden', // Prevents scrollbar issues
            }}
          >
            <Divider />

            {/* Sidebar Content - Ensures scrolling when needed */}
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              <List sx={sidebarStyles.list}>
                {/* User Profile Section */}
                <ListItem
                  disableGutters
                  disablePadding
                  sx={sidebarStyles.listItemProfile}
                >
                  <ListItemButton sx={sidebarStyles.listItemButton}>
                    <ListItemIcon sx={{ minWidth: 'auto' }}>
                      {profilePicture && profilePicture.length > 0 ? (
                        <UserProfilePicture
                          picture={profilePicture}
                          width="30px"
                          height="30px"
                        />
                      ) : (
                        <UserIcon color={theme.palette.primary.dark} />
                      )}
                    </ListItemIcon>
                    <Box
                      sx={{ display: { xs: 'auto', sm: 'none', lg: 'inline' } }}
                    >
                      <Typography
                        noWrap
                        display="inline"
                        variant="subtitle2"
                        color={theme.palette.primary.dark}
                      >
                        {userName}
                      </Typography>
                      <Typography
                        noWrap
                        color={theme.palette.text.primary}
                        variant="body2"
                      >
                        {userRole}
                      </Typography>
                    </Box>
                  </ListItemButton>
                </ListItem>

                {Object.entries(tabs).map(
                  ([key, values]: [string, TabType], index) => {
                    const isSelected = key === selectedTab;
                    return (
                      <ListItem
                        disableGutters
                        key={index}
                        onClick={() => routeChange(key)}
                        sx={{
                          py: 2,
                          pl: { xs: 0, lg: 6 },
                          color: isSelected ? theme.palette.bg.main : 'none',
                          backgroundColor: isSelected
                            ? theme.palette.bg.main
                            : 'none',
                          '& .MuiButtonBase-root': {
                            py: 3,
                            display: 'flex',
                            justifyContent: 'center',
                          },
                        }}
                      >
                        <ListItemButton
                          // selected={isSelected}
                          sx={{
                            px: 0,
                            alignItems: 'flex-start',
                            gap: 3,
                            py: 2,
                            display: 'flex',
                            justifyContent: 'center !important',
                            borderRadius: 2,
                            '&:hover': {
                              color: theme.palette.secondary.light,
                            },
                            // color: isSelected ? theme.palette.bg.main : 'none',
                          }}
                        >
                          <ListItemIcon sx={sidebarStyles.listItemIcon}>
                            <values.Icon
                              sx={{
                                '&:hover': {
                                  color: theme.palette.primary.dark,
                                },
                                color: isSelected
                                  ? theme.palette.primary.dark
                                  : theme.palette.text.secondary,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography variant="body1">
                                {translateMisc(values.key)}{' '}
                                {/* Use i18n translation */}
                              </Typography>
                            }
                            sx={{
                              alignItems: 'start',
                              whiteSpace: 'normal',
                              wordBreak: 'break-word',
                              display: { xs: 'auto', sm: 'none', lg: 'inline' },
                              '&:hover': {
                                color: theme.palette.text.secondary,
                              },
                              color: isSelected
                                ? theme.palette.primary.dark
                                : theme.palette.text.secondary,
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  },
                )}
              </List>
            </Box>

            {/* Help Button - Positioned at the bottom */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 2,
                mt: 'auto',
                mb: { xs: 6 }, // Adjust margin based on available space
              }}
            >
              <CustomButton
                disabled={false}
                clickHandler={handleFaqClick}
                variant="outlined"
              >
                <HelpIcon />
                <Typography
                  p={'0 0 0 8px'}
                  display={{ xs: 'none', lg: 'flex' }}
                >
                  {translateCommon?.('headers.headerHelpButton')}
                </Typography>
              </CustomButton>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <CircleIcon
              sx={{
                width: 10,
                height: 10,
                mr: 1,
                color:
                  readyState === 1
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
            />
            <Typography variant={'body3'}>Build Nº {buildNumber}</Typography>
          </Box>
        </Drawer>
      )}
    </>
  );
};
