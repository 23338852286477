import React, { ReactNode } from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';

interface NotificationCardPropType {
  activeNumber: string | number;
  cardTitle: string;
  cardIcon: ReactNode;
  pathLink: string;
}

export default function NotificationCard(props: NotificationCardPropType) {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(props.pathLink)}
      sx={{
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Paper
        elevation={1}
        sx={{
          flex: 1, // Ensures equal height in the Grid
          display: 'flex',
          flexDirection: 'column',
          p: 5,
        }}
      >
        <Grid container spacing={1} alignItems="center">
          {/* Icon + Number (Same Row) */}
          <Grid sx={{ display: 'flex', gap: 1 }}>
            {props.cardIcon}

            {/* Number + Title (Stacked) */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h5" color="primary.dark">
                {props.activeNumber}
              </Typography>
              <Typography
                color="text.primary"
                variant="body2"
                sx={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {props.cardTitle}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
