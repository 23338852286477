import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { ghgSfeApi } from '../services/ghgci-sfe';
import useAuth from 'configs/AuthContext';
import { PlEntities, SlEntities } from '../services/types';

type TagType =
  | PlEntities
  | SlEntities.Comment
  | SlEntities.Task
  | SlEntities.Notification
  | SlEntities.ProjectStep
  | SlEntities.ProjectText;

// Define WebSocket context type
interface WebSocketContextType {
  lastMessageData: string | null;
  sendMessage: (message: string) => void;
  readyState: ReadyState;
  connectionStatus: string;
  setSocketUrl: Dispatch<SetStateAction<string | null>>;
  activeWebSocket: string;
}

// Default WebSocket context values
const defaultWebSocketContext: WebSocketContextType = {
  lastMessageData: null,
  sendMessage: () => {},
  readyState: ReadyState.UNINSTANTIATED,
  connectionStatus: 'Uninstantiated',
  activeWebSocket: '',
  setSocketUrl: () => {},
};

// Create WebSocket Context
const WebSocketContext = createContext<WebSocketContextType>(
  defaultWebSocketContext,
);

// Custom Hook to use WebSocket Context
export const useWebSocketContext = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error(
      'useWebSocketContext must be used within a WebSocketProvider',
    );
  }
  return context;
};

export const WebSocketProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const auth = useAuth();

  const ghgciId = useMemo(() => {
    return (
      auth.user?.getSignInUserSession()?.getIdToken()?.payload[
        'custom:ghgci_id'
      ] || null
    );
  }, [auth.user]);

  const [socketUrl, setSocketUrl] = useState<string | null>(null);
  const [lastMessageData, setLastMessageData] = useState<string | null>(null);

  // Set WebSocket URL only when ghgciId is available
  useEffect(() => {
    if (ghgciId) {
      // const newUrl = `/wss?user=${ghgciId}`;
      const newUrl = `wss://test.ghgci.com/wss?user=${ghgciId}`; //TODO- Use this for development
      console.log('Setting WebSocket URL:', newUrl);
      setSocketUrl(newUrl);
    }
  }, [ghgciId]);

  // Initialize WebSocket only when socketUrl is available
  const { sendMessage, lastMessage, readyState } = useWebSocket<string>(
    socketUrl ? socketUrl : '',
    {
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 5000,
    },
  );

  useEffect(() => {
    if (lastMessage?.data) {
      console.log('New WebSocket Message Received:', lastMessage.data);
      setLastMessageData(lastMessage.data);

      try {
        // Parse the WebSocket message JSON
        const parsedMessage = JSON.parse(lastMessage.data);
        if (parsedMessage?.invalidate) {
          parsedMessage.invalidate.forEach((obj: Record<string, string>) => {
            Object.entries(obj).forEach(([typename, id]) => {
              // Ensure typename is a valid RTK tag type
              if (
                Object.values(PlEntities).includes(typename as PlEntities) ||
                Object.values(SlEntities).includes(typename as SlEntities)
              ) {
                console.log(
                  `Triggering RTK invalidation for: ${typename} with ID: ${id}`,
                );

                dispatch(
                  ghgSfeApi.util.invalidateTags([
                    { type: typename as TagType, id },
                  ]),
                );
              } else {
                console.warn(
                  `Invalid typename received from WebSocket: ${typename}`,
                );
              }
            });
          });
        }
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    }
  }, [lastMessage, dispatch]);

  // Convert readyState to a user-friendly connection status
  const connectionStatus = useMemo(() => {
    return {
      [ReadyState.CONNECTING]: 'Connecting',
      [ReadyState.OPEN]: 'Open',
      [ReadyState.CLOSING]: 'Closing',
      [ReadyState.CLOSED]: 'Closed',
      [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];
  }, [readyState]);

  const [activeWebSocket, setActiveWebSocket] = useState('');

  // Debugging logs
  useEffect(() => {
    console.log(
      'WebSocket Ready State:',
      readyState,
      ' - Status:',
      connectionStatus,
    );
    setActiveWebSocket(connectionStatus);
  }, [readyState, connectionStatus]);

  useEffect(() => {
    if (lastMessage?.data) {
      console.log('New WebSocket Message Received:', lastMessage.data);
      setLastMessageData(lastMessage.data);
    }
  }, [lastMessage]);

  return (
    <WebSocketContext.Provider
      value={{
        sendMessage,
        lastMessageData, // Using the latest state-tracked message
        readyState,
        connectionStatus,
        setSocketUrl,
        activeWebSocket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
