import { MyProjectsItem } from './my-projects-item';
import { Project } from '../../../react-query-toolkit/state/types';
export const myProjectsList = (projects: Project[]) => {
  const projectStepsOrder = [
    'set-up',
    'data-collection',
    'research',
    'calculation',
    'calculation-review',
    'draft-report',
    'report-review',
    'client-review',
    'finalize-report',
    'close-project',
  ];

  function getProjectStepProgress(projectStep: string): number {
    const stepIndex = projectStepsOrder.indexOf(projectStep);

    // If the project step is not found, return -1 as an indicator of invalid input
    if (stepIndex === -1) {
      return -1;
    }

    // Calculate progress as a percentage
    return (stepIndex / (projectStepsOrder.length - 1)) * 100;
  }
  return projects.map((project) => {
    return (
      <MyProjectsItem
        key={project.id}
        projectName={`${project.period}-${project.projecttype}-${project.jurisdiction}`}
        progress={getProjectStepProgress(project.step)}
        projectId={project.id}
      />
    );
  });
};
